import axios from "axios";
import {
  signInWithPopup,
  FacebookAuthProvider,
  signInWithRedirect,
} from "firebase/auth";

import React, { useContext, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import * as apiUrls from "../../constants/ApiConstants";
import { MyContext } from "../../Store";
import { auth, provider } from "../../Utils/firebase";
import { GenericPostApiNoAuth } from "../../Services";
import { Footer, Navbar } from "../../Component";
import Icons from "../../Icons";

import "./Styles.css";

const Registration = () => {
  // useContext
  const { saveToken } = useContext(MyContext);

  // useNavigate
  const navigate = useNavigate();

  // react hooks form
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  // useState
  const [isLoading, setLoading] = useState(false);
  const [isGoogleAuthLoading, setGoogleLoading] = useState(false);

  const [otpFieldIsVisible, setOtpFieldIsVisible] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isSignUpButtonVisible, setIsSignUpButtonVisible] = useState(false);
  const [emailOtpValue, setEmailOtpValue] = useState("");

  // Email validation regex pattern
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const passwordRegex =
    /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

  const onSubmit = async (data) => {
    if (isSignUpButtonVisible && isEmailVerified) {
      if (data?.userName && data?.password && data?.name && data?.email) {
        setLoading(true);
        const url = apiUrls.clientRegister;
        // console.log(url);

        const body = {
          userName: data.userName,
          password: data.password,
          fullName: data.name,
          email: data.email,
          isEmailVerified: true,
          mobile: "",
          isMobileVerified: false,
          latitude: "",
          longitude: "",
          deviceData: {
            modelName: "",
            brand: "",
            uniqueId: "",
            deviceId: "",
          },
        };

        try {
          await axios({
            method: "post",
            url: url,
            headers: {
              "Accept-Language": "en",
            },
            data: body,
          }).then((response) => {
            // console.log(response);
            if (response.status === 200 && response.data.code === 200) {
              const data = response.data?.data[0];
              // Store the token in local storage
              localStorage.setItem("token", `token ${data.token}`);
              // Store the token in local storage
              saveToken(`token ${data.token}`);
              navigate("/");
            } else {
              alert(
                response?.data?.detail
                  ? response?.data?.detail
                  : "Attention! Something went wrong, please try again"
              );
            }
          });
        } catch (error) {
          setLoading(false);
          // console.log("==> error from signup page, details = ", error);
        } finally {
          setLoading(false);
        }
      } else {
        alert("Attention! please make sure all recommended fields are typed");
      }
    } else if (otpFieldIsVisible && !isEmailVerified) {
      if (data?.userOtp && emailOtpValue) {
        setLoading(true);
        setIsEmailVerified(false);
        setIsSignUpButtonVisible(false);
        if (parseFloat(data?.userOtp) === emailOtpValue) {
          setIsEmailVerified(true);
          setIsSignUpButtonVisible(true);
        } else {
          setError("userOtp", {
            type: "required",
            message: "Verification code is invalid!",
          });
        }
        setLoading(false);
      } else {
        alert("Attention! please enter the verification code");
      }
    } else {
      if (data?.email) {
        setLoading(true);
        setOtpFieldIsVisible(false);
        setEmailOtpValue("");

        const url = apiUrls.sendRegistrationCodeToEmail;
        const body = {
          email: data.email,
        };

        try {
          await axios({
            method: "post",
            url: url,
            headers: {
              "Accept-Language": "en",
            },
            data: body,
          }).then((response) => {
            // console.log(response);
            if (response.status === 200 && response.data.code === 200) {
              setEmailOtpValue(response?.data?.otp);
              setOtpFieldIsVisible(true);
            } else {
              alert(
                response?.data?.detail
                  ? response?.data?.detail
                  : "Attention! Something went wrong, please try again"
              );
            }
          });
        } catch (error) {
          // console.log("==> error from send email otp api, details = ", error);
        }

        setLoading(false);
      } else {
        alert("Attention! please enter a valid email address");
      }
    }
  };

  // sign in with google
  const GoogleLogin = async () => {
    setGoogleLoading(true);
    try {
      signInWithPopup(auth, provider).then((result) => {
        handleSocialMediaAuthSignIn(result);
      });
    } catch (error) {
      // console.log(error);
    }
    setGoogleLoading(false);
  };

  // sign in with facebook
  const fbProvider = new FacebookAuthProvider();
  const FacebookLogin = async () => {
    setGoogleLoading(true);
    try {
      signInWithPopup(auth, fbProvider).then((result) => {
        // console.log("result = ", result);
        handleSocialMediaAuthSignIn(result);
      });
    } catch (error) {
      // console.log(error);
    }

    // try {
    //   const result = await signInWithPopup(auth, fbProvider);
    //   console.log("result = ", result);
    //   const credential = await FacebookAuthProvider.credentialFromResult(
    //     result
    //   );
    //   const fbToken = credential.accessToken;
    // } catch (error) {
    //   console.log(error);
    // }
    setGoogleLoading(false);
  };

  const handleSocialMediaAuthSignIn = async (autData) => {
    const url = apiUrls?.ClientSocialRegister;
    const provider = autData.providerId;

    const body = {
      fullName: autData?.user?.displayName,
      email: autData?.user?.providerData[0]?.email,
      isEmailVerified: true,
      dob: "",
      profileImage: autData?.user?.providerData[0]?.photoURL
        ? autData?.user?.providerData[0]?.photoURL
        : "",
      provider: provider,
      firebaseUid: autData?.user?.uid,
      providerUid: autData?.user?.providerData[0]?.uid,
      latitude: "",
      longitude: "",
      deviceData: {
        modelName: "",
        brand: "",
        uniqueId: "",
        deviceId: "",
      },
    };

    const [res, error] = await GenericPostApiNoAuth(url, body);

    if (res?.code === 200 && res?.token) {
      localStorage.setItem("token", `token ${res.token}`);
      saveToken(`token ${res.token}`);
      navigate("/");
    } else {
      // console.log("error in social register, details = ", error);
    }
    setGoogleLoading(false);
  };

  async function checkUserName(val) {
    const url = apiUrls.checkUserName;
    const body = {
      term: val,
    };

    try {
      await axios({
        method: "post",
        url: url,
        headers: {
          "Accept-Language": "en",
        },
        data: body,
      }).then((response) => {
        // console.log(response.data);
        if (response.status === 200 && response.data.code === 200) {
        } else {
          setError("userName", {
            type: "required",
            message: "Username not available!",
          });
        }
      });
    } catch (error) {
      // console.log("==> error from check user name api, details = ", error);
    }
  }

  return (
    <div className="loginContainer">
      <Navbar />
      <Container>
        <div className="main-logon-inner">
          <Row className="justify-content-center">
            <Col lg={6} md={7} sm={10}>
              <div className="login-card-wrpr">
                <div className="login_head">Welcome to iShout</div>
                <div>
                  <button className="fbbtns" onClick={GoogleLogin}>
                    <Icons
                      icon={"google"}
                      color={"#ffffff"}
                      className="common-icon-size"
                    />
                    <div className="s-ss-ssep">Continue with Google</div>
                  </button>
                  <div className="h-24-s" />
                  <button className="fbbtns" onClick={FacebookLogin}>
                    <Icons
                      icon={"fb"}
                      color={"#ffffff"}
                      className="common-icon-size"
                    />
                    <div className="s-ss-ssep">Continue with Facebook</div>
                  </button>
                </div>
                <div className="line-wrprs-1" />
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-wrpr-field">
                    <div className="label-reg-form">Name*</div>
                    <Form.Control
                      placeholder="John doe"
                      className={
                        errors.name
                          ? `custom-pfa-class-styleq1 errorBoarder`
                          : `custom-pfa-class-styleq1`
                      }
                      {...register("name", {
                        required: {
                          value: true,
                          message: "Required",
                        },
                        maxLength: {
                          value: 100,
                          message: "Text length is limited to 100 chars",
                        },
                      })}
                    />
                    {errors.name && (
                      <span className="errorText">{errors.name.message}</span>
                    )}
                  </div>
                  <div className="form-wrpr-field">
                    <div className="label-reg-form">Email*</div>
                    <Form.Control
                      type="text"
                      placeholder="john.doe@email.com"
                      readOnly={isEmailVerified}
                      disabled={isEmailVerified}
                      className={
                        errors.email
                          ? `custom-pfa-class-styleq1 errorBoarder`
                          : `custom-pfa-class-styleq1`
                      }
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Required",
                        },
                        maxLength: {
                          value: 100,
                          message: "Text length is limited to 100 chars",
                        },
                        pattern: {
                          value: emailRegex,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="errorText">{errors.email.message}</span>
                    )}
                  </div>
                  {!otpFieldIsVisible ? (
                    <>
                      <button
                        disabled={isLoading}
                        type="submit"
                        className="log-in-btsss"
                      >
                        {isLoading || isGoogleAuthLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          `Submit`
                        )}
                      </button>
                    </>
                  ) : (
                    <>
                      {!isEmailVerified ? (
                        <>
                          <div className="form-wrpr-field">
                            <div className="label-reg-form">
                              Verification code*
                            </div>
                            <Form.Control
                              placeholder="Verification code send to your email"
                              className={
                                errors.userOtp
                                  ? `custom-pfa-class-styleq1 errorBoarder`
                                  : `custom-pfa-class-styleq1`
                              }
                              {...register("userOtp", {
                                required: {
                                  value: true,
                                  message: "Required",
                                },
                                maxLength: {
                                  value: 6,
                                  message:
                                    "Verification code is a 6 digit code",
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                    "Verification code is a 6 digit code",
                                },
                              })}
                            />
                            {errors.userOtp && (
                              <span className="errorText">
                                {errors.userOtp.message}
                              </span>
                            )}
                          </div>

                          <button
                            disabled={isLoading}
                            type="submit"
                            className="log-in-btsss"
                          >
                            {isLoading || isGoogleAuthLoading ? (
                              <Spinner size="sm" />
                            ) : (
                              `Verify`
                            )}
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  {isEmailVerified && isSignUpButtonVisible ? (
                    <>
                      <div className="form-wrpr-field">
                        <div className="label-reg-form">Username*</div>
                        <Form.Control
                          placeholder="john_doe"
                          className={
                            errors.userName
                              ? `custom-pfa-class-styleq1 errorBoarder`
                              : `custom-pfa-class-styleq1`
                          }
                          {...register("userName", {
                            required: {
                              value: true,
                              message: "Required",
                            },
                            maxLength: {
                              value: 50,
                              message: "Text length is limited to 50 chars",
                            },
                            minLength: {
                              value: 3,
                              message: "Should contain at least 3 chars",
                            },
                            validate: (val) => {
                              checkUserName(val);
                            },
                          })}
                        />
                        {errors.userName && (
                          <span className="errorText">
                            {errors.userName.message}
                          </span>
                        )}
                      </div>
                      <div className="form-wrpr-field">
                        <div className="label-reg-form">Password*</div>
                        <Form.Control
                          placeholder="***************"
                          className={
                            errors.password
                              ? `custom-pfa-class-styleq1 errorBoarder`
                              : `custom-pfa-class-styleq1`
                          }
                          {...register("password", {
                            required: {
                              value: true,
                              message: "Required",
                            },
                            pattern: {
                              value: passwordRegex,
                              message:
                                "Should contains a letter, digit and a special character",
                            },
                            minLength: {
                              value: 8,
                              message:
                                "Password length is between 8 to 16 chars",
                            },
                            maxLength: {
                              value: 16,
                              message:
                                "Password length is between 8 to 16 chars",
                            },
                          })}
                        />
                        {errors.password && (
                          <span className="errorText">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                      <button
                        disabled={isLoading}
                        type="submit"
                        className="log-in-btsss"
                      >
                        {isLoading || isGoogleAuthLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          `Sign Up`
                        )}
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </Form>
                {isEmailVerified && isSignUpButtonVisible ? (
                  <>
                    <div style={{ height: "16px" }}></div>
                    <div className="privacyText">
                      By signing up, you agree to iShout's &nbsp;
                      <Link to={"/privacy"}>
                        <div className="privacyPlolicy">Privacy policy</div>
                      </Link>
                      <div className="andSigns">&</div>
                      <Link to={"/terms"}>
                        <div className="termsOfUse">Terms of use</div>
                      </Link>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div style={{ height: "16px" }}></div>
                <div className="regBtns1">
                  Already have an account?{" "}
                  <Link to={"/login"}>
                    <div className="signUpTxt">Login</div>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Registration;
