// import axios from "axios";

import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Form,
  Spinner,
  Row,
  Col,
  Modal,
  FormControl,
} from "react-bootstrap";
import StarRatings from "react-star-ratings";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { Footer, Navbar } from "../../Component";
// import Ale from "../../Assets/ale.jpg";
// import Boss from "../../Assets/boss.png";
import Icons from "../../Icons";
import { GenericPostApiCall, GenericGetApisWithParams } from "../../Services";
import * as apiUrls from "../../constants/ApiConstants";

import "./Styles.css";

const OrderDownload = () => {
  // useLocation
  const location = useLocation();
  // console.log(location.state);
  // const orderData = location.state?.orderData; // Use optional chaining (?.)

  const order_id = location.state?.orderData?.order_id;
  const videoProcessed = location.state.orderData?.videoProcessed;

  // useState
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSoundMute, setIsSoundMute] = useState(true);
  const [rating, setRating] = useState();
  const [review, setReview] = useState("");
  const [submitBtnLoader, setSubmitBtnLoader] = useState(false);
  const [reviewPostResponse, setReviewPostResponse] = useState("");
  const [isDownloadButtonLoading, setDownloadButtonLoading] = useState(false);
  const [isReportButtonLoading, setReportButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [orderData, setOrderData] = useState([]);

  // useEffect
  useEffect(() => {
    getOrderDetails();
  }, []);

  // useRef
  const videoRef = useRef(null);

  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getOrderDetails = async () => {
    setIsLoading(true);
    const [res, error] = await GenericGetApisWithParams(
      apiUrls.getOrderDetails,
      { key: order_id }
    );
    // console.log(res);
    if (res?.code === 200) {
      setOrderData(res?.order);
    } else {
      if (error) {
      }
      // console.log("error in or-in-com-pg-gt-det", error);
    }
    setIsLoading(false);
  };

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleMuteUnmute = () => {
    if (isSoundMute) {
      setIsSoundMute(false);
    } else {
      setIsSoundMute(true);
    }
  };

  const changeRating = (data) => {
    setRating(data);
  };

  const handlePostReview = async (event) => {
    event.preventDefault();
    setSubmitBtnLoader(true);
    if (rating && review) {
      const url = `${apiUrls.postReview}?key=${order_id}`;
      const body = {
        rating: rating,
        comment: review,
      };
      const [res, error] = await GenericPostApiCall(url, body);
      if (error) {
        // console.log("error in o-i-p-sub-api");
      }
      if (res?.code === 200) {
        // console.log(res);
        setReviewPostResponse(res);
        getOrderDetails();
      } else {
        // console.log("error happened in post review api, details = ", error);
        alert("Attention! Unable to complete the request, please try again");
      }
    } else {
      alert(
        "Attention! Unable to complete the request, please write your review"
      );
    }
    setSubmitBtnLoader(false);
  };

  const handleReviewOnChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setReview(value);
  };

  const handleDownloadVideo = async () => {
    setDownloadButtonLoading(true);
    const body = {
      orderId: order_id,
    };
    const url = apiUrls.DownloadVideo;
    const [res, error] = await GenericPostApiCall(url, body);
    if (error) {
      // console.log("error in o-i-p-down-api");
    }

    if (res.dataAvailable && res.outputVideoDownloadLink) {
      const response = await fetch(res.outputVideoDownloadLink, {
        method: "GET",
        headers: {
          Accept: "video/mp4;charset=UTF-8",
        },
      });
      // console.log("video response = ", response);
      const blob = await response.blob();
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob, {
        type: "video/mp4",
      });
      const link = document.createElement("a");

      link.href = blobUrl;
      var filename = "";
      if (orderData?.celebrityFirstName) {
        filename = `iShout-${order_id}-${orderData?.celebrityFirstName}-${orderData.celebrity}`;
      } else {
        filename = `iShout-${order_id}-${orderData.celebrity}`;
      }
      link.setAttribute("download", `${filename}.mp4`);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);

      getOrderDetails();
    } else {
      // console.log("error = ", error);
      alert("Attention, something went wrong, please try again later");
    }
    setDownloadButtonLoading(false);
  };

  const handleReportVideo = async (data) => {
    setReportButtonLoading(true);
    if (data?.reason) {
      const body = {
        orderId: order_id,
        details: data?.reason,
      };
      const url = apiUrls.ReportOrder;
      const [res, error] = await GenericPostApiCall(url, body);
      if (error) {
        if (error?.detail) {
          setShow(false);
          alert(error?.detail);
        }
        // console.log("error in o-i-p-down-api");
      }
      if (res?.code === 200) {
        setShow(false);
        alert(res?.detail);
      }
      getOrderDetails();
    } else {
      alert("Please enter the reason");
    }
    setReportButtonLoading(false);
  };

  const onEnded = () => {
    setIsPlaying(false);
  };

  const handleMouseOver = (event) => {
    event.target.play();
    setIsPlaying(true);
  };

  const handleMouseOut = (event) => {
    event.target.pause();
    setIsPlaying(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="containerO-wr">
      <Navbar />
      {isLoading && !orderData ? (
        <Spinner />
      ) : (
        <Container>
          <div className="order-inner-wrpr">
            {/* ------------------------ */}
            <div className="order-heads-head">
              Order #{order_id}
              <hr></hr>
            </div>
            {/* ------------------------ */}
            {/* <div className="pro-sec-wrprs"> */}
            <Row className="mb-4">
              <Col lg={3} md={4} sm={6} xs={8}>
                <div className="r-p-imgs-new">
                  <video
                    width="100%"
                    height="auto"
                    ref={videoRef}
                    muted={isSoundMute}
                    onEnded={onEnded}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    style={{ background: "#000000" }}
                  >
                    <source
                      src={`${videoProcessed}#t=0.001`}
                      type="video/mp4"
                    />
                  </video>
                  {/* <img src={videoProcessedThumbnail} className="w-100" alt="cel" /> */}
                  <div className="mute-wrprs-c-d">
                    <button
                      className="-an-c-d-muts-bts-1"
                      onClick={handleMuteUnmute}
                    >
                      {isSoundMute ? (
                        <Icons icon="mutes" size={32} color="#ffffff" />
                      ) : (
                        <Icons icon="soundOn" size={32} color="#ffffff" />
                      )}
                    </button>
                  </div>
                  <div className="mute-wrprs-c-d-play">
                    {isPlaying ? (
                      <button
                        onClick={handlePlayPause}
                        className="-an-r-v-play-btn"
                      >
                        <Icons icon="pauses" size={32} color="#ffffff" />
                      </button>
                    ) : (
                      <button
                        onClick={handlePlayPause}
                        className="-an-r-v-play-btn"
                      >
                        <Icons icon="plays" size={32} color="#ffffff" />
                      </button>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={9} md={8} sm={6}>
                <div className="sec-sec-wrrprs">
                  <div className="orderIds text-white">{orderData?.intro}</div>
                  <div className="names-cels">
                    {orderData?.celebrityFirstName} {orderData?.celebrity}
                  </div>
                  <div className="cel-pro">
                    {orderData?.shoutOutPurpose === "business"
                      ? orderData?.occasion
                        ? `Personalized video for business ${orderData?.businessName} for the ${orderData?.occasion.name}`
                        : `Personalized video for business ${orderData?.businessName}`
                      : orderData?.occasion
                      ? `Personalized video for ${orderData?.shoutOutTo} for the ${orderData?.occasion.name}`
                      : `Personalized video for ${orderData?.shoutOutTo}`}
                  </div>
                  <div className="cel-sec-s mh-25">
                    {orderData?.instruction}
                  </div>

                  <div className="statusDetailsText">
                    Personalized video completed on{" "}
                    {new Date(orderData?.completedOn * 1000).toDateString()}.
                    {orderData?.reportFlag || orderData?.isAlreadyReported
                      ? ``
                      : ` If you are not satisfied with this video, you can report and our team will check it as soon as possible`}
                  </div>

                  {orderData?.isAlreadyReported ? (
                    <>
                      <div className="statusDetailsText">
                        You reported this video on{" "}
                        {new Date(
                          orderData?.reportHistory?.data[0]?.time * 1000
                        ).toDateString()}
                        .
                      </div>
                      {orderData?.isReportPending ? (
                        <>
                          <div className="statusDetailsText">
                            Your report request is reviewing! We will let you
                            know the status
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mt-4 mt-md-5">
                            <>
                              <button
                                className="downloadbtsn-ss-s"
                                onClick={handleDownloadVideo}
                              >
                                {isDownloadButtonLoading ? (
                                  <div>
                                    <Spinner
                                      size="sm"
                                      animation="border"
                                      role="status"
                                    ></Spinner>
                                  </div>
                                ) : (
                                  `Download`
                                )}
                              </button>
                              {!orderData?.reportFlag ? (
                                <button
                                  className="report-btns-ss"
                                  onClick={handleShow}
                                  // onClick={handleReportVideo}
                                >
                                  {isReportButtonLoading ? (
                                    <div>
                                      <Spinner
                                        size="sm"
                                        animation="border"
                                        role="status"
                                      ></Spinner>
                                    </div>
                                  ) : (
                                    `Report`
                                  )}
                                </button>
                              ) : (
                                ""
                              )}
                            </>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="mt-3">
                      <>
                        <button
                          className="downloadbtsn-ss-s"
                          onClick={handleDownloadVideo}
                        >
                          {isDownloadButtonLoading ? (
                            <div>
                              <Spinner
                                size="sm"
                                animation="border"
                                role="status"
                              ></Spinner>
                            </div>
                          ) : (
                            `Download`
                          )}
                        </button>
                        {!orderData?.reportFlag ? (
                          <button
                            className="report-btns-ss"
                            onClick={handleShow}
                            // onClick={handleReportVideo}
                          >
                            {isReportButtonLoading ? (
                              <div>
                                <Spinner
                                  size="sm"
                                  animation="border"
                                  role="status"
                                ></Spinner>
                              </div>
                            ) : (
                              `Report`
                            )}
                          </button>
                        ) : (
                          ""
                        )}
                      </>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <div className="reviewSubmitWrpr">
              <>
                <div className="r-v-v-txt">Rate this video</div>
                <div className="ratingContainer">
                  <StarRatings
                    starDimension="20"
                    starSpacing="5px"
                    rating={
                      orderData?.reviewPosted
                        ? orderData?.reviewData?.rating
                        : rating
                    }
                    starRatedColor="#FDCC0D"
                    changeRating={changeRating}
                    numberOfStars={5}
                    name="rating"
                    starHoverColor="yellow"
                  />
                </div>
                <div className="reviewInput">
                  {orderData?.reviewData?.comment || reviewPostResponse ? (
                    <div className="comment-texts-caa">
                      {orderData.reviewData?.comment
                        ? orderData.reviewData?.comment
                        : reviewPostResponse?.reviewData[0]?.comment}
                    </div>
                  ) : (
                    <Form onSubmit={(event) => handlePostReview(event)}>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Write your review"
                        className="-an-c-c-form"
                        onChange={handleReviewOnChange}
                        style={{ color: "#ffffff" }}
                      />
                      <div className="button-s-wrrprs">
                        <button
                          onClick={(event) => handlePostReview(event)}
                          className="submit-bts-o-i-p"
                        >
                          {submitBtnLoader ? (
                            <div>
                              <Spinner
                                size="sm"
                                animation="border"
                                role="status"
                              ></Spinner>
                            </div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </div>
                {orderData?.reviewPosted ? (
                  <small className="mt-2 text-primary">
                    Rated on{" "}
                    {orderData?.history.data[3]
                      ? new Date(
                          orderData?.history.data[3].time * 1000
                        ).toDateString()
                      : ``}
                  </small>
                ) : (
                  ``
                )}
              </>
            </div>
          </div>
        </Container>
      )}

      <Footer />

      <Modal show={show} onHide={handleClose} scrollable={false}>
        <Modal.Body
          className="modal-wrpr-body"
          style={{ borderRadius: "35px", margin: "25px" }}
        >
          <Form onSubmit={handleSubmit(handleReportVideo)}>
            <Col lg={12} className="text-end">
              <button
                className="close-btn-wrpr-modal mb-0"
                onClick={handleClose}
                type="button"
              >
                <span className="m-cls-btn-style" aria-hidden="true">
                  &times;
                </span>
              </button>
            </Col>
            <Col lg={12}>
              <div className="total-review-modals-count mb-0">
                Report video #{orderData?.order_id}
              </div>
            </Col>
            <div className="review-para-cc1 pb-2">
              Why are you reporting this order? Please explain in detail
            </div>

            <Col lg={12} className="mt-2">
              <FormControl
                placeholder="Enter the reason"
                type="text"
                className={
                  errors.reason
                    ? "pr-edit-form-control errorBoarder"
                    : "pr-edit-form-control "
                }
                {...register("reason", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                  maxLength: {
                    value: 100,
                    message: "Text length is limited to 100 chars",
                  },
                })}
              />
              {errors.reason && (
                <div className="errorText">{errors.reason.message}</div>
              )}
            </Col>

            <Row className="my-4">
              <Col xs={6}>
                <button className="pr-edit-submit-button w-100">
                  {isReportButtonLoading ? <Spinner size="sm" /> : `Report`}
                </button>
              </Col>
              <Col xs={6}>
                <button
                  type="button"
                  className="pr-edit-submit-button w-100"
                  onClick={handleClose}
                >
                  Close
                </button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OrderDownload;
