import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import { Footer, Navbar } from "../../Component";

import "./styles.css";

const Privacy = () => {
  return (
    <div className="privacyContainer">
      <Navbar />
      <Container>
        <div className="mt-1 mt-md-3 mb-5">
          <h1>Terms of Use</h1>
          <p>
            iShout- Terms of Use BY INSTALLING AND USING THE MOBILE APPLICATION,
            WEBSITE{" "}
            <Link to={"/"} target="_blank" rel="noreferrer">
              WWW.ISHOUT.IO
            </Link>
            &nbsp; OR AND ANY OF THE SERVICES, FEATURES, CONTENT, OR
            APPLICATIONS OF CELEBRITY CONNECTIONS TECH PRIVATE LIMITED
            ("COMPANY" or "WE"), OFFERED THROUGH ITS APPLICATION (COLLECTIVELY
            REFERRED TO AS "SERVICES) YOU AS A USER, INCLUDING USERS WHO ARE
            CONTRIBUTORS OF CONTENT, AGREE TO THESE TERMS OF USE READ TOGETHER
            WITH VARIOUS POLICIES INCLUDING BUT NOT LIMITED TO THE CANCELLATION
            &amp; REFUND POLICY AND PRIVACY POLICY AS AVAILABLE ON THE APP.
          </p>
          <p>
            This document is an electronic record in terms of the Information
            Technology Act, 2000 ("IT Act") and the rules thereunder as
            applicable and the provisions about electronic records in various
            statutes as amended by the IT Act and is published by the provisions
            of Rule 3 (1) of the Information Technology (Intermediaries
            guidelines) Rules, 2011 amended from time to time, that require
            publishing the rules and regulations, privacy policy and Terms of
            Use for access or usage of the App. This electronic record is
            generated by a computer system and does not require any physical or
            digital signatures.
          </p>
          <p>
            We enhance and update the App and Services often. We may change or
            discontinue the Terms of use, the App, or Services at any time, with
            or without notice to you. You can determine the applicable terms by
            referring to the "LAST UPDATED" legend above. It shall be your
            responsibility to check terms periodically for changes. The company
            may require you to provide your consent to the updated terms in a
            specified manner before any further use of the Services is
            permitted. If no such separate consent is sought, your continued use
            of the App and Services, following changes to these terms, will
            constitute your acceptance of those changes.
          </p>
          <p>
            Notwithstanding anything contained herein, if you breach the Terms
            of Use or Privacy Policy or other rules and policies, Company
            reserves the right to take any legal or other activities including
            but not limited to denying or revoking access, or referral to the
            appropriate authorities.
          </p>
          <p>
            Through the App and Services, you can purchase "Personalized Video
            Messages" from celebrities and influencers. A " Personalized Video
            Message" includes the text, video, software, scripts, graphics,
            graphics interchange formats, photos, sounds, music, videos,
            audiovisual combinations, interactive features, and other materials
            you may view on, access through, or contribute to the Services.
          </p>
          <p>
            Eligibility: Persons who are "incompetent to contract" within the
            meaning of the Indian Contract Act, 1872 or the relevant laws in
            your jurisdiction including minors, un-discharged insolvents, etc.
            shall not be eligible to use the App. Any minor desirous to use or
            contribute to the App is required to conduct such transactions
            through their legal guardian or parents. You may only access the
            site and use the content and the Services for lawful purposes. You
            are solely responsible for the knowledge of and adherence to any
            laws, rules, and regulations about your use of the Services.
          </p>
          <p>
            Contents Posted on the App: Through the App and Services, you may
            submit a request which may or may not be accepted. The maximum time
            within which a request will be completed or rejected will be shared
            on the App and refunds will be initiated as per our Cancellation and
            Refund Policy for canceled and rejected requests. The company does
            not review or modify any content uploaded by a user. Company does
            not endorse any content submitted to it by any user, or any opinion,
            recommendation, or advice expressed therein, and Company expressly
            disclaims any liability in connection with the content.
            Notwithstanding the above, Company reserves the right to, but does
            not have any obligation to, reject a request, remove, or block the
            Services in its sole discretion, at any time, without notice to you
            and for any reason (including, but not limited to, a request
            contrary to General Provisions: Clause ‎6 or upon receipt of claims
            or allegations from third parties or authorities), or for no reason
            at all. Your interactions with third party, including users and
            celebrities, and influencers, found on or through the App are solely
            between you and such third party; however, Company may, in its sole
            discretion, intercede in any dispute, and you will reasonably
            cooperate with the Company if it does so. You should make whatever
            investigation you feel necessary or appropriate before proceeding
            with the interaction. You agree not to contact or interact with any
            user including a celebrity or influencer except as expressly
            permitted through our App. You acknowledge and agree that we are not
            responsible or liable for any damages, losses, costs, expenses, or
            liabilities of any kind or nature incurred as the result of any such
            interaction. You hereby release the Company from claims, demands,
            and damages (actual and consequential) of every kind or nature,
            known and unknown, suspected and unsuspected, disclosed and
            undisclosed, arising out of or in any way related to such disputes
            or our App.
          </p>
          <p>
            Links: The App and Services may contain links to other websites and
            online resources, including payment gateways. A link to a third
            party’s website does not mean that we endorse it or that we are
            affiliated with it. We are not responsible or liable for any damage
            or loss related to the use of any third-party website. You should
            always read the terms and conditions and privacy policy of a
            third-party website before using it. Promotion and marketing offer:
            We may offer promotional offers from time to time which may include
            discount codes or account credits, may be subject to expiration
            dates, and may only apply to selected users of a Service. We reserve
            the right to withhold or remove credit from a user account or end a
            promotion without notice if at our sole discretion we believe a user
            is not acting in good faith about the terms of the promotion.
            Ownership: We own or license all rights, title, and interest in and
            to (a) the App and Services, including all software, text, media,
            look and feel of the App and Services, and (b) our trademarks,
            logos, and brand elements. We respect the intellectual property of
            others. In case you feel that your work has been copied in a way
            that constitutes copyright infringement, you can write to us at
            queries@gonuts.com
          </p>
          <p>
            Rights and Responsibilities: Upon your continued compliance with
            these Terms of Use, you have a non-exclusive, worldwide license to
            use a video message purchased on the App, for your own personal,
            non-commercial, and non-promotional purposes. A video message is
            licensed and not sold to you. You may not modify or edit a video
            message or re-sell your rights in any way. If you breach any of the
            provisions herein, your license to the video message is terminated
            with immediate effect and without further notice. You shall not
            remove or modify any copyright and/or other intellectual property
            notices or watermarks from any video messages or the Services.
          </p>
          <p>
            By use of the platform for delivering the video message uploaded by
            you, you hereby grant Company a worldwide, non-exclusive,
            royalty-free, and transferable right to use, modify or edit to fit
            the format of delivery, reproduce, distribute, display, publish,
            adapt, make available online or electronically transmit, and perform
            the video message in connection with the Services and Company's (and
            its successors' and affiliates') business, including without
            limitation for promoting and redistributing part or all of the App
            and/or the Services in any media formats and through any media
            channels. You provide Company the undisputed and irrevocable right
            to apply its watermark, brand, and logo including that of its
            partners and affiliates on the video message uploaded by you.
          </p>
          <p>
            Privacy: We are committed to respecting your privacy and the privacy
            of all individuals using the Services. More information regarding
            how we may use your personal data can be found in our Privacy
            Policy. By using any of the services, you agree to the terms laid
            out in our Privacy Policy which is incorporated into these terms by
            reference. General Prohibitions: In addition to the prohibitions
            imposed on you under applicable law, you agree not to do any of the
            following: Post, request for, upload, publish, submit or transmit
            any content that:
          </p>
          <p>
            infringes, misappropriates, or violates a third party’s patent,
            copyright, trademark, trade secret, moral rights or other
            intellectual property rights, or rights of publicity or privacy;
            violates, or encourages any conduct that would violate, any
            applicable law or regulation or would give rise to civil liability;
            is fraudulent, false, misleading, or deceptive; is defamatory,
            obscene, or pornographic; promotes or constitutes discrimination,
            bigotry, racism, hatred, harassment, or harm against any individual
            or group; is violent or threatening or promotes violence or actions
            that are threatening to any person or entity; promotes illegal or
            harmful activities or substances; contains personal or contact
            information about any other person without their prior
            authorization; or violates any content policies Company has posted
            for the Services. Send any unsolicited or unauthorized advertising,
            promotional materials, email, junk mail, spam, chain letters, or
            another form of solicitation You shall not use any "deep-link",
            "page-scrape", "robot", "spider" or another automatic device,
            program, algorithm, or methodology, or any similar or equivalent
            manual process, to access, acquire, copy or monitor any portion of
            the App, Services or any Content, or in any way reproduce or
            circumvent the navigational structure or presentation of the App,
            Services or any Content, to obtain or attempt to obtain any
            materials, documents or information through any means not purposely
            made available through the App. You shall not engage in advertising
            to, or solicitation of, other users of the App to buy or sell any
            products or services, including, but not limited to, products or
            services related to that being displayed on the App or related to
            us. Encourage or enable any other individual to do any of the
            foregoing.
          </p>
          <p>
            Indemnity: You shall indemnify and hold harmless Company (including
            its affiliates, subsidiaries, and group companies), its directors,
            advisors, employees, agents, partners, suppliers, or content
            providers, from any claim or demand, or actions including reasonable
            attorneys' fees, made by any third party or penalty imposed due to
            or arising out of your breach of these Terms of Use, Privacy Policy
            and other policies, or your violation of any law, rules or
            regulations or the rights of a third party. Company reserves the
            right to assume the exclusive defense and control of any matter
            otherwise subject to indemnification by you, in which event you will
            assist and cooperate with us in asserting any available defenses.
            Disclaimer of warranties and Limitation of Liability: YOUR USE OF
            THE SERVICES IS SOLELY AT YOUR OWN RISK. We do not warrant that the
            App will be compatible with all hardware and software which you may
            use. To the fullest extent permitted by law, Company excludes all
            warranties, conditions, terms, or representations about the accuracy
            or completeness of Apps or any sites linked to the App. The Services
            and content are provided "AS IS", "AS AVAILABLE" and without
            warranty of any kind, express or implied including, but not limited
            to, the implied warranties concerning title; non-infringement;
            merchantability; uninterrupted, timely, secure or error-free use of
            the App or Services; and fitness for a particular purpose; and any
            warranties implied by any course of performance or usage of trade;
            all of which are expressly disclaimed.
          </p>
          <p>
            YOU UNDERSTAND AND AGREE THAT ANY MATERIAL OR INFORMATION DOWNLOADED
            OR OTHERWISE OBTAINED THROUGH THE USE OF THE APP OR SERVICES,
            INCLUDING WITHOUT LIMITATION, VIDEO MESSAGES, IS DONE AT YOUR OWN
            RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE IF ANY
            ARISING FROM DOING SO. NO ADVICE OR INFORMATION, WHETHER ORAL OR
            WRITTEN, OBTAINED BY YOU FROM US OR THROUGH THE SITE OR SERVICES
            WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE.
          </p>
          <p>
            IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS,
            ADVISORS, PARTNERS, SUPPLIERS, OR CONTENT PROVIDERS, BE LIABLE UNDER
            CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE, OR ANY OTHER LEGAL OR
            EQUITABLE THEORY CONCERNING THE SERVICES (I) FOR ANY LOST PROFITS,
            DATA LOSS, GOODWILL, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
            SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY
            OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, SUBSTITUTE GOODS OR
            SERVICES (HOWEVER ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN
            HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE), OR (III) FOR ANY
            DIRECT DAMAGES WILL BE LIMITED TO THE AMOUNT RECEIVED BY US FROM YOU
            WITHIN THE LAST 12 MONTHS TOWARDS THE SERVICES.
          </p>
          <p>
            You understand and agree that we have set our prices and entered
            into these Terms of Use with you in reliance upon the limitations of
            liability outlined in these Terms, which allocate risk between us
            and form the basis of a bargain between the parties.
          </p>
          <p>
            General Provisions Force Majeure: Under no circumstances will we be
            held liable for any delay or failure in performance due in whole or
            in part to any acts of nature labor disputes, strikes, acts of God,
            floods, lightning, severe weather, shortages of materials,
            rationing, inducement of any virus, Trojan or other disruptive
            mechanisms, any event of hacking or illegal usage of the App,
            utility or communication failures, earthquakes, war, revolution,
            acts of terrorism, civil commotion, acts of public enemies,
            blockade, embargo or any law, order, proclamation, regulation,
            ordinance, demand or requirement having legal effect of any
            government or any judicial authority or representative of any such
            government, or any other act whatsoever, whether similar or
            dissimilar to those referred to in this clause beyond our reasonable
            control. If a Force Majeure event takes place that affects the
            performance of our obligations under these terms of use our
            obligations under these terms shall be suspended for the duration of
            the Force Majeure event.
          </p>
          <p>
            Applicable Law: These Terms shall be governed by and interpreted and
            construed following the laws of India. The courts in Bengaluru shall
            have exclusive jurisdiction in respect of any matters arising here.
          </p>
          <p>
            Dispute Resolution: In the event, any dispute arises out of or in
            connection with the Terms of Use herein, including the validity
            hereof, the parties hereto shall endeavor to settle such dispute
            amicably in the first instance. The attempt to bring about an
            amicable settlement shall be treated as having failed as soon as one
            of the parties hereto, after reasonable attempts, which shall
            continue for not less than 15 (Fifteen) calendar days, gives a
            notice to this effect, to the other party in writing. In case of
            such failure, the dispute shall be referred to a sole arbitrator,
            who shall be appointed by Company. The arbitration proceedings shall
            be governed by the Indian Arbitration and Conciliation Act, 1996,
            and shall be held in Bengaluru. The language of the arbitration
            shall be English.
          </p>
          <h2>Contact Us</h2>
          <div className="mb-5">
            <p className="">
              Please contact us for any questions or comments (including all
              inquiries unrelated to copyright infringement) regarding this App.
            </p>
            <ul className="my-3">
              <li>
                By visiting this page on our website:&nbsp;
                <Link to={"/contact"}>https://ishout.io/contact</Link>
              </li>
              <li>
                <span className="text-white">By email: </span>
                <a href="mailto:info@ishout.io" rel="noreferrer">
                  info@ishout.io
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Privacy;
