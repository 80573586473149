import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Spinner as BootStrapSpinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  BsFillCalendar2PlusFill,
  BsFillCalendar2MinusFill,
} from "react-icons/bs";

import { Footer, Navbar } from "../../Component";

import * as Urls from "../../constants/ApiConstants";
import {
  GenericPostApiCall,
  GenericGetApis,
  GenericPostApiWithParams,
} from "../../Services";

import "./Styles.css";

const MemorableDays = () => {
  // // useParams
  // const { action } = useParams();

  // // useNavigate
  // const navigate = useNavigate();

  // use State => Initialize state variables
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [initialLoader, setInitialLoader] = useState(true);
  const [memorableDayData, setMemorableDay] = useState([]);

  // useEffect
  useEffect(() => {
    GetAllMemorableDays();
  }, []);

  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    if (data?.day && data?.event) {
      setIsLoading(true);

      const selectedDate = new Date(data?.day);

      const url = Urls.AddMemorableDay;
      const body = {
        day: selectedDate.getDate(),
        month: selectedDate.getMonth() + 1,
        event: data?.event,
      };
      const [res, error] = await GenericPostApiCall(url, body);
      if (error) {
        // console.log("error in p-h-submit-api");
      }
      // console.log(res, error);
      if (res?.code === 200) {
        reset();
        GetAllMemorableDays();
      } else {
        // console.log("error coming from add memorable day api submit", error);
        alert(
          "Attention! we are unable to complete the request, please try again"
        );
      }

      setIsLoading(false);
    } else {
      alert(
        "Attention! we can't process your request, please make sure you have entered event and choose day and month"
      );
    }
  };

  const GetAllMemorableDays = async () => {
    setInitialLoader(true);
    const url = Urls.GetMemorableDays;
    const [response, error] = await GenericGetApis(url);
    if (error) {
      // console.log("error= ", error);
    }
    // console.log(response);
    if (response?.code === 200) {
      setMemorableDay(response.memorableDays);
    } else {
      // console.log(error, "coming from setMemorableDay function");
      setMemorableDay([]);
    }
    setInitialLoader(false);
  };

  const deleteMemorableDay = async (id) => {
    setIsLoadingDelete(true);
    const url = Urls.RemoveMemorableDay;
    const params = {
      key: id,
    };
    const body = {};
    const [response, error] = await GenericPostApiWithParams(url, params, body);
    if (error) {
      // console.log("error in p-h-de-mem-api");
    }
    // console.log(response);
    if (response?.code === 200) {
      // console.log("completed");
      GetAllMemorableDays();
    } else {
      // console.log(error, "coming from setMemorableDay function");
      alert(
        "Attention! we are unable to complete the request, please try again"
      );
    }
    setIsLoadingDelete(false);
  };

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  const allDays = [];
  for (let k = 1; k < 32; k++) {
    allDays.push(k);
  }

  return (
    <div className="homeContainer">
      <Navbar />
      <Container style={{ minHeight: "90vh" }}>
        <div>
          <Col lg={6} md={8} sm={10}>
            <div className="ch-email-main-heading pt-md-3 pt-0 pb-2">
              Memorable days
            </div>
            <div className="ch-email-main-wrpr">
              {initialLoader ? (
                <>
                  <div className="text-center">
                    <BootStrapSpinner
                      animation="grow"
                      role="status"
                      aria-hidden
                      variant="light"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </BootStrapSpinner>
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-4">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <div className="d-flex gap-3">
                        <div className="flex-grow-1">
                          <Form.Control
                            placeholder="Joel's Birthday"
                            className={
                              errors.event
                                ? "pr-home-form-control-basic errorBoarder"
                                : "pr-home-form-control-basic"
                            }
                            {...register("event", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                              maxLength: {
                                value: 100,
                                message:
                                  "Text length is limited to 100 characters",
                              },
                            })}
                          />
                          {errors.event && (
                            <div className="errorText">
                              {errors.event.message}
                            </div>
                          )}
                        </div>
                        <div className="">
                          <Form.Control
                            type="date"
                            className={
                              errors.day
                                ? "pr-home-form-control-basic errorBoarder"
                                : "pr-home-form-control-basic"
                            }
                            {...register("day", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                            })}
                          ></Form.Control>
                          {errors.day && (
                            <div className="errorText">
                              {errors.day.message}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="mt-1">
                            <button
                              disabled={isLoading}
                              className="pf-home-mem-days-submit-btn"
                              type="submit"
                            >
                              {isLoading ? (
                                <BootStrapSpinner size="sm" variant="light" />
                              ) : (
                                <BsFillCalendar2PlusFill
                                  size={20}
                                  color="#79fc38"
                                  title="Add a new memorable day"
                                ></BsFillCalendar2PlusFill>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                  <hr style={{ color: "#a7a7a7" }}></hr>
                  <div className="mt-4">
                    {memorableDayData.length > 0 &&
                      memorableDayData?.map((ev, index) => (
                        <div
                          className="d-flex gap-4 mb-3 align-items-stretch"
                          key={index}
                        >
                          <div className="flex-fill">
                            <div className="pf-home-mem-days-event">
                              {ev.event}
                            </div>
                          </div>
                          <div className="" style={{ maxWidth: "200px" }}>
                            <div className="pf-home-mem-days-date-main-div">
                              <div className="pf-home-mem-days-date-text">
                                {ev.day}
                              </div>
                              <div className="pf-home-mem-days-date-text">
                                {getMonthName(ev.month)}
                              </div>
                            </div>
                          </div>

                          <div
                            role="button"
                            className="deleteMemButtonStyle"
                            onClick={() => deleteMemorableDay(ev.id)}
                          >
                            {isLoadingDelete ? (
                              <BootStrapSpinner size="sm" variant="light" />
                            ) : (
                              <BsFillCalendar2MinusFill
                                size={14}
                                color="#FA6638"
                                title="Remove this memorable day"
                              ></BsFillCalendar2MinusFill>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
          </Col>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default MemorableDays;
