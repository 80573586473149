import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Col, Container, Row, Spinner } from "react-bootstrap";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import * as apiUrls from "../../constants/ApiConstants";
import { GenericPostApiCall } from "../../Services";
import SpinnerComponent from "../../Component/Spinner";

export default function CheckoutForm({ orderId, total }) {
  // params
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isWaitForStripe, setIsWaitForStripe] = useState(false);

  useEffect(() => {
    setIsInitialLoading(false);
  }, []);

  const handleSubmit = async (e) => {
    setIsWaitForStripe(true);
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    setIsWaitForStripe(false);
    setIsProcessing(true);

    if (error) {
      // console.log(error);
      setMessage(error?.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      const body = {
        orderId,
        paymentMethod: "stripe",
        total,
        serviceCharge: "",
        currency: "USD",
        paymentId: paymentIntent?.id,
      };

      const url = apiUrls.OrderConfirm;
      const [res, error] = await GenericPostApiCall(url, body);
      // console.log("res = ", res, "error = ", error);
      if (res?.status === "SUCCESS") {
        // console.log(res);
        navigate(`/payment-success/${res.orderId}`);
      } else {
        // console.log("error in or-cnf-stp-post-api");
        const disputeOrderBody = {
          orderId,
          paymentMethod: "stripe",
          amount: total,
          serviceCharge: "",
          currency: "USD",
          paymentId: paymentIntent?.id,
        };
        const [refund_res, refund_err] = await GenericPostApiCall(
          apiUrls.RequestManualRefund,
          disputeOrderBody
        );
        // console.log("refund_res = ", refund_res, "refund_err = ", refund_err);
        if (refund_res?.code === 200) {
          // console.log("req-rf-post-api processed");
        } else {
          // console.log("error in req-rf-post-api");
        }

        alert(
          "There was an error processing your payment. If money has been debited from your account, we will refund within 2-7 days"
        );
      }
    }
    setIsProcessing(false);
  };

  // paypal functionality
  const client_id =
    "AcqPIYjYfcIPDHH-jelkp18ndn7upNKvOsnLjQ8BSKxujIuUYvhnObBnxN4H3EpCyG3dDdGsxOfpr8h2"; // Replace with your PayPal Client ID

  const handlePaymentSuccess = async (details) => {
    // console.log(
    //   "Payment successful!",
    //   details?.id,
    //   details?.status === "COMPLETED"
    // );
    // Perform additional actions after successful payment

    setIsProcessing(true);
    if (details?.status === "COMPLETED") {
      const body = {
        orderId,
        paymentMethod: "paypal",
        total,
        serviceCharge: "",
        currency: "USD",
        paymentId: details?.purchase_units[0].payments?.captures[0].id,
        paypalPaymentModeWeb: true,
      };

      const url = apiUrls.OrderConfirm;
      const [res, error] = await GenericPostApiCall(url, body);
      // console.log("res = ", res, "error = ", error);
      if (res?.status === "SUCCESS") {
        navigate(`/payment-success/${res.orderId}`);
      } else {
        // console.log(error);
        // console.log("error in or-cnf-pay-post-api");
        const [refund_res, refund_err] = await GenericPostApiCall(
          apiUrls.RequestManualRefund,
          body
        );
        // console.log("refund_res = ", refund_res, refund_err);
        if (refund_res?.code === 200) {
          // console.log("req-rf-post-api processed");
        } else {
          if (refund_err) {
          }
          // console.log("error in req-rf-post-api");
        }
        alert(
          "There was an error processing your payment. If money has been debited from your account, we will refund within 2-7 days"
        );
      }
    } else {
    }

    setIsProcessing(false);
  };

  const handlePaymentError = (error) => {
    // console.error("Payment error!", error);
    alert("There was an error processing your payment, please try again!");
  };

  const handlePaymentCancel = () => {
    alert("This payment is cancelled! please try again");
  };

  return (
    <>
      <Container>
        {isProcessing || isInitialLoading ? (
          <SpinnerComponent />
        ) : (
          <>
            <div className="payment-main-all-wrpr">
              <Row className="justify-content-center">
                <Col lg={7} md={8} sm={10} xs={12}>
                  <div className="newCardWrpsPay -paddi16">
                    <div className="pay-card-heading">Payment info</div>
                    {/* {paypal start} */}
                    <>
                      <PayPalScriptProvider
                        options={{
                          "client-id": client_id,
                          intent: "capture",
                          currency: "USD",
                        }}
                      >
                        <PayPalButtons
                          createOrder={(data, actions) => {
                            // console.log("data = ", data);
                            // console.log("actions= ", actions);
                            return actions.order.create({
                              purchase_units: [
                                {
                                  amount: {
                                    value: total, // Set the amount for the payment
                                  },
                                },
                              ],
                            });
                          }}
                          onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {
                              // console.log("data = ", data);
                              //console.log("details = ", details);
                              // console.log("type of details = ", typeof details);
                              handlePaymentSuccess(details); // Callback function when payment is successful
                            });
                          }}
                          onError={handlePaymentError} // Callback function when an error occurs
                          onCancel={handlePaymentCancel}
                          fundingSource="paypal"
                          style={{ shape: "pill" }}
                          // fundingSource={{
                          //   : "disabled", // Disable credit card payment
                          // }}
                        />
                      </PayPalScriptProvider>
                    </>
                    {/*  */}
                    <div className="or-line-payment">
                      <div className="or-line-payment-or-abs">
                        <div className="or-line-payment-or">OR</div>
                      </div>
                    </div>
                    {/* {paypal end} */}
                    <form id="payment-form" onSubmit={handleSubmit}>
                      <PaymentElement id="payment-element" />
                      {/* Show any error or success messages */}
                      {message && (
                        <div id="payment-message" style={{ color: "red" }}>
                          {message}
                        </div>
                      )}

                      <div>
                        <div className="pay-card-heading1">Summary</div>
                        <div className="sep-line-12"></div>
                        <div className="summary-wrprs">
                          <div className="summary-names">
                            iShout personalized video
                          </div>
                          <div className="summary-names">{`$ ${total}`}</div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="sep-btn-p" />
                      <button
                        disabled={isWaitForStripe}
                        className="-btn-books-now"
                      >
                        {!isWaitForStripe ? (
                          `Pay now`
                        ) : (
                          <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        )}
                      </button>
                    </form>
                    <div className="text-center text-white pt-2">
                      <Link to={"/"} className="btn btn-link">
                        I need to cancel and back to home
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Container>
    </>
  );
}
