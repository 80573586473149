import React, { useContext, useState, useEffect } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { useForm } from "react-hook-form";

import { MyContext } from "../../Store";
import { Footer, Navbar } from "../../Component";
import * as apiUrls from "../../constants/ApiConstants";
import { GenericPostApiCall } from "../../Services";
// import SpinnerComponent from "../../Component/Spinner";

import "./Styles.css";

const industryList = [
  { id: 1, name: "Advertising/Marketing" },
  { id: 2, name: "Agency" },
  { id: 3, name: "Auto" },
  { id: 4, name: "B2B" },
  { id: 5, name: "Consumer Packaged Goods" },
  { id: 6, name: "Crypto" },
  { id: 7, name: "eCommerce" },
  { id: 8, name: "Education" },
  { id: 9, name: "Entertainment/Media" },
  { id: 10, name: "Events" },
  { id: 11, name: "Fashion & Beauty" },
  { id: 12, name: "Financial Services" },
  { id: 13, name: "Gaming" },
  { id: 14, name: "Health & Wellness" },
  { id: 15, name: "Healthcare/Pharmacy" },
  { id: 16, name: "Insurance/Legal" },
  { id: 17, name: "Non-profit" },
  { id: 18, name: "Professional Services" },
  { id: 19, name: "Real Estate" },
  { id: 20, name: "Restaurants" },
  { id: 21, name: "Sports" },
  { id: 22, name: "Technology & Telecom" },
  { id: 23, name: "Travel" },
  { id: 24, name: "Other" },
];

// const socialMeida = [
//   { id: 1, name: "Twitter" },
//   { id: 2, name: "Instagram" },
//   { id: 3, name: "Youtube" },
//   { id: 4, name: "Facebook" },
//   { id: 5, name: "TikTok" },
//   { id: 6, name: "Other" },
// ];

const website_regex = new RegExp(
  "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
);

const BusinessBookingForm = () => {
  const navigate = useNavigate();
  // useContext
  const { businessFormOccasions } = useContext(MyContext);
  const [businessOccasions] = businessFormOccasions;

  // useLocation
  const location = useLocation();
  const { orderData } = location.state;

  // destructure celebrity data
  const {
    celebrityFirstName,
    celebrity,
    celebrityPhoto,
    businessDescription,
    businessIndustry,
    businessName,
    businessSocialHandle,
    businessSocialPlatform,
    celebrityContentLanguages,
    contentLanguage,
    instruction,
    isOrderIsPublic,
    occasion,
    order_id,
    businessWebSite,
    status,
  } = orderData;

  const celebrityName = `${celebrityFirstName} ${celebrity}`;

  // useState
  const [isLoading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(!isOrderIsPublic);
  const [instructionsCharLength, setInstructionsCharLength] = useState(
    instruction.length
  );
  const [instructionsColor, setInstructionsColor] = useState("#e1e1e1");
  const [validateLoader, setValidateLoader] = useState(false);

  let submitBtnTitle = "Edit personalized video order";
  let submitBtnDisabled = false;
  if (status === "Accept") {
    submitBtnTitle = `${celebrityName} has already accepted your video request! You cannot edit`;
    submitBtnDisabled = true;
  }

  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      businessName: businessName,
      websiteUrl: businessWebSite,
      industryType: businessIndustry,
      purpose: occasion.id,
      description: businessDescription,
      Instructions: instruction,
      socialMedia: businessSocialPlatform,
      socialMediaUrl: businessSocialHandle,
      language: selectedLanguage ? selectedLanguage : contentLanguage.id,
    },
  });

  // useEffect
  useEffect(() => {
    if (celebrityContentLanguages.length === 1) {
      setSelectedLanguage(celebrityContentLanguages[0].id);
    }
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    const url = `${apiUrls.UpdateOrder}?key=${order_id}`;

    const body = {
      instruction: data?.Instructions,
      businessName: data?.businessName,
      industry: data?.industryType,
      occasion: data?.purpose,
      description: data?.description ? data?.description : "",
      businessWebsite: data?.websiteUrl ? data?.websiteUrl : "",
      businessSocialHandle: data?.socialMediaUrl ? data?.socialMediaUrl : "",
      businessSocialPlatform: data?.socialMedia ? data?.socialMedia : "",
      contentLanguage: data?.language ? data?.language : selectedLanguage,
      isOrderPublic: isPrivacyChecked === false ? 1 : 0,
    };

    const [res, error] = await GenericPostApiCall(url, body);
    if (res?.code === 200) {
      navigate(-1);
    } else {
      // console.log(
      //   "error happened at order edit api (business), details = ",
      //   error
      // );
      alert("Attention! Unable to complete the request, please try again");
    }
    setLoading(false);
  };

  const handleCharLength = (event) => {
    setInstructionsCharLength(event.target.value.length);
    if (event.target.value.length > 500) {
      setInstructionsColor("#df220d");
    } else {
      setInstructionsColor("#e1e1e1");
    }
  };

  const validateInstructions = async (value) => {
    let returnFlag = true;
    setValidateLoader(true);
    const [response, error] = await GenericPostApiCall(
      apiUrls.ValidateErrorCheck,
      {
        inputText: value,
      }
    );
    if (!response.valid) {
      const detectedWords = response?.detected_data
        .map((item) => item)
        .join(", ");
      returnFlag = `Reminder of Community Guidelines! Please do not type any naughty/obscene words! (Found ${detectedWords})`;
    } else {
      clearErrors("Instructions.validate");
      returnFlag = true;
    }
    if (error) {
      // console.log("error in gt-o-bdwrds-api");
      clearErrors("Instructions.validate");
      returnFlag = true;
    }
    setValidateLoader(false);
    return returnFlag;
  };

  return (
    <>
      <div className="bookingContainer">
        <Navbar />
        <Container>
          <div className="mainWrpr-pbf">
            <Row className="mb-3 justify-content-center">
              <Col lg={7} md={8} sm={10} xs={12}>
                <div className="d-flex arrowBack">
                  <Link to={`/orders`}>
                    <IoArrowBack style={{ color: "#FFF" }}></IoArrowBack>
                    <span
                      className="text-white"
                      style={{
                        paddingLeft: "5px",
                      }}
                    >
                      Orders
                    </span>
                  </Link>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={7} md={8} sm={10} xs={12}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="cardWrpr mb-4">
                    <div className="c-f-s-wrprs">
                      <div className="c-img-wrpr">
                        {/* add a person avatar here if no profile image is present */}
                        <img
                          src={celebrityPhoto ? celebrityPhoto : ""}
                          alt="man"
                          className="w-100"
                        />
                      </div>
                      <div className="whosName">Edit order #{order_id}</div>
                    </div>
                    <div className="lineStyles" />
                    <>
                      <div className="pfa-a-heading1">
                        Business name (required)
                      </div>
                      <Form.Control
                        placeholder="How should they refer to your business?"
                        className={
                          errors.businessName
                            ? "custom-pfa-class-style errorBoarder"
                            : "custom-pfa-class-style"
                        }
                        {...register("businessName", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                          maxLength: {
                            value: 100,
                            message: "Text length is limited to 100 characters",
                          },
                        })}
                      />
                      {errors.businessName && (
                        <div className="errorText">
                          {errors.businessName.message}
                        </div>
                      )}
                    </>
                    <div className="sepinput-size12"></div>
                    <>
                      <div className="pfa-a-heading1">Industry (required)</div>
                      <Form.Select
                        aria-label="Default select example"
                        className={
                          errors.industryType
                            ? "form-select-custm-class errorBoarder"
                            : "form-select-custm-class"
                        }
                        {...register("industryType", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                        })}
                      >
                        <optgroup label="Please select your business industry">
                          {industryList?.map((i, index) => (
                            <option value={i.name} key={index}>
                              {i.name}
                            </option>
                          ))}
                        </optgroup>
                      </Form.Select>
                      {errors.industryType && (
                        <div className="errorText">
                          {errors.industryType.message}
                        </div>
                      )}
                    </>
                    <div className="sepinput-size12"></div>
                    <>
                      <div className="pfa-a-heading1">Purpose (required)</div>
                      <Form.Select
                        aria-label="Default select example"
                        className={
                          errors.purpose
                            ? "form-select-custm-class errorBoarder"
                            : "form-select-custm-class"
                        }
                        {...register("purpose", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                        })}
                      >
                        <optgroup label="Please select the purpose">
                          {businessOccasions &&
                            businessOccasions.map((i, index) => (
                              <option value={i.id} key={index}>
                                {i.name}
                              </option>
                            ))}
                        </optgroup>
                      </Form.Select>
                      {errors.purpose && (
                        <div className="errorText">
                          {errors.purpose.message}
                        </div>
                      )}
                    </>
                    <div className="sepinput-size12"></div>
                    <>
                      <div className="pfa-a-heading1">Description</div>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        className={
                          errors.description
                            ? "custom-pfa-class-style-multi errorBoarder"
                            : "custom-pfa-class-style-multi"
                        }
                        placeholder="Explain briefly the business"
                        {...register("description", {
                          maxLength: {
                            value: 100,
                            message: "Text length is limited to 100 characters",
                          },
                        })}
                      />
                      {errors.description && (
                        <div className="errorText">
                          {errors.description.message}
                        </div>
                      )}
                    </>
                    <div className="sepinput-size12"></div>
                    <>
                      <div className="pfa-a-heading1">Website</div>
                      <Form.Control
                        placeholder="www.website.com | http://website.com"
                        className={
                          errors.websiteUrl
                            ? "custom-pfa-class-style errorBoarder"
                            : "custom-pfa-class-style"
                        }
                        {...register("websiteUrl", {
                          maxLength: {
                            value: 100,
                            message: "Text length is limited to 100 characters",
                          },
                          pattern: {
                            value: website_regex,
                            message: "Website you entered is incorrect",
                          },
                        })}
                      />
                      {errors.websiteUrl && (
                        <div className="errorText">
                          {errors.websiteUrl.message}
                        </div>
                      )}
                    </>
                    {/* <div className="sepinput-size12"></div>
                  <>
                    <div className="pfa-a-heading1">Where can we find you?</div>
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-custm-class"
                      {...register("socialMedia", { required: false })}
                    >
                      <option value={""} selected disabled>
                        Select social media
                      </option>
                      {socialMedia?.map((i, index) => (
                        <option value={i.name} key={index}>{i.name}</option>
                      ))}
                    </Form.Select>
                  </>
                  <div className="sepinput-size12"></div>
                  <>
                    <div className="pfa-a-heading1">Social Media Url</div>
                    <Form.Control
                      placeholder="Enter your socail media url"
                      className="custom-pfa-class-style"
                      {...register("socialMediaUrl", { required: false })}
                    />
                  </> */}
                  </div>
                  <div className="cardWrpr mb-4">
                    <>
                      <div className="pfa-a-heading1">
                        Instructions (required){" "}
                        {validateLoader ? <Spinner size="sm" /> : ``}
                      </div>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        className={
                          errors.Instructions
                            ? "custom-pfa-class-style-multi errorBoarder"
                            : "custom-pfa-class-style-multi"
                        }
                        placeholder="What do you want them to say and do? (Scripts are acceptable.)"
                        onInput={handleCharLength}
                        {...register("Instructions", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                          maxLength: {
                            value: 500,
                            message: "Text length is limited to 500 characters",
                          },
                          validate: validateInstructions,
                        })}
                      />
                      <div className="position-relative mb-3">
                        <div className="position-absolute start-0 end-30">
                          {errors.Instructions && (
                            <div className="bbInsND">
                              {errors.Instructions.message}
                            </div>
                          )}
                        </div>
                        <div
                          className="position-absolute end-0 bbInsND"
                          style={{ color: instructionsColor }}
                        >
                          {instructionsCharLength}/500 characters
                        </div>
                      </div>
                    </>
                    {celebrityContentLanguages.length > 1 && (
                      <div className="sepinput-size12"></div>
                    )}
                    <>
                      <div className="chips-wrpr">
                        {celebrityContentLanguages.length > 1 && (
                          <>
                            <div className="sep-32-custom" />
                            <div className="pfa-a-heading1">
                              Video language (required)
                            </div>
                            <Form.Select
                              aria-label="Default select example"
                              className={
                                errors.language
                                  ? "form-select-custm-class errorBoarder"
                                  : "form-select-custm-class"
                              }
                              {...register("language", {
                                required: {
                                  value: true,
                                  message: "Required",
                                },
                              })}
                            >
                              <optgroup label="Select preferred language from the list">
                                {celebrityContentLanguages?.map((i, index) => (
                                  <option value={i.id} key={index}>
                                    {i.name}
                                  </option>
                                ))}
                              </optgroup>
                            </Form.Select>
                            {errors.language && (
                              <div className="errorText">
                                {errors.language.message}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </>

                    <div className="btn-pfa-wrpr">
                      <div className="c-b-wrpr-s mb-3">
                        <Form.Check
                          type={"checkbox"}
                          className="cehckbox-custom"
                          checked={isPrivacyChecked}
                          name="isPrivate"
                          onChange={(e) => {
                            setIsPrivacyChecked((current) => !current);
                          }}
                        />
                        <div className="hide-wrpr">
                          Hide this video from {celebrityName} profile
                        </div>
                      </div>
                      <button
                        type="submit"
                        className={
                          status === "Accept"
                            ? "continue-btns1 submitBtnStyle"
                            : "continue-btns1"
                        }
                        disabled={submitBtnDisabled}
                        title={submitBtnTitle}
                      >
                        {isLoading ? <Spinner size="sm" /> : `Submit`}
                      </button>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default BusinessBookingForm;
