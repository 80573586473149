import React, { useState } from "react";
import "./Styles.css";
import { Container } from "react-bootstrap";
import { Navbar, Footer } from "../../Component";
import ReactPlayer from "react-player";
import Ale from "../../Assets/ale.jpg";
import Icons from "../../Icons";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";

const VideoPlayer = () => {
  // useState
  // const [isLoading, setIsLoading] = useState(true);
  const [url, setUrl] = useState("");

  // useLocation
  const location = useLocation();
  const {
    // videoProcessedThumbnail,
    // videoProcessedDownloadLink,
    videoProcessed,
  } = location.state;

  const handleDownload = () => {
    const videoUrl = videoProcessed;
    saveAs(videoUrl, "video.mp4");
  };

  return (
    <div className="v-p-container">
      <Navbar />
      <Container>
        <div className="v-p-inner-container">
          <div className="video-p-wrpr">
            <ReactPlayer
              controls
              playsinline={false}
              height={"100%"}
              width={"100%"}
              playing
              url={videoProcessed}
              onReady={() => setUrl(videoProcessed)}
            />
            <div className="v-d-cel-img-wrpr">
              <img src={Ale} alt={"ishoutVideo"} className="w-100" />
            </div>
            <button onClick={handleDownload} className="downloadBtns-v-d">
              <Icons icon={"download"} size={18} />
            </button>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default VideoPlayer;
