import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

import {
  CelebrityCard,
  Footer,
  HomepageHeading,
  Navbar,
  HowItWorks,
} from "../../Component";
import SpinnerComponent from "../../Component/Spinner";
import * as Urls from "../../constants/ApiConstants";
import { GenericGetApisNoToken } from "../../Services";
import { MyContext } from "../../Store";

import "./Styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  // useNavigate
  const navigate = useNavigate();

  // useState
  const [categoryData, setCategoryData] = useState([]);
  const [categoryListData, setCategoryListData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { listMainCategory } = useContext(MyContext);
  const [mainCategoryListData] = listMainCategory;

  // useEffect
  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const url = Urls.GetAllCategories;
      const response = await GenericGetApisNoToken(url);
      if (response[0].data) {
        const categoryDataArray = response[0].data.map((i, index) => ({
          name: i.display,
          key: i.key,
          url: `${Urls.GetCategoryDataByKey}?key=${i.key}`,
          data: [],
          index,
        }));
        setCategoryData(categoryDataArray);
        getAllData(categoryDataArray);
      }
    } catch (error) {
      // console.log("error from home page category list fetch api", error);
    }
  };

  const getAllData = async (categoryDataArray) => {
    try {
      const sessionId = sessionStorage.getItem("sessionId");
      const params = { session_key: sessionId };

      const dataPromises = categoryDataArray.map((category) =>
        fetch(category.url, { params }).then((res) => res.json())
      );
      const data = await Promise.all(dataPromises);
      const categoryData = data.map((response, index) => ({
        name: categoryDataArray[index].name,
        key: categoryDataArray[index].key,
        data: response.data,
        index: categoryDataArray[index].index,
        url: categoryDataArray[index].url,
      }));
      setCategoryData(categoryData.sort((a, b) => a.index - b.index));
    } catch (error) {
      // console.log(error);
    }
    GetAvailableCategory();
  };

  const GetAvailableCategory = async () => {
    const url = Urls.GetAvailableCategory;
    const [res, error] = await GenericGetApisNoToken(url);
    if (res?.code === 200) {
      setCategoryListData(res.data);
    } else {
      // console.log(error);
    }
    setIsLoading(false);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const handleViewAllClick = (url, title) => {
    navigate("/view-all", {
      state: {
        url: url,
        title: title,
      },
    });
  };

  const renderCategoryList = (item, index) => {
    const url =
      item.key === "featured"
        ? Urls.GetAllFeatured
        : item.key === "new-on-ishout"
        ? Urls.GetAllNewFaces
        : item.key === "recently_viewed"
        ? Urls.GetRecentlyViewed
        : `${Urls.GetAllCustomCategory}?key=${item.key}`;
    const title = item.name;

    return (
      <div key={index}>
        {item.data?.length > 0 && (
          <div className="mb-5">
            <HomepageHeading
              isViewAllVisible={true}
              title={title}
              onClick={() => handleViewAllClick(url, title)}
            />
            <div className="main-wrpr-h-p-scroll">
              {categoryData &&
                item?.data?.map((i, index) => (
                  <Link to={`/celeb/${i.username}`} key={index}>
                    <div className="h-c-l-scroll-wrps">
                      <CelebrityCard data={i} />
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="homeContainer">
      <Navbar />
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <>
          <Container>
            {/* ---slick start----- */}
            <div className="sli-con-mains">
              <Slider {...settings}>
                <div className="s-c-items">
                  <Row>
                    <Col xl={6} lg={7} md={9} sm={12}>
                      <div className="ali-i-c-j-home">
                        <div className="headings_banner_slick">
                          Connect with your favorite stars
                        </div>
                        <div className="slick-para-txt">
                          Browse through our numerous number of stars and get
                          your personalized video for any occasion just a few
                          simple steps.
                        </div>
                      </div>
                    </Col>
                    <Col
                      xl={6}
                      lg={5}
                      md={12}
                      sm={12}
                      // style={{ backgroundImage: `url(${SlickBg})` }}
                    >
                      {/* <img src={SlickBg} className="h-100" alt="ishout"></img> */}
                      {/* add video introduction here or either an image grid */}
                    </Col>
                  </Row>
                </div>
              </Slider>
            </div>
            {mainCategoryListData ? (
              <>
                <div className="mt-5 mb-4 mb-xs-2 home-page-heads text-center">
                  Get surprising videos from super stars!
                </div>

                <div className="mb-5 hm-main-cat-wrpr">
                  <Row className="d-flex flex-row flex-nowrap justify-content-start">
                    {mainCategoryListData &&
                      mainCategoryListData?.map((i, index) => (
                        <Col
                          xxl={1.5}
                          xl={2}
                          lg={2}
                          md={3}
                          sm={3}
                          xs={4}
                          key={index}
                        >
                          <Link
                            to={`browse/${i.slug}`}
                            className="h-p-l-i-link"
                          >
                            <div
                              style={{
                                width: "100%",
                                aspectRatio: 1 / 1,
                                borderRadius: "50%",
                                background:
                                  "linear-gradient(95.32deg, #282828 0.7%, #212021 98.35%)",
                                border: "2px solid",
                                position: "relative",
                              }}
                            >
                              <div
                                className="h-p-l-i-heading-name"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                {i.name}
                              </div>
                            </div>
                          </Link>
                        </Col>
                      ))}

                    <Col xxl={1.5} xl={2} lg={2} md={3} sm={3} xs={4}>
                      <Link to={`browse/view-all`} className="h-p-l-i-link">
                        <div
                          style={{
                            width: "100%",
                            aspectRatio: 1 / 1,
                            borderRadius: "50%",
                            background:
                              "linear-gradient(95.32deg, #282828 0.7%, #212021 98.35%)",
                            border: "2px solid",
                            position: "relative",
                          }}
                        >
                          <div
                            className="h-p-l-i-heading-name"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            View_all
                          </div>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div></div>
            )}

            {/* ---slick End------ */}
            <div className="c-list-wrprs-line">
              {categoryData?.map((items, index) =>
                renderCategoryList(items, index)
              )}
            </div>
            <div className="my-5">
              <div className="viewAllbtsWrpr">
                <div className="home-page-heads">How it works</div>
                <div className="my-3 text-white">
                  Get a personalized video in just four steps &nbsp;
                  <Link to={"/how-it-works"}>Learn more</Link>
                </div>
                <div className="mt-3">
                  <HowItWorks />
                </div>
              </div>
            </div>
            <div className="categories-wrpr-h-main">
              <HomepageHeading title={"Categories"} isViewAllVisible={false} />
              <div className="categiries-wors-scroll">
                {categoryListData &&
                  categoryListData?.map((i, index) => (
                    <Link to={`/celebrities/${i.slug}`} key={index}>
                      <div
                        className="categoriesWrpr-mains"
                        style={{
                          backgroundImage: `linear-gradient(to right, ${i.colorCodes?.colorOne}, ${i.colorCodes.colorTwo})`,
                        }}
                      >
                        {i.name}
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </Container>
        </>
      )}
      <Footer />
    </div>
  );
};

export default Home;
