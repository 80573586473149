// export const apiUrls = "https://theshoutoutapp.com/api/";
// export const adminBaseUrl = "https://theshoutoutapp.com/";

export const apiUrls = "https://api.ishout.io/v1/";
export const adminBaseUrl = "https://admin.ishout.io/";
export const apiUrlForWeb = "https://api.ishout.io/v1/web/";

export const sendRegistrationCodeToEmail = apiUrls + "client-send-email-code/";
export const checkUserName = apiUrls + "check-user-name/";
export const clientRegister = apiUrls + "client-register/";
export const ClientLogin = apiUrls + "client-login/";

export const GetAllCategories = apiUrlForWeb + "get-all-home-categories/";
export const GetCategoryDataByKey = apiUrlForWeb + "get-category-by-key/";
export const UserProfileDetails = apiUrls + "get-client-profile-details/";
export const CelebrityDetails = apiUrlForWeb + "celebrity/";
export const GetAllFeatured = apiUrlForWeb + "get-all-featured/";
export const GetAllNewFaces = apiUrlForWeb + "get-all-new-profiles/";
export const GetAllCustomCategory = apiUrlForWeb + "get-all-custom-category/";

export const GetAvailableCategory =
  apiUrlForWeb + "get-all-available-categories/";
export const GetMainCategory = apiUrlForWeb + "get-main-categories-list/";
export const GetAllMainCategoryDataByKey =
  apiUrlForWeb + "get-all-main-category-data-by-key/";
export const GetRecentlyViewed = apiUrlForWeb + "get-all-recently-viewed";
export const GetAllCategoryByKey = apiUrlForWeb + "get-all-category-by-key/";

export const ClientEditProfile = apiUrls + "client-edit-profile/";
export const ClientChangeMobile = apiUrls + "client-change-mobile/";
export const clientMobileVerified = apiUrls + "client-change-mobile-verified/";
export const getAllCurrency = apiUrls + "get-all-currency/";

export const ResetPassword = apiUrls + "reset-password/";
export const setNewPassword = apiUrls + "set-new-password/";

export const getAllOrders = apiUrls + "get-all-orders/";
export const getOrderDetails = apiUrls + "order-details/";

export const ClientChangeEmail = apiUrls + "client-change-email/";
export const ClientEmailVerified = apiUrls + "client-change-email-verified/";

export const GetMemorableDays = apiUrls + "get-memorable-days/";
export const RemoveMemorableDay = apiUrls + "remove-memorable-day/";
export const AddMemorableDay = apiUrls + "add-memorable-day/";

export const NewOrder = apiUrls + "new-order/";
export const OrderConfirm = apiUrls + "order-confirm/";
export const RequestManualRefund = apiUrls + "request-manual-refund/";
export const UpdateOrder = apiUrls + "update-order/";

export const CreatePaymentClientToken = apiUrls + "pay/generate-token/";
export const MakePaymentOrderPayPal = apiUrls + "pay/create-payment-order/";
export const GetPayPalClientId = apiUrls + "pay/get-paypal-client-id/";

export const Search = apiUrlForWeb + "search/";
export const GetReviews = apiUrlForWeb + "get-all-reviews";
export const GetOccasions = apiUrlForWeb + "get-occasions/";

export const postReview = apiUrls + "post-review/";

export const GetAllRecentVideos = apiUrls + "get-all-recent-videos/";

export const DownloadVideo = apiUrls + "download-video/";
export const ReportOrder = apiUrls + "report-order/";

export const ClientSocialRegister = apiUrls + "client-social-register/";
export const GetAllCountries = apiUrls + "get-all-countries/";
export const CelebrityEnroll = apiUrls + "enroll/";

export const GetAllBadWords = apiUrls + "get-badwords-based-language/";
export const ValidateErrorCheck = apiUrls + "validate-for-error-check/";

export const Contact = apiUrlForWeb + "contact/";
export const JoinNewsletter = apiUrlForWeb + "join-newsletter/";
export const SetNewPasswordForWeb = apiUrlForWeb + "set-new-password/";
export const NotifyClients = apiUrls + "notify-clients/";

// // web

// // export const apiUrls = 'http://192.168.43.42:8000/api/';
// export const sendRegistrationCode = apiUrls + "client-send-code/";
// export const UploadProfileImage = apiUrls + "upload-profile-image/";
// export const UpdateClientData = apiUrls + "update-client-data/";
// export const ClientChangePassword = apiUrls + "client-change-password/";
// export const clientChangeCurrency = apiUrls + "client-change-currency/";
// export const GetFavourites = apiUrls + "get-favourites/";
// export const AddToFavourites = apiUrls + "add-to-favourites/";
// export const RemoveFromFavourites = apiUrls + "remove-from-favourites/";
// export const GetFavouritesNames = apiUrls + "get-favourites-names/";
// export const ChangePasswordAuth = apiUrls + "client-change-password-auth/";
// export const GetNotifications = apiUrls + "get-notifications/";
// export const GetReviewDetails = apiUrls + "get-review-details/";
// export const ClientFcmToken = apiUrls + "client-fcm-token/";
// export const ClientNotStatus = apiUrls + "client-change-notification-status/";
// export const getAllFacesAvailable = apiUrls + "get-all-faces/available/";
// export const UpdateReview = apiUrls + "update-review/";
// export const ChangeOrderPrivacy = apiUrls + "change-order-privacy/";
// export const ReportOrder = apiUrls + "report-order/";
// export const GetCountryBasedLocation = apiUrls + "get-country-based-location/";
// export const OrderDetails = apiUrls + "order-details/";
// export const NotifyClients = apiUrls + "notify-clients/";
// export const DeleteAccount = apiUrls + "delete-account/";
// export const UserLogout = apiUrls + "user-logout/";
// export const commonReportOrder = apiUrls + "common-report-order/";
