import axios from "axios";

export const GenericGetApis = async (url) => {
  const token = localStorage.getItem("token");
  // console.log(token);

  if (token) {
    try {
      let response = await axios({
        url: url,
        headers: {
          Authorization: token,
          "Accept-Language": "en",
        },
      });
      if (response.status === 200 && response.data.code === 200) {
        return [response.data, null];
      } else {
        return [null, response.data];
      }
    } catch (error) {
      return [null, error];
    }
  }
};

export const GenericGetApisWithParams = async (url, params) => {
  const token = localStorage.getItem("token");
  // console.log(token);

  if (token) {
    try {
      let response = await axios({
        url: url,
        params: params,
        headers: {
          Authorization: token,
          "Accept-Language": "en",
        },
      });
      if (response.status === 200 && response.data.code === 200) {
        return [response.data, null];
      } else {
        return [null, response.data];
      }
    } catch (error) {
      return [null, error];
    }
  }
};

export const GenericGetApisNoToken = async (url) => {
  const sessionId = sessionStorage.getItem("sessionId");
  const params = { session_key: sessionId };
  try {
    let response = await axios({
      url: url,
      params: params,
    });
    if (response.status === 200 && response.data.code === 200) {
      return [response.data, null];
    } else {
      return [null, response.data];
    }
  } catch (error) {
    return [null, error];
  }
};

export const GenericGetApisNoTokenNoParams = async (url) => {
  try {
    let response = await axios({
      url: url,
    });
    if (response.status === 200 && response.data.code === 200) {
      return [response.data, null];
    } else {
      return [null, response.data];
    }
  } catch (error) {
    return [null, error];
  }
};

export const GenericGetApisExtraParams = async (url, params) => {
  try {
    let response = await axios({
      url: url,
      params: params,
    });
    if (response.status === 200 && response.data.code === 200) {
      return [response.data, null];
    } else {
      return [null, response.data];
    }
  } catch (error) {
    return [null, error];
  }
};

export const GenericPostApiWithParams = async (url, params, body) => {
  const token = localStorage.getItem("token");
  // console.log(token);
  try {
    let response = await axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: token,
        "Accept-Language": "en",
      },
      params: params,
      data: body,
    });
    if (response.status === 200 && response.data.code === 200) {
      return [response.data, null];
    } else {
      return [null, response.data];
    }
  } catch (error) {
    return [null, error];
  }
};

export const GenericPostApiCall = async (url, body) => {
  const token = localStorage.getItem("token");
  // console.log(token);
  try {
    let response = await axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: token,
        "Accept-Language": "en",
      },
      data: body,
    });
    if (response.status === 200 && response.data.code === 200) {
      return [response.data, null];
    } else {
      return [null, response.data];
    }
  } catch (error) {
    return [null, error];
  }
};

// export const GenericPostApiCall = async (url, body) => {
//   const token = await AsyncStorage.getItem("token");
//   try {
//     let response = await axios({
//       method: "POST",
//       url: url,
//       headers: {
//         Authorization: token,
//         "Accept-Language": "en",
//       },
//       data: body,
//     });
//     if (response.status === 200 && response.data.code === 200) {
//       return [response.data, null];
//     } else {
//       return [null, response.data];
//     }
//   } catch (error) {
//     return [null, error];
//   }
// };

export const GenericPostApiNoAuth = async (url, body) => {
  try {
    let response = await axios({
      method: "POST",
      url: url,
      headers: {
        "Accept-Language": "en",
      },
      data: body,
    });
    if (response.status === 200 && response.data.code === 200) {
      return [response.data, null];
    } else {
      return [null, response.data];
    }
  } catch (error) {
    return [null, error];
  }
};

// export const GenericGetApisNoAuth = async (url) => {
//   try {
//     let response = await axios({
//       url: url,
//     });
//     if (response.status === 200 && response.data.code === 200) {
//       return [response.data, null];
//     } else {
//       return [null, response.data];
//     }
//   } catch (error) {
//     return [null, error];
//   }
// };
