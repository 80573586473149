import { React, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import {
  Footer,
  Navbar,
  PageHeading,
  CelebrityCardForGrid,
} from "../../Component";
import { GenericGetApisNoToken } from "../../Services";
import SpinnerComponent from "../../Component/Spinner";

import "./Styles.css";

const ViewAll = () => {
  // useLocation
  const location = useLocation();
  const { url, title } = location.state;

  // useState
  const [allCelebrityData, setAllCelebrityData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [celebrityCount, setCelebrityCount] = useState();

  // useEffect
  useEffect(() => {
    fetchCelebrityList();
  }, []);

  const fetchCelebrityList = async () => {
    setIsLoading(true);
    const [res, error] = await GenericGetApisNoToken(url);
    if (res?.code === 200) {
      setAllCelebrityData(res?.data);
      setCelebrityCount(res?.data?.length);
    } else {
      // console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="homeContainer">
      <Navbar />
      <Container style={{ minHeight: "90vh" }}>
        {isLoading ? (
          <SpinnerComponent />
        ) : (
          <>
            <PageHeading
              title={title}
              subTitle={`${celebrityCount} results shown`}
            />
            <div className="mnCtDaLs mb-5">
              {allCelebrityData?.map((i, index) => (
                <div key={index}>
                  <Link to={`/celeb/${i.username}`}>
                    <CelebrityCardForGrid data={i} />
                  </Link>
                </div>
              ))}
            </div>
          </>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default ViewAll;
