import React from "react";
import "./Styles.css";
import Icons from "../../Icons";
import CelebrityDefault from "../../Assets/CelebrityDefault.jpg";

const CelebrityCard = ({ data }) => {
  const bookingAmount = data?.bookingCost?.amount;
  const celebrityName = `${data?.celebrityFirstName} ${data?.celebrityName}`;
  const imgAlt = `Photo of ${celebrityName}, click to book`;
  const currencySymbol = data?.currency === "USD" ? "$" : data?.currency;

  return (
    <div className="horizontalitem">
      <div className="horiozntalImageWrpr">
        <img
          src={data?.profilePhoto ? data?.profilePhoto : CelebrityDefault}
          alt={imgAlt}
          className="imgStyle"
        />
        {/* <div className="iconWrprLove">
          <Icons icon="love" color={"#F8F8F8"} size={18} />
        </div> */}
      </div>
      <div className="hl_cardName">{celebrityName}</div>
      <div className="hl_professionName">{data?.intro}</div>
      <div className="hl_bookingAmouns">
        <div className="amountCurrency">
          <div className="bookingCurrency">{currencySymbol}</div>{" "}
          {bookingAmount}
        </div>
        {data?.fastDelivery && (
          <>
            <div className="lineStyles1" />
            <Icons icon={"time"} color="#9CD0FA" className="common-icon-size" />
            <div className="timeHr">24hr</div>
          </>
        )}
      </div>
    </div>
  );
};

export default CelebrityCard;
