import React, { useState } from "react";
import { Container, Row, Col, Spinner, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Urls from "../../constants/ApiConstants";
import { GenericPostApiNoAuth } from "../../Services";
import { useForm } from "react-hook-form";

import "./styles.css";

const Footer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Email validation regex pattern
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    setSuccessMessage("");
    const url = Urls.JoinNewsletter;
    const body = {
      email: data.email,
    };
    // console.log(body);
    const [res, error] = await GenericPostApiNoAuth(url, body);
    if (res.code === 200) {
      setSuccessMessage(res.detail);
      reset();
    } else {
      setSuccessMessage(error.detail);
    }
    setIsLoading(false);
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        paddingTop: 72,
        paddingBottom: 72,
      }}
    >
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <div className="footer-newsletter-head">Join our newsletters</div>
            <div className="footerText">
              Be the first to know about the newest stars and best deals
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="input-group footer-input-grp">
                <Form.Control
                  placeholder="you@example.com"
                  className={
                    errors.email
                      ? "news-letter-input errorBoarder"
                      : "news-letter-input"
                  }
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                    pattern: {
                      value: emailRegex,
                      message: "Invalid email address",
                    },
                    maxLength: {
                      value: 100,
                      message: "Email is too long!",
                    },
                  })}
                />
                <div className="input-group-append">
                  <button className="news-letter-btn">
                    {isLoading ? <Spinner size="sm" /> : `Join`}
                  </button>
                </div>
              </div>
              {errors.email && (
                <div className="errorText">{errors.email.message}</div>
              )}
              {successMessage && (
                <div className="mt-2 footer-success-msg">{successMessage}</div>
              )}
            </Form>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            direction="row"
            // justifyContent="flex-end"
            // alignItems="flex-end"
          >
            <div className="flex-s-sa-an">
              <div>
                <div
                  style={{
                    fontFamily: "GillroyRegular",
                    color: "#ffffff",
                    justifyContent: "flex-end",
                  }}
                >
                  Ready to become a talent on iShout?
                </div>
                <div style={{ height: 12 }} />
                <div className="enrolBtnWrpr">
                  <Link to={"/enroll"}>
                    <div className="enrollBtn">Join as Talent</div>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ height: 48 }} />

        {/*  */}
        <Row>
          <Col md={7} sm={12}>
            <div className="footerList">
              <Link to={"/how-it-works"}>
                <div className="footer-ul-li-styles">How it works </div>
              </Link>

              <Link to="/privacy">
                <div className="footer-ul-li-styles">Privacy policy</div>
              </Link>
              <Link to={"/terms"}>
                <div className="footer-ul-li-styles">Terms of use</div>
              </Link>

              <Link to={"/contact"}>
                <div className="footer-ul-li-styles">Contact</div>
              </Link>
            </div>
          </Col>
          <Col md={5} sm={12} className="mt-4 mt-md-0">
            <div className="footerBottomTxt">
              © {new Date().getFullYear()} ishout.io | All rights reserved
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
