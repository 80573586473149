import React from "react";
import "./Styles.css";
// import { RotatingLines } from "react-loader-spinner";
import logo_gif from "../../Assets/ishout-logo-new.gif";

const SpinnerComponent = () => {
  return (
    <div className="spinnerWrprNew">
      <img src={logo_gif} alt="loading..." width="200"></img>
    </div>
  );
};

export default SpinnerComponent;
