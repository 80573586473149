import React, { createContext, useState, useEffect } from "react";
import { GenericGetApis, GenericGetApisNoTokenNoParams } from "../Services";
import * as apiUrls from "../constants/ApiConstants";

export const MyContext = createContext();

const Context = (props) => {
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [userDetail, setUserDetails] = useState(
    localStorage.getItem("userDetails") || null
  );
  const [personalOccasions, setPersonalOccasions] = useState("");
  const [businessOccasions, setBusinessOccasions] = useState("");
  const [listMainCategory, setMainCategory] = useState("");

  useEffect(() => {
    setUserDetailsFromLocalStorage();
    getOccasions();
    getMainCategory();
  }, []);

  const setUserDetailsFromLocalStorage = () => {
    const storedData = localStorage.getItem("userDetails");
    // if (storedData) {
    setUserDetails(JSON.parse(storedData));
    // }
  };

  const saveToken = (token) => {
    localStorage.setItem("token", token);
    setToken(token);
    fetchUserDetails();
  };

  const clearToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userDetails");
    setToken(null);
  };

  const fetchUserDetails = async () => {
    const user_token = token || localStorage.getItem("token");
    const url = apiUrls.UserProfileDetails;

    if (user_token) {
      const [response, error] = await GenericGetApis(url);
      if (response?.code === 200) {
        setUserDetails(response?.data);
        localStorage.setItem("userDetails", JSON.stringify(response?.data)); // Store the user details in local storage
      } else {
        // console.log("fetchUserDetails", error);
      }
    }
  };

  const getOccasions = async () => {
    const url = apiUrls.GetOccasions;
    const [res, error] = await GenericGetApisNoTokenNoParams(url);
    if (res) {
      setPersonalOccasions(res?.personalOccasions);
      setBusinessOccasions(res?.businessOccasions);
    } else {
      if (error) {
      }
      // console.log("error coming from occasion api call", error);
    }
  };

  const getMainCategory = async () => {
    const url = apiUrls.GetMainCategory;
    const [res, error] = await GenericGetApisNoTokenNoParams(url);
    // console.log("res = ", res);
    if (res) {
      setMainCategory(res?.data);
    } else {
      // console.log("error coming from main category api call", error);
      if (error) {
      }
    }
  };

  return (
    <MyContext.Provider
      value={{
        authToken: [token, setToken],
        userData: [userDetail, setUserDetails],
        personalFormOccasions: [personalOccasions, setPersonalOccasions],
        businessFormOccasions: [businessOccasions, setBusinessOccasions],
        listMainCategory: [listMainCategory, setMainCategory],
        saveToken,
        clearToken,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

export default Context;
