import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

import { GenericPostApiCall, GenericGetApis } from "../../Services";
import * as Urls from "../../constants/ApiConstants";

import "./Styles.css";

const CodeVerification = ({
  title,
  resendLink,
  otp,
  goto,
  body,
  isLoadingData,
}) => {
  //useNavigate
  const navigate = useNavigate();

  // useState
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  let [code, setCode] = useState("");

  const handleChange = (code) => {
    setCode(code);
    if (code.length === 6) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    code = parseInt(code);
    if (code) {
      if (code === otp) {
        const [res, error] = await GenericPostApiCall(goto, body);
        // console.log(res, error);
        if (res?.code === 200) {
          let resp_flag = getUserDetails();
          if (resp_flag) {
            alert("Profile successfully updated!");
            navigate("/profile");
          }
        } else {
          if (error?.detail) {
            alert(error?.detail);
          } else {
            alert(
              "Attention! we are unable to complete the request, please try again"
            );
          }
        }
      } else {
        alert("Attention! you entered an invalid code, please try again!");
      }
    } else {
      alert("Attention! please enter otp to proceed the request");
    }
    setIsLoading(false);
  };

  const getUserDetails = async () => {
    let response_flag = false;
    const profile_details_url = Urls.UserProfileDetails;
    const [response, error] = await GenericGetApis(profile_details_url);
    if (response?.code === 200) {
      // console.log("response = ", response);
      localStorage.setItem("userDetails", JSON.stringify(response?.data));
      response_flag = true;
    } else {
      // console.log("Error from Us-dt-ap-pst");
    }
    return response_flag;
  };

  return (
    <div className="otp-ver-main-wrapper w-100">
      <div className="otp-ver-main-head-wrapper mb-3">
        <div className="otp-ver-main-head">Code verification</div>
      </div>
      <div className="otp-ver-label-wrapper">
        <div className="otp-ver-label">
          Enter the verification code we sent to {title}
        </div>
      </div>
      <div className="w-100 d-flex justify-content-evenly my-3">
        <OtpInput
          value={code}
          onChange={handleChange}
          numInputs={6}
          renderSeparator={<span style={{ width: "8px" }}></span>}
          renderInput={(props) => <input {...props} />}
          isInputNum={true}
          shouldAutoFocus={true}
          inputStyle={{
            border: "1px solid #565656",
            borderRadius: "8px",
            minWidth: "30px",
            minHeight: "40px",
            maxWidth: "65px",
            maxHeight: "55px",
            width: "100%",
            height: "100%",
            fontSize: "15px",
            color: "#fff",
            fontWeight: "400",
            background: "#1A1A1A",
          }}
        />
      </div>
      <div className="mb-2">
        {isValid ? (
          <button
            className="otp-ver-submit-btn-area w-100"
            onClick={handleSubmit}
            type="button"
          >
            <div className="otp-ver-btn-label">
              {!isLoading ? "Submit" : <Spinner size="sm"></Spinner>}
            </div>
          </button>
        ) : (
          <button
            className="otp-ver-submit-btn-area w-100 bg-secondary"
            onClick={handleSubmit}
            type="button"
            disabled
          >
            <div className="otp-ver-btn-label">
              {!isLoading ? "Submit" : <Spinner size="sm"></Spinner>}
            </div>
          </button>
        )}
      </div>
      <div className="mt-3">
        <div
          type="button"
          onClick={resendLink}
          className="btn-link otp-ver-resend-link"
        >
          {!isLoadingData ? (
            "Resend confirmation code"
          ) : (
            <Spinner size="sm"></Spinner>
          )}
        </div>
      </div>
    </div>
  );
};

export default CodeVerification;
