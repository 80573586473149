import React from "react";
import iconSet from "./selection.json";
import IcomoonReact, { iconList } from "icomoon-react";

const Icons = ({ color, size, icon, className }) => {
  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
      className={className}
    />
  );
};

export default Icons;
