import React, { useRef } from "react";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

import { Footer, Navbar } from "../../Component";

import OneStep from "../../Assets/1.svg";
import TwoStep from "../../Assets/2.svg";
import ThreeStep from "../../Assets/3.svg";
import FourStep from "../../Assets/4.svg";

import firstStepImage from "../../Assets/first-img.svg";
import secondsteps from "../../Assets/second-img.svg";
import thirdsteps from "../../Assets/third-img.svg";
import stepsfour from "../../Assets/stepsfour.png";
import scroller from "../../Assets/scroll.png";

// import bannerImage from "../../Assets/banner.svg";
// import bannerDeskImage from "../../Assets/banner-desk.png";
import newBanner from "../../Assets/new-banner.png";
import newBannerMobile from "../../Assets/new-banner-mobile.png";

import colorHeading from "../../Assets/color-heading.png";

// import downloadImg from "../../Assets/downloadImg.png";
// import appstore from "../../Assets/appstore.svg";
// import playstore from "../../Assets/playstore.svg";

import step2Abs from "../../Assets/step2Abs.png";
import step3Abs from "../../Assets/step3Abs.png";
import step4Abs from "../../Assets/step4Abs.png";

import "./styles.css";

const HowItWorks = () => {
  // const openAppStoreTab = () => {
  //   const url = "https://apps.apple.com/in/app/ishout/id1615766354";
  //   window.open(url, "_blank");
  // };
  // const openPlayStoreTab = () => {
  //   const url = "https://play.google.com/store/apps/details?id=com.ishout";
  //   window.open(url, "_blank");
  // };

  const ref = useRef(null);

  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="hiw-container">
      <Navbar />
      <Container fluid>
        <div
          className="banner-sections mt-4"
          style={{ background: "#272727B2", borderRadius: 24 }}
        >
          <Row>
            <Col
              className="d-sm-none d-md-none d-lg-none d-xl-none"
              xl={7}
              lg={7}
              md={7}
              sm={7}
            >
              <img
                src={newBannerMobile}
                className="w-100 h-auto"
                alt="ishout"
              ></img>
            </Col>
            <Col xl={5} lg={5} md={5} sm={5}>
              <div className="ali-i-c-j-home-new">
                <div className="b-h-stl">Welcome to iShout!</div>
                <div className="ba-par-style">
                  A platform where you can connect with your favorite stars
                </div>
                <div className="mt-md-4 mt-lg-5 mt-sm-1 mt-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-sm-none">
                      <div className="enrolBtnWrpr">
                        <Link to={"/enroll"}>
                          <div className="enrollBtn">Join as Talent</div>
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      <div
                        onClick={handleScroll}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={scroller}
                          alt="scroller"
                          className="scroller-img"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              className="d-none d-sm-block d-md-block d-lg-block d-xl-block"
              xl={7}
              lg={7}
              md={7}
              sm={7}
            >
              <img src={newBanner} className="w-100 h-auto" alt="ishout"></img>
            </Col>
          </Row>
        </div>
      </Container>
      <Container>
        <div className="details-section">
          <Row className="justify-content-center">
            {/* See how to use iShout in a few simple steps */}
            <Col ref={ref} lg={8} md={9} className="mb-4">
              {/* <h1 className="-an-d-s-head">
                See how to use iShout in a few simple steps
              </h1> */}
              <img
                src={colorHeading}
                className="w-100"
                alt="See how to use iShout in a few simple steps"
              ></img>
            </Col>

            {/* Step 1 */}
            <Col lg={12} md={12} className="my-5 my-lg-3 my-md-3">
              <Row>
                <Col lg={6} md={6} className="align-self-center">
                  <Row>
                    <Col lg={4} md={4} sm={3} className="mb-3">
                      <img
                        src={OneStep}
                        className="how-it-works-number-steps"
                        alt="ishout-booking-steps"
                        srcSet=""
                      />
                    </Col>
                    <Col lg={8} md={8} sm={9} className="mb-3">
                      <div className="step-data">
                        <h2 className="h2-head">Find your favorite star</h2>
                        <p className="details-para">
                          The perfect selection of celebrities for the events
                          like birthdays, weddings, engagements, inaugurations,
                          etc. Find the ideal one and request them for a
                          personalized video and enjoy the video!
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} className="mb-3">
                  <img src={firstStepImage} className="w-100" alt="ishout" />
                </Col>
              </Row>
            </Col>

            {/* Step 2 */}
            <Col lg={12} md={12} className="my-5 my-lg-3 my-md-3">
              <div className="first-relative">
                <Row>
                  <Col lg={6} md={6} className="d-none d-lg-block d-md-block">
                    <img src={secondsteps} className="w-100" alt="ishout" />
                  </Col>
                  <Col lg={6} md={6} className="align-self-center">
                    <Row>
                      <Col lg={4} md={4} sm={3} className="mb-3">
                        <img
                          src={TwoStep}
                          className="how-it-works-number-steps"
                          alt="ishout-booking-steps"
                          srcSet=""
                        />
                      </Col>
                      <Col lg={8} md={8} sm={9} className="mb-3">
                        <div className="step-data">
                          <h2 className="h2-head">Tell them what to say</h2>
                          <p className="details-para">
                            Fill out the requisition form carefully, include all
                            details regarding the occasion for easy catchup.
                            Celebrity will complete your video request within 7
                            days . And we help to make the unique video gift to
                            enjoy your most memorable occasion.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6} md={6} className="d-sm-block d-md-none">
                    <img src={secondsteps} className="w-100" alt="ishout" />
                  </Col>
                </Row>
                <div className="second-step-absolute-wrpr">
                  <img
                    src={step2Abs}
                    className="w-100"
                    alt="ishout-booking-steps"
                  />
                </div>
              </div>
            </Col>

            {/* Step 3 */}
            <Col lg={12} md={12} className="my-5 my-lg-3 my-md-3">
              <div className="first-relative">
                <Row>
                  <Col lg={6} md={6} className="align-self-center">
                    <Row>
                      <Col lg={4} md={4} sm={3} className="mb-3">
                        <img
                          src={ThreeStep}
                          className="how-it-works-number-steps"
                          alt="ishout-booking-steps"
                          srcSet=""
                        />
                      </Col>
                      <Col lg={8} md={8} sm={9} className="mb-3">
                        <div className="step-data">
                          <h2 className="h2-head">Get your video</h2>
                          <p className="details-para">
                            Complete the payment and eagerly wait for your
                            personalized video for your beloved occasions, video
                            will be delivered within a week.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6} md={6} className="mb-3">
                    <img src={thirdsteps} className="w-100" alt="ishout" />
                  </Col>
                </Row>
                <div className="second-step-absolute-wrpr">
                  <img
                    src={step3Abs}
                    className="w-100"
                    alt="ishout-booking-steps"
                  />
                </div>
              </div>
            </Col>

            {/* Step 4 */}
            <Col lg={12} md={12}>
              <div className="first-relative">
                <Row>
                  <Col lg={6} md={6} className="d-none d-md-block d-lg-block">
                    <img src={stepsfour} className="w-100" alt="ishout" />
                  </Col>
                  <Col lg={6} md={6} className="align-self-center">
                    <Row>
                      <Col lg={4} md={4} sm={3} className="mb-3">
                        <img
                          src={FourStep}
                          className="how-it-works-number-steps"
                          alt="ishout-booking-steps"
                        />
                      </Col>
                      <Col lg={12} md={12} sm={12} className="mb-3">
                        <div className="step-data">
                          <h2 className="last-step-head">
                            Hooray!!..
                            <br></br>You are done
                            <br></br> Wait for your video
                          </h2>
                          <p className="details-para">
                            Share the video with your friends and family!
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6} md={6} className="d-sm-block d-md-none d-lg-none">
                    <img src={stepsfour} className="w-100" alt="ishout" />
                  </Col>
                </Row>
                <div className="second-step-absolute-wrpr">
                  <img
                    src={step4Abs}
                    className="w-100"
                    alt="ishout-booking-steps"
                  />
                </div>
              </div>
            </Col>
          </Row>

          {/* <div className="d-s-inner-container">
            <Row>
              <Col lg={6} className="align-self-center">
                <h1 className="dowload-heads ddparas">Download the app now</h1>
                <p className="download-para">
                  The most memorable moments of your life to be shared. The
                  iShout App will experience you with the best and most classy
                  videos from your favorite stars.
                </p>
                <div className="baner-btn-wrpr custom-margin">
                  <button className="appstoreBtn" onClick={openAppStoreTab}>
                    <div className="play-icon-wrpr-app">
                      <img src={appstore} alt="ishout-ios-app" />
                    </div>
                    <div>
                      <div className="get-it">GET IT ON</div>
                      <div className="google-play-text">App Store</div>
                    </div>
                  </button>
                  <button className="playstoreBtn" onClick={openPlayStoreTab}>
                    <div className="play-icon-wrpr-app">
                      <img src={playstore} alt="ishout-ios-app" />
                    </div>
                    <div>
                      <div className="get-it">GET IT ON</div>
                      <div className="google-play-text">Google Play</div>
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={1} />
              <Col lg={4}>
                <div className="bottomPhns">
                  <img src={downloadImg} className="w-100" alt="iShout" />
                </div>
              </Col>
              <Col lg={1} />
            </Row>
          </div> */}

          <div className="faq-client-wrapper" id="faq">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className="faq-header">Frequently asked questions</div>
                <div className="faq-desc">
                  Have questions? we're here to help
                </div>
              </Col>

              <Col xl={9} lg={10} md={12} className="mx-auto mt-5 mb-3">
                <div className="justify-content-center">
                  <Accordion flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        What is iShout, and how does it work?
                      </Accordion.Header>
                      <Accordion.Body>
                        iShout is a platform that connects users with their
                        favorite celebrities to request personalized videos for
                        special occasions. It works by allowing users to browse
                        a diverse selection of celebrities, choose their
                        preferred star, and request a personalized video message
                        for events like birthdays, weddings, engagements, and
                        more. Users can specify their customization preferences
                        and enjoy a unique video created just for their
                        occasion.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        How do I find my favorite celebrity for a personalized
                        video?
                      </Accordion.Header>
                      <Accordion.Body>
                        Finding your favorite celebrity is easy on iShout.
                        Simply browse our extensive list of celebrities, filter
                        by category or search by name, and select the star you
                        admire. Once you've found them, you can proceed to
                        request a personalized video.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        What can I ask the celebrity to include in the
                        personalized video?
                      </Accordion.Header>
                      <Accordion.Body>
                        You have the creative freedom to request specific
                        messages or themes for your personalized video. Share
                        details about the occasion, the recipient, and any
                        special instructions or messages you'd like the
                        celebrity to convey. This way, you can make the video
                        truly meaningful and tailored to your needs.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        How long does it take for the celebrity to complete my
                        video request?
                      </Accordion.Header>
                      <Accordion.Body>
                        Celebrities on iShout are committed to delivering your
                        personalized video within 7 days from the date of your
                        request. This ensures that you receive your video in a
                        timely manner to enjoy your memorable occasion.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        What information should I include in the requisition
                        form for my video request?
                      </Accordion.Header>
                      <Accordion.Body>
                        When filling out the requisition form, be sure to
                        include all the relevant details about your occasion.
                        Provide information about the event, the recipient's
                        name, any specific messages you want the celebrity to
                        include, and any other pertinent information that will
                        help the celebrity create a meaningful video.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Can I share my personalized video with friends and
                        family?
                      </Accordion.Header>
                      <Accordion.Body>
                        Absolutely! Once you've received your personalized
                        video, you can easily share it with your friends and
                        family. You can share the video through our platform or
                        download it to share on other social media platforms,
                        email, or messaging apps, allowing you to spread the joy
                        of your special occasion with loved ones.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        How fast is the delivery of personalized videos on
                        iShout?
                      </Accordion.Header>
                      <Accordion.Body>
                        We take pride in providing fast delivery of your
                        personalized videos. In most cases, you can expect to
                        receive your video within mention the expected delivery
                        time frame, e.g., 24 hours from the date of your
                        request. Our goal is to ensure you can enjoy your
                        special occasion without delay.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default HowItWorks;
