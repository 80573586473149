import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { IoArrowBack } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, Link } from "react-router-dom";

import { MyContext } from "../../Store";
import { Footer, Navbar } from "../../Component";
import Icons from "../../Icons";
import * as apiUrls from "../../constants/ApiConstants";
import { GenericPostApiCall } from "../../Services";

import "./Styles.css";

const pronounList = [
  { id: 1, name: "He/Him" },
  { id: 2, name: "She/Her" },
  { id: 3, name: "They/Them" },
];

const Book = () => {
  // useNavigate
  const navigate = useNavigate();

  // useLocation
  const location = useLocation();
  const { orderData } = location.state;

  const {
    celebrity,
    celebrityContentLanguages,
    celebrityFirstName,
    celebrityPhoto,
    contentLanguage,
    friendName,
    instruction,
    introduction,
    occasion,
    order_id,
    pronoun,
    shoutOutFor,
    shoutOutTo,
    isOrderIsPublic,
    status,
  } = orderData;

  const celebrityName = `${celebrityFirstName} ${celebrity}`;

  const [isMySelfVideo, setMySelfVideo] = useState(
    shoutOutFor === 0 ? true : false
  );
  const [selectedOccasion, setSelectedOccasion] = useState(
    occasion ? occasion.id : ""
  );
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedPronoun, setSelectedPronoun] = useState(
    pronoun ? pronoun : ""
  );
  const [isPrivateChecked, setIsPrivateChecked] = useState(!isOrderIsPublic);
  const [instructionPlaceHolder, setPlaceHolder] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [instructionsCharLength, setInstructionsCharLength] = useState(
    instruction.length
  );
  const [instructionsColor, setInstructionsColor] = useState("#e1e1e1");
  const [validateLoader, setValidateLoader] = useState(false);

  let submitBtnTitle = "Edit personalized video order";
  let submitBtnDisabled = false;
  if (status === "Accept") {
    submitBtnTitle = `${celebrityName} has already accepted your video request! You cannot edit`;
    submitBtnDisabled = true;
  }

  // useContext
  const { personalFormOccasions } = useContext(MyContext);
  const [personalOccasions] = personalFormOccasions;

  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      shoutOutTo: shoutOutTo,
      friendName: friendName,
      IntroduceYourself: introduction,
      Instructions: instruction,
      pronoun: pronoun,
      language: selectedLanguage ? selectedLanguage : contentLanguage.id,
    },
  });

  const handlePlaceHolder = (i) => {
    switch (i) {
      case "Birthday":
        setPlaceHolder(
          "I've been a huge fan for years and wanted to get this video as a b-day present to my wife! We're spending the day going to Atlantis Aquaventure with friends and we're all going to cook out. Any birthday wish from you would be awesome!"
        );
        break;

      case "Anniversary":
        setPlaceHolder(
          "Another milestone in my friend's marriage is a moment of joy and gratitude. Your thoughtful anniversary wishes would add a touch of glamour to their celebration, and they would cherish them forever. Your presence in spirit would be an unforgettable gift. Thank you for considering this request!"
        );
        break;

      case "Ask a question":
        setPlaceHolder(
          "I've been a huge fan for years and wanted to get this video as to ask a question to my kids"
        );
        break;

      case "Graduation":
        setPlaceHolder(
          "My sister prepares to step into a new chapter of life, your encouraging words on her graduation day would be a cherished memory. Your influence has been happy, and we humbly request your inspiring wishes to mark this milestone. Thank you for being a source of motivation!"
        );
        break;

      case "Get advice":
        setPlaceHolder(
          "Your wisdom and insights have always been an inspiration to my daughter. She would be incredibly grateful if you could spare a moment to offer some advice or answer a burning question that she has. Your guidance would mean the world. Thank you for considering request!"
        );
        break;

      default:
        setPlaceHolder(
          "I've been a huge fan for years and wanted to get this video as a b-day present to my wife! We're spending the day going to Atlantis Aquaventure with friends and we're all going to cook out. Any birthday wish from you would be awesome!"
        );
        break;
    }
  };

  // useEffect
  useEffect(() => {
    if (celebrityContentLanguages.length === 1) {
      setSelectedLanguage(celebrityContentLanguages[0].id);
    }
  }, []);

  //
  const onSubmit = async (data) => {
    setIsLoading(true);
    const url = `${apiUrls.UpdateOrder}?key=${order_id}`;

    const body = {
      shoutOutFor: isMySelfVideo ? "mine" : "friend",
      instruction: data?.Instructions,
      shoutOutTo: data?.shoutOutTo,
      friendName: data?.friendName ? data?.friendName : "",
      introduction: data?.IntroduceYourself,
      pronoun: selectedPronoun,
      occasion: selectedOccasion,
      contentLanguage: data?.language ? data?.language : selectedLanguage,
      isOrderPublic: isPrivateChecked === false ? 1 : 0,
    };

    const [res, error] = await GenericPostApiCall(url, body);
    if (res?.code === 200) {
      navigate(-1);
    } else {
      // console.log(
      //   "error happened at order edit api (personal), details = ",
      //   error
      // );
      alert("Attention! Unable to complete the request, please try again");
    }
    setIsLoading(false);
  };

  const handleCharLength = (event) => {
    setInstructionsCharLength(event.target.value.length);
    if (event.target.value.length > 250) {
      setInstructionsColor("#df220d");
    } else {
      setInstructionsColor("#e1e1e1");
    }
  };

  const validateInstructions = async (value) => {
    let returnFlag = true;
    setValidateLoader(true);
    const [response, error] = await GenericPostApiCall(
      apiUrls.ValidateErrorCheck,
      {
        inputText: value,
      }
    );
    if (!response.valid) {
      const detectedWords = response?.detected_data
        .map((item) => item)
        .join(", ");
      returnFlag = `Reminder of Community Guidelines! Please do not type any naughty/obscene words! (Found ${detectedWords})`;
    } else {
      clearErrors("Instructions.validate");
      returnFlag = true;
    }
    if (error) {
      // console.log("error in gt-o-bdwrds-api");
      clearErrors("Instructions.validate");
      returnFlag = true;
    }
    setValidateLoader(false);
    return returnFlag;
  };

  return (
    <div className="bookingContainer">
      <Navbar />
      <Container>
        <div className="mainWrpr-pbf">
          <Row className="mb-3 justify-content-center">
            <Col lg={7} md={8} sm={10} xs={12}>
              <div className="d-flex arrowBack">
                <Link to={`/orders`}>
                  <IoArrowBack style={{ color: "#FFF" }}></IoArrowBack>
                  <span
                    className="text-white"
                    style={{
                      paddingLeft: "5px",
                    }}
                  >
                    Orders
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={7} md={8} sm={10} xs={12}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="cardWrpr mb-4">
                  <div className="c-f-s-wrprs">
                    <div className="c-img-wrpr">
                      {/* add a person avatar here if no profile image is present */}
                      <img
                        src={celebrityPhoto ? celebrityPhoto : ""}
                        alt="man"
                        className="w-100"
                      />
                    </div>
                    <div className="whosName">Edit Order #{order_id}</div>
                  </div>
                  <div className="lineStyles" />
                  <div className="pfa-s-s-wrpr">
                    <div className="pfa-a-heading">Who's this video for?</div>
                    <div className="pfs-ss-b-row">
                      <div className="f-i-wrpr-all">
                        <button
                          type="button"
                          className="-an-w-t-f-btns"
                          onClick={() => setMySelfVideo(true)}
                        >
                          <div
                            className={
                              isMySelfVideo
                                ? "pfa-f-i-s-img active"
                                : "pfa-f-i-s-img"
                            }
                          >
                            <Icons icon={"user"} color="#ffffff" size={28} />
                          </div>
                          <div className="forme-wrpr-row">
                            <div
                              className={
                                isMySelfVideo
                                  ? "for-me-name active"
                                  : "for-me-name"
                              }
                            >
                              Myself
                            </div>
                          </div>
                        </button>
                      </div>
                      <div className="f-i-wrpr-all">
                        <button
                          type="button"
                          className="-an-w-t-f-btns"
                          onClick={() => setMySelfVideo(false)}
                        >
                          <div
                            className={
                              isMySelfVideo === false
                                ? "pfa-f-i-s-img active"
                                : "pfa-f-i-s-img"
                            }
                          >
                            <Icons icon={"gift"} color="#ffffff" size={28} />
                          </div>
                          <div className="forme-wrpr-row">
                            <div
                              className={
                                isMySelfVideo === false
                                  ? "for-me-name active"
                                  : "for-me-name"
                              }
                            >
                              Someone else
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>

                    <div className="sepinput-size1"></div>
                    <div className="pfa-a-heading1">
                      To ( who should be surprised )
                    </div>
                    <Form.Control
                      placeholder="Enter the name"
                      className={
                        errors.shoutOutTo
                          ? "custom-pfa-class-style errorBoarder"
                          : "custom-pfa-class-style"
                      }
                      {...register("shoutOutTo", {
                        required: {
                          value: true,
                          message: "Required",
                        },
                        maxLength: {
                          value: 100,
                          message: "Text length is limited to 100 characters",
                        },
                      })}
                    />
                    {errors.shoutOutTo && (
                      <div className="errorText">
                        {errors.shoutOutTo.message}
                      </div>
                    )}

                    <div className="chips-wrpr mt-3">
                      {pronounList &&
                        pronounList?.map((i, index) => (
                          <button
                            key={index}
                            type="button"
                            className={
                              selectedPronoun === i.name
                                ? "chip-names active"
                                : "chip-names"
                            }
                            onClick={() => {
                              setSelectedPronoun(i.name);
                            }}
                          >
                            <div className="names-styles">{i.name}</div>
                          </button>
                        ))}
                    </div>

                    {!isMySelfVideo ? (
                      <>
                        <div className="sepinput-size1"></div>
                        <div className="pfa-a-heading1">
                          From ( who's this video from )
                        </div>
                        <Form.Control
                          placeholder="Enter the name"
                          className={
                            errors.friendName
                              ? "custom-pfa-class-style errorBoarder"
                              : "custom-pfa-class-style"
                          }
                          {...register("friendName", {
                            required: {
                              value: true,
                              message: "Required",
                            },
                            maxLength: {
                              value: 100,
                              message:
                                "Text length is limited to 100 characters",
                            },
                          })}
                        />
                        {errors.friendName && (
                          <div className="errorText">
                            {errors.friendName.message}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="sepinput-size1"></div>
                        <div className="pfa-a-heading1">Introduce yourself</div>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          className={
                            errors.IntroduceYourself
                              ? "custom-pfa-class-style-multi errorBoarder"
                              : "custom-pfa-class-style-multi"
                          }
                          placeholder="Introduce yourself"
                          {...register("IntroduceYourself", {
                            required: false,
                            maxLength: {
                              value: 200,
                              message:
                                "Text length is limited to 200 characters",
                            },
                          })}
                        />
                        {errors.IntroduceYourself && (
                          <div className="errorText">
                            {errors.IntroduceYourself.message}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* ------------ */}
                <div className="cardWrpr mb-4">
                  <div className="pfa-a-heading1">What's the occasion?</div>
                  <div className="chips-wrpr">
                    {personalOccasions &&
                      personalOccasions?.map((i, index) => (
                        <button
                          key={index}
                          type="button"
                          className={
                            selectedOccasion === i.id
                              ? "chip-names active"
                              : "chip-names"
                          }
                          onClick={() => {
                            setSelectedOccasion(i.id);
                            handlePlaceHolder(i.name);
                          }}
                        >
                          <div className="names-styles">{i?.name}</div>
                        </button>
                      ))}
                  </div>
                </div>
                {/* ---------- */}
                <div className="cardWrpr">
                  <div className="pfa-a-heading1">
                    Instructions (required){" "}
                    {validateLoader ? <Spinner size="sm" /> : ``}
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    className={
                      errors.Instructions
                        ? "custom-pfa-class-style-multi errorBoarder"
                        : "custom-pfa-class-style-multi"
                    }
                    placeholder={
                      instructionPlaceHolder
                        ? instructionPlaceHolder
                        : "I've been a huge fan for years and wanted to get this video as a b-day present to my wife! We're spending the day going to Atlantis Aquaventure with friends and we're all going to cook out. Any birthday wish from you would be awesome!"
                    }
                    onInput={handleCharLength}
                    {...register("Instructions", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                      maxLength: {
                        value: 250,
                        message: "Text length is limited to 250 characters",
                      },
                      validate: validateInstructions,
                    })}
                  />
                  <div className="position-relative mb-3">
                    <div className="position-absolute start-0 end-30">
                      {errors.Instructions && (
                        <div className="bbInsND">
                          {errors.Instructions.message}
                        </div>
                      )}
                    </div>
                    <div
                      className="position-absolute end-0 bbInsND"
                      style={{ color: instructionsColor }}
                    >
                      {instructionsCharLength}/250 characters
                    </div>
                  </div>

                  {celebrityContentLanguages.length > 1 && (
                    <>
                      <div style={{ height: "36px" }} />
                      <div className="pfa-a-heading1">
                        Video language (required)
                      </div>
                      <Form.Select
                        aria-label="Default select example"
                        className={
                          errors.language
                            ? "form-select-custm-class errorBoarder"
                            : "form-select-custm-class"
                        }
                        {...register("language", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                        })}
                      >
                        <optgroup label="Select preferred language from the list">
                          {celebrityContentLanguages?.map((i, index) => (
                            <option value={i.id} key={index}>
                              {i.name}
                            </option>
                          ))}
                        </optgroup>
                      </Form.Select>
                      {errors.language && (
                        <div className="errorText">
                          {errors.language.message}
                        </div>
                      )}
                    </>
                  )}
                  <div className="btn-pfa-wrpr">
                    <div className="c-b-wrpr-s mb-3">
                      <Form.Check
                        type={"checkbox"}
                        className="cehckbox-custom"
                        checked={isPrivateChecked}
                        name="isPrivate"
                        onChange={(e) => {
                          setIsPrivateChecked((current) => !current);
                        }}
                      />
                      <div className="hide-wrpr">
                        Hide this video from {celebrityName} profile
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className={
                          status === "Accept"
                            ? "continue-btns1 submitBtnStyle"
                            : "continue-btns1"
                        }
                        disabled={submitBtnDisabled}
                        title={submitBtnTitle}
                      >
                        {isLoading ? <Spinner size="sm" /> : `Submit`}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Book;
