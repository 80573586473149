import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CelebrityCard, Footer, Navbar, PageHeading } from "../../Component";
import "./Styles.css";

const Favorite = () => {
  return (
    <div className="favContainer">
      <Navbar />
      <Container>
        <PageHeading title="Favorite list" />
        <div className="pageWrpr">
          <Row>
            <Col lg={2} className="mb-5">
              <CelebrityCard />
            </Col>
            <Col lg={2} className="mb-5">
              <CelebrityCard />
            </Col>
            <Col lg={2} className="mb-5">
              <CelebrityCard />
            </Col>
            <Col lg={2} className="mb-5">
              <CelebrityCard />
            </Col>
            <Col lg={2} className="mb-5">
              <CelebrityCard />
            </Col>
            <Col lg={2} className="mb-5">
              <CelebrityCard />
            </Col>
            <Col lg={2} className="mb-5">
              <CelebrityCard />
            </Col>
            <Col lg={2} className="mb-5">
              <CelebrityCard />
            </Col>
            <Col lg={2} className="mb-5">
              <CelebrityCard />
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Favorite;
