import axios from "axios";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Tabs,
  Tab,
  Spinner,
  // Toast,
  // TabContainer,
} from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";

import { GenericPostApiNoAuth } from "../../Services";
import { Footer, Navbar } from "../../Component";
import * as apiUrls from "../../constants/ApiConstants";

import "./styles.css";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const passwordRegex =
    /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  // useState
  const [isLoading, setLoading] = useState(false);
  const [isOtpEmailFieldVisible, setEmailOtpFieldVisible] = useState(false);
  const [isEmailPasswordVisible, setEmailPasswordVisible] = useState(false);

  const [isOtpMobileVisible, setOtpMobileVisible] = useState(false);
  const [isMobilePasswordVisible, setMobilePasswordVisible] = useState(false);

  const [emailOtpFromResponse, setEmailOtpFromResponse] = useState("");
  const [mobileOtpFromResponse, setMobileOtpFromResponse] = useState("");

  const [token, setToken] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneCodeCountryCode, setPhoneNumberCountryCode] = useState("");
  const [defaultCountryCode, setDefaultCountry] = useState("");
  const [isFoundDefaultCountry, setDefaultCountryFlag] = useState(false);

  const [activeTab, setActiveTab] = useState("Email");

  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    // clearErrors,
    control,
  } = useForm();

  // useEffect
  useEffect(() => {
    getGeoInfo();
  }, []);

  const handleChange = (phoneNumber) => {
    if (phoneNumber?.length > 1) {
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

      if (parsedPhoneNumber) {
        const phoneNumber = parsedPhoneNumber.nationalNumber;
        const countryPhoneCode = parsedPhoneNumber.countryCallingCode;
        setPhoneCode(phoneNumber);
        setPhoneNumberCountryCode(countryPhoneCode);
      }
    }
  };

  const typedPassword = useWatch({ control, name: "newPassword" });

  const onSubmit = async (data) => {
    if (!emailOtpFromResponse && !data?.newPassword) {
      setLoading(true);
      const body = {
        data: data.email,
        dataType: "email",
      };
      const url = apiUrls.ResetPassword;
      const [response, error] = await GenericPostApiNoAuth(url, body);
      if (response) {
        setEmailOtpFromResponse(response?.otp);
        setToken(response?.token);
        setEmailOtpFieldVisible(true);
      } else {
        setEmailOtpFromResponse("");
        setToken("");
        setEmailOtpFieldVisible(false);
        alert(
          error?.detail
            ? error?.detail
            : "Something went wrong, please try again later"
        );
      }
      setLoading(false);
    } else if (data?.userEmailOtp && !data?.newPassword) {
      setLoading(true);
      if ("" + emailOtpFromResponse === "" + data?.userEmailOtp) {
        setEmailPasswordVisible(true);
      } else {
        setError("userEmailOtp", {
          type: "required",
          message: "Invalid OTP",
        });
        setEmailPasswordVisible(false);
      }
      setLoading(false);
    } else if (data?.newPassword) {
      setLoading(true);
      const url = apiUrls.setNewPassword;
      const body = {
        password: data.newPassword,
      };
      await axios({
        method: "POST",
        url: url,
        headers: {
          Authorization: `token ${token}`,
          "Accept-Language": "en",
        },
        data: body,
      }).then((response) => {
        if (response.data.code === 200) {
          alert("Your password has been changed successfully.");
          navigate("/login");
        }
      });
      setLoading(false);
    } else {
      // console.log("check forgot password");
      alert("Attention! unable to process the request, please try again later");
    }
  };

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const onPhoneSubmit = async (data) => {
    // console.log(data);
    if (!mobileOtpFromResponse && data?.phoneInputWithCountrySelect) {
      setLoading(true);
      const body = {
        data: data.phoneInputWithCountrySelect,
        dataType: "mobile",
      };
      const url = apiUrls.ResetPassword;
      const [response, error] = await GenericPostApiNoAuth(url, body);
      if (response) {
        setMobileOtpFromResponse(response?.otp);
        setToken(response?.token);
        setOtpMobileVisible(true);
      } else {
        alert(
          error?.detail
            ? error?.detail
            : "Something went wrong, please try again later"
        );
        setMobileOtpFromResponse("");
        setToken("");
        setOtpMobileVisible(false);
      }
      setLoading(false);
    } else if (data?.userOtp && !data?.newPassword) {
      setLoading(true);
      if ("" + mobileOtpFromResponse === "" + data?.userOtp) {
        setMobilePasswordVisible(true);
      } else {
        setError("userOtp", {
          type: "required",
          message: "Invalid OTP",
        });
      }
      setLoading(false);
    } else if (data?.newPassword) {
      setLoading(true);
      const url = apiUrls.setNewPassword;
      const body = {
        password: data.newPassword,
      };
      await axios({
        method: "POST",
        url: url,
        headers: {
          Authorization: `token ${token}`,
          "Accept-Language": "en",
        },
        data: body,
      }).then((response) => {
        if (response.data.code === 200) {
          alert("Your password has been changed successfully.");
          navigate("/login");
        }
      });

      setLoading(false);
    } else {
      // console.log("check forgot password");
      alert("Attention! unable to process the request, please try again later");
    }
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        if (data) {
          setDefaultCountryFlag(true);
          setDefaultCountry({
            countryName: data.country_name,
            countryCallingCode: data.country_calling_code,
            countryCode: data.country_code,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      <Navbar />
      <Container>
        {/* <TabContainer position="top-end">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={10000}
            autohide
            bg="success"
          >
            <Toast.Body>
              Your password has been changed successfully.
            </Toast.Body>
          </Toast>
        </TabContainer> */}
        <div className="forgotpasswordContainer">
          <Row className="justify-content-center">
            <Col lg={6} md={7} sm={10}>
              <div className="f-p-all-wrpr">
                <div className="login_head1">Forgot Password</div>
                <Tabs
                  defaultActiveKey="Email"
                  id="uncontrolled-tab-example"
                  className="my-custom-tab-styles"
                  fill
                  onSelect={handleTabSelect}
                >
                  <Tab eventKey="Email" title="Email">
                    {activeTab === "Email" && (
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <>
                          <div style={{ height: 48 }} />
                          <div className="pfa-a-heading1">Email</div>
                          <Form.Control
                            placeholder="Enter your email address"
                            type="email"
                            className={
                              errors.email
                                ? "custom-pfa-class-style error"
                                : "custom-pfa-class-style"
                            }
                            {...register("email", {
                              required: "required",
                              pattern: {
                                value: emailRegex,
                                message: "Invalid email address",
                              },
                            })}
                          />
                          {errors.email && (
                            <div className="error-message errorText">
                              {errors.email.message}
                            </div>
                          )}

                          {isOtpEmailFieldVisible && (
                            <>
                              <div style={{ height: 20 }} />
                              <div className="pfa-a-heading1">OTP</div>
                              <Form.Control
                                disabled={isEmailPasswordVisible}
                                placeholder="Enter your OTP"
                                className={
                                  errors.userEmailOtp
                                    ? "custom-pfa-class-style error"
                                    : "custom-pfa-class-style"
                                }
                                {...register("userEmailOtp", {
                                  required: {
                                    value: true,
                                    message: "Required",
                                  },
                                  minLength: {
                                    value: 6,
                                    message: "Verification code is 6 digits",
                                  },
                                  maxLength: {
                                    value: 6,
                                    message: "Verification code is 6 digits",
                                  },
                                })}
                              />
                              {errors.userEmailOtp && (
                                <div className="error-message errorText">
                                  {errors.userEmailOtp.message}
                                </div>
                              )}
                            </>
                          )}

                          {isEmailPasswordVisible && (
                            <>
                              <div style={{ height: 20 }} />
                              <div className="pfa-a-heading1">New Password</div>
                              <Form.Control
                                type="password"
                                autocomplete="on"
                                placeholder="Enter your new password"
                                className={
                                  errors.newPassword
                                    ? "custom-pfa-class-style error"
                                    : "custom-pfa-class-style"
                                }
                                {...register("newPassword", {
                                  required: "required",
                                  pattern: {
                                    value: passwordRegex,
                                    message:
                                      "Should contains a letter, digit and a special character",
                                  },
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Password length is between 8 to 16 chars",
                                  },
                                  maxLength: {
                                    value: 16,
                                    message:
                                      "Password length is between 8 to 16 chars",
                                  },
                                })}
                              />
                              {errors.newPassword && (
                                <div className="error-message errorText">
                                  {errors.newPassword.message}
                                </div>
                              )}

                              <div style={{ height: 20 }} />
                              <div className="pfa-a-heading1">
                                Confirm New Password
                              </div>
                              <Form.Control
                                type="password"
                                autoComplete="on"
                                placeholder="Confirm your new password"
                                className={
                                  errors.confirmNewPassword
                                    ? "custom-pfa-class-style error"
                                    : "custom-pfa-class-style"
                                }
                                {...register("confirmNewPassword", {
                                  required: "required",
                                  validate: (value) =>
                                    value === typedPassword ||
                                    "Passwords do not match",
                                })}
                              />
                              {errors.confirmNewPassword && (
                                <div className="error-message errorText">
                                  {errors.confirmNewPassword.message}
                                </div>
                              )}
                            </>
                          )}

                          <button
                            disabled={isLoading}
                            className={
                              isLoading ? "log-in-btsss2" : "log-in-btsss1"
                            }
                            type="submit"
                          >
                            {isLoading ? <Spinner size="sm" /> : "Submit"}
                          </button>
                        </>
                      </Form>
                    )}
                  </Tab>
                  <Tab eventKey="PhoneNumber" title="Mobile Number">
                    {activeTab === "PhoneNumber" && (
                      <Form onSubmit={handleSubmit(onPhoneSubmit)}>
                        <>
                          <div style={{ height: 48 }} />
                          <div className="pfa-a-heading1">Mobile number</div>

                          <div className="phone-number-wrpr">
                            <PhoneInputWithCountry
                              withCountryCallingCode={true}
                              name="phoneInputWithCountrySelect"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Required",
                                },
                                maxLength: {
                                  value: 20,
                                  message: "Text length is limited to 20 chars",
                                },
                              }}
                              value={phoneCode}
                              onChange={handleChange}
                              placeholder="Enter your mobile number"
                              containerclass="my-container-class"
                              inputclass="my-input-class"
                              containerstyle={{
                                border: "10px solid black",
                              }}
                              inputstyle={{
                                background: "lightblue",
                              }}
                              defaultCountry={
                                isFoundDefaultCountry
                                  ? defaultCountryCode.countryCode
                                  : "AE"
                              }
                            />
                          </div>

                          {errors.phoneInputWithCountrySelect && (
                            <div className="error-message errorText">
                              {errors.phoneInputWithCountrySelect.message}
                            </div>
                          )}

                          {isOtpMobileVisible && (
                            <>
                              <div style={{ height: 20 }} />
                              <div className="pfa-a-heading1">OTP</div>
                              <Form.Control
                                disabled={isMobilePasswordVisible}
                                placeholder="Enter the verification code"
                                className={
                                  errors.userOtp
                                    ? "custom-pfa-class-style error"
                                    : "custom-pfa-class-style"
                                }
                                {...register("userOtp", {
                                  required: {
                                    value: true,
                                    message: "Required",
                                  },
                                  minLength: {
                                    value: 6,
                                    message: "Verification code is 6 digits",
                                  },
                                  maxLength: {
                                    value: 6,
                                    message: "Verification code is 6 digits",
                                  },
                                })}
                              />
                              {errors.userOtp && (
                                <div className="error-message errorText">
                                  {errors.userOtp.message}
                                </div>
                              )}
                            </>
                          )}

                          {isMobilePasswordVisible && (
                            <>
                              <div style={{ height: 20 }} />
                              <div className="pfa-a-heading1">New Password</div>
                              <Form.Control
                                type="password"
                                autoComplete="on"
                                placeholder="Enter your new password"
                                className={
                                  errors.newPassword
                                    ? "custom-pfa-class-style error"
                                    : "custom-pfa-class-style"
                                }
                                {...register("newPassword", {
                                  required: "required",
                                  pattern: {
                                    value: passwordRegex,
                                    message:
                                      "Should contains a letter, digit and a special character",
                                  },
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Password length is between 8 to 16 chars",
                                  },
                                  maxLength: {
                                    value: 16,
                                    message:
                                      "Password length is between 8 to 16 chars",
                                  },
                                })}
                              />
                              {errors.newPassword && (
                                <div className="error-message errorText">
                                  {errors.newPassword.message}
                                </div>
                              )}

                              <div style={{ height: 20 }} />
                              <div className="pfa-a-heading1">
                                Confirm New Password
                              </div>
                              <Form.Control
                                type="password"
                                autoComplete="on"
                                placeholder="Confirm your new password"
                                className={
                                  errors.confirmNewPassword
                                    ? "custom-pfa-class-style error"
                                    : "custom-pfa-class-style"
                                }
                                {...register("confirmNewPassword", {
                                  required: "required",
                                  validate: (value) =>
                                    value === typedPassword ||
                                    "Passwords do not match",
                                })}
                              />
                              {errors.confirmNewPassword && (
                                <div className="error-message errorText">
                                  {errors.confirmNewPassword.message}
                                </div>
                              )}
                            </>
                          )}

                          <button
                            disabled={isLoading}
                            className={
                              isLoading ? "log-in-btsss2" : "log-in-btsss1"
                            }
                            type="submit"
                          >
                            {isLoading ? <Spinner size="sm" /> : "Submit"}
                          </button>
                        </>
                      </Form>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default ForgotPassword;
