import React from "react";
import "./Styles.css";

const Heading = ({ title, subTitle }) => {
  return (
    <div className="viewAllRow">
      <div className="viewAllHeading">{title}</div>
      <div className="subtitle_head">{subTitle}</div>
      <div className="separatorLine" />
    </div>
  );
};

export default Heading;
