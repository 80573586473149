import axios from "axios";

import "react-phone-number-input/style.css";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { Footer, Navbar } from "../../Component";

import * as Urls from "../../constants/ApiConstants";
import { GenericPostApiCall } from "../../Services";

import "./Styles.css";

const ChangeMobile = () => {
  // useParams
  const { action } = useParams();

  // useNavigate
  const navigate = useNavigate();

  // use State => Initialize state variables
  const [isLoading, setIsLoading] = useState(false);
  const [phoneCode, setMobileNumber] = useState("");
  const [isFoundDefaultCountry, setDefaultCountryFlag] = useState(false);
  const [phoneCodeCountryCode, setCountryCode] = useState("");
  const [defaultCountryCode, setDefaultCountry] = useState("");

  // useEffect
  useEffect(() => {
    getGeoInfo();
  }, []);

  // react hooks form
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleChange = (phoneNumber) => {
    if (phoneNumber?.length > 1) {
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
      if (parsedPhoneNumber) {
        setMobileNumber(parsedPhoneNumber.nationalNumber);
        setCountryCode(parsedPhoneNumber.countryCallingCode);
      }
    }
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        if (data) {
          setDefaultCountryFlag(true);
          setDefaultCountry({
            countryName: data.country_name,
            countryCallingCode: data.country_calling_code,
            countryCode: data.country_code,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const onSubmit = async (data) => {
    if (phoneCode && phoneCodeCountryCode) {
      setIsLoading(true);
      const url = Urls.ClientChangeMobile;
      const body = {
        mobile: `+${phoneCodeCountryCode}` + phoneCode,
      };
      const [res, error] = await GenericPostApiCall(url, body);
      if (res?.code === 200) {
        navigate("/confirm-mobile", {
          state: {
            value: `+${phoneCodeCountryCode}` + phoneCode,
            code: res?.otp,
            action: action,
          },
        });
      } else {
        if (error?.detail) {
          alert(error?.detail);
        } else {
          alert(
            "Attention! we are unable to complete the request, please try again"
          );
        }
      }
      setIsLoading(false);
    } else {
      alert(
        "Attention! we can't process your request, please make sure you have entered a valid mobile number"
      );
    }
  };

  return (
    <div className="homeContainer">
      <Navbar />
      <Container style={{ minHeight: "90vh" }}>
        <div className="mt-0 mt-md-5">
          <Col lg={6} md={7} sm={10}>
            <div className="ch-email-main-wrpr">
              <div className="ch-email-main-heading pb-2">
                {action === "change" ? `Change mobile` : `Add mobile`}
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="text-white">
                  <Row>
                    <Col lg={12} sm={12}>
                      <PhoneInputWithCountry
                        withCountryCallingCode={true}
                        name="phoneInputWithCountrySelect"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Required",
                          },
                          maxLength: {
                            value: 20,
                            message: "Text length is limited to 20 chars",
                          },
                        }}
                        value={phoneCode}
                        onChange={handleChange}
                        placeholder="Enter your number"
                        containerclass="my-container-class"
                        inputclass="my-input-class"
                        containerstyle={{
                          border: "10px solid black",
                        }}
                        inputstyle={{
                          background: "lightblue",
                        }}
                        defaultCountry={
                          isFoundDefaultCountry
                            ? defaultCountryCode.countryCode
                            : "AE"
                        }
                      />
                      {errors.mobile && (
                        <div className="errorText">{errors.mobile.message}</div>
                      )}
                    </Col>
                  </Row>
                </div>

                <div style={{ height: "40px" }}></div>

                <Row className="mb-2">
                  <Col lg={8} md={8} sm={8}></Col>
                  <Col lg={4} md={4} sm={4}>
                    <button className="pr-edit-submit-button w-100">
                      {isLoading ? <Spinner size="sm" /> : `Submit`}
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default ChangeMobile;
