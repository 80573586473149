import React from "react";
import "./Styles.css";
import Icons from "../../Icons";
import CelebrityDefault from "../../Assets/CelebrityDefault.jpg";

const CelebrityCardForGrid = ({ data }) => {
  const bookingAmount = data?.bookingCost?.amount;
  const celebrityName = `${data?.celebrityFirstName} ${data?.celebrityName}`;
  const imgAlt = `Photo of ${celebrityName}, click to book`;
  const currencySymbol = data?.currency === "USD" ? "$" : data?.currency;

  return (
    <div className="">
      <div className="hrIWN">
        <img
          src={data?.profilePhoto ? data?.profilePhoto : CelebrityDefault}
          alt={imgAlt}
          className="jMSkFv"
        />
      </div>
      <div className="gqtDFW">
        <div className="cNNst">{celebrityName}</div>
        <div className="cIntTx">{data?.intro}</div>
        <div className="bkAdi">
          <div className="amCNw">
            <div className="bKCy">{currencySymbol}</div> {bookingAmount}
          </div>
          {data?.fastDelivery && (
            <>
              <div className="lineStylesNew" />
              <Icons
                icon={"time"}
                color="#9CD0FA"
                className="common-icon-size"
              />
              <div className="timeHr">24hr</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CelebrityCardForGrid;
