import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import {
  CelebrityCardForGrid,
  Footer,
  Navbar,
  PageHeading,
} from "../../Component";
import { GenericGetApisExtraParams } from "../../Services";
import * as Urls from "../../constants/ApiConstants";
import SpinnerComponent from "../../Component/Spinner";

import "./Styles.css";

function makeTitle(slug) {
  var words = slug.split("-");

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ");
}

const ViewAll = () => {
  // params
  const { key } = useParams();

  //   // useState
  const [allCelebrityData, setAllCelebrityData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // useEffect
  useEffect(() => {
    fetchCelebrityList();
  }, []);

  const fetchCelebrityList = async () => {
    setIsLoading(true);
    const params = { key: key };
    const url = Urls.GetAllCategoryByKey;
    const [res, error] = await GenericGetApisExtraParams(url, params);

    if (res?.code === 200) {
      setAllCelebrityData(res?.data);
    } else {
      // console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="homeContainer">
      <Navbar />
      <Container style={{ minHeight: "90vh" }}>
        {isLoading ? (
          <SpinnerComponent />
        ) : (
          <>
            <PageHeading title={makeTitle(key)} />
            <div className="mnCtDaLs mb-5">
              {allCelebrityData?.map((i, index) => (
                <div key={index}>
                  <Link to={`/celeb/${i.username}`}>
                    <CelebrityCardForGrid data={i} />
                  </Link>
                </div>
              ))}
            </div>
          </>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default ViewAll;
