import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { Footer, Navbar } from "../../Component";

import * as Urls from "../../constants/ApiConstants";
import { GenericPostApiCall } from "../../Services";

import "./Styles.css";

const ChangeEmail = () => {
  // useParams
  const { action } = useParams();

  // useNavigate
  const navigate = useNavigate();

  // use State => Initialize state variables
  const [isLoading, setIsLoading] = useState(false);

  // Email validation regex pattern
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  // useEffect
  useEffect(() => {}, []);

  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (data?.email) {
      setIsLoading(true);
      const url = Urls.ClientChangeEmail;
      const body = {
        email: data?.email,
      };
      const [res, error] = await GenericPostApiCall(url, body);
      // console.log(res, error);
      if (res?.code === 200) {
        navigate("/confirm-email", {
          state: {
            value: data?.email,
            code: res?.otp,
            action: action,
          },
        });
      } else {
        if (error?.detail) {
          alert(error?.detail);
        } else {
          alert(
            "Attention! we are unable to complete the request, please try again"
          );
        }
      }
      setIsLoading(false);
    } else {
      alert(
        "Attention! we can't process your request, please make sure you have entered valid email id"
      );
    }
  };

  return (
    <div className="homeContainer">
      <Navbar />
      <Container style={{ minHeight: "90vh" }}>
        <div className="mt-0 mt-md-5">
          <Col lg={6} md={7} sm={10}>
            <div className="ch-email-main-wrpr">
              <div className="ch-email-main-heading pb-2">
                {action === "change" ? `Change email` : `Add email`}
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="text-white">
                  <Row>
                    <Col lg={12} sm={12}>
                      <Form.Control
                        placeholder="email@example.com"
                        type="email"
                        className={
                          errors.email
                            ? "pr-edit-form-control errorBoarder"
                            : "pr-edit-form-control "
                        }
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                          maxLength: {
                            value: 100,
                            message: "Text length is limited to 100 chars",
                          },
                          pattern: {
                            value: emailRegex,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.email && (
                        <div className="errorText">{errors.email.message}</div>
                      )}
                    </Col>
                  </Row>
                </div>

                <div style={{ height: "24px" }}></div>

                <Row className="mb-2">
                  <Col lg={8} md={8} sm={8}></Col>
                  <Col lg={4} md={4} sm={4}>
                    <button className="pr-edit-submit-button w-100">
                      {isLoading ? <Spinner size="sm" /> : `Submit`}
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default ChangeEmail;
