import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { Footer, Navbar, Spinner, RecentVideos } from "../../Component";
import Icons from "../../Icons";
import Ale from "../../Assets/users.svg";
// import Mute from "../../Assets/mute.svg";
// import Boss from "../../Assets/boss.png";
import * as apiUrls from "../../constants/ApiConstants";
import { GenericGetApis } from "../../Services";

import "./Styles.css";

const ProfileHome = () => {
  const [users] = useState(
    localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
  );

  // useState
  const [initialLoader, setInitialLoader] = useState(true);
  const [recentVideoData, setRecentVideoData] = useState(true);

  // useEffect
  useEffect(() => {
    GetAllRecentVideo();
  }, []);

  // get all recent videos
  const GetAllRecentVideo = async () => {
    setInitialLoader(true);
    const url = apiUrls.GetAllRecentVideos;
    const [response, error] = await GenericGetApis(url);
    if (error) {
      // console.log("error in p-h-recent-api");
    }
    // console.log(response);
    if (response?.code === 200) {
      setRecentVideoData(response.recentOrder);
    } else {
      // console.log(error, "coming from add setRecentVideoData  on profile");
      setRecentVideoData(true);
    }
    setInitialLoader(false);
  };

  return (
    <div className="profileHomeContainer">
      <Navbar />
      {initialLoader ? (
        <Spinner />
      ) : (
        <>
          <Container style={{ minHeight: "90vh" }}>
            <div className="pf-main-top-head pt-0 pt-md-3 pb-2">Profile</div>
            <div className="all-wrpr-cs-pro">
              <div className="profileCardSecWrpr">
                {/* first section */}
                <div className="d-flex justify-content-between align-items-end align-items-sm-start">
                  <div className="d-sm-flex">
                    <div>
                      {users[0]?.profileImage ? (
                        <>
                          <div className="p-c-avatar-imgs">
                            <img
                              className="w-100"
                              src={users[0]?.profileImage}
                              alt="name"
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="p-c-avatar-imgs-default">
                            <img
                              className="p-p-user-imgs h-100 w-auto"
                              src={Ale}
                              alt="name"
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <div className="ps-sm-3 pt-3 py-sm-0">
                      <div className="p-c-i-names">{users[0]?.name}</div>
                      {users[0]?.userName ? (
                        <div className="p-f-inner-value-txt-top">
                          {users[0]?.userName}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {users[0]?.dob ? (
                        <div className="p-f-inner-value-txt-top">
                          {users[0]?.dob}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {users[0]?.bio ? (
                        <div className="p-f-inner-value-txt-top">
                          {users[0]?.bio}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>

                  <div className="mt-2 mt-sm-0">
                    <Link to={"/edit-profile"}>
                      <div className="edit-profile-btns justify-content-center">
                        {/* <Icons icon="pencil" size={12} color={"#F8F8F8"} /> */}
                        <div className="e-p-s-txt">Edit</div>
                      </div>
                    </Link>
                  </div>
                </div>

                <hr style={{ color: "#a7a7a7" }}></hr>

                {/* second section */}
                <div className="text-white">
                  <div className="section-semi-head">Contact Details</div>

                  {/* Mobile number */}
                  <Row className="py-2">
                    {users[0].mobile ? (
                      <>
                        <Col md={3} xs={12}>
                          <div className="p-f-inner-label">Mobile number</div>
                        </Col>
                        <Col md={7} xs={9}>
                          <div className="p-f-inner-value">
                            {users[0].mobile}
                          </div>
                        </Col>
                        <Col md={2} sm={3} xs={3} className="text-end">
                          <Link to={`/mobile/change`} className="link-btn-act">
                            Change
                          </Link>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md={10} xs={9}>
                          <div className="p-f-inner-label">Mobile number</div>
                        </Col>
                        <Col md={2} sm={3} xs={3} className="text-end">
                          <Link to={"/mobile/add"} className="link-btn-act">
                            Add
                          </Link>
                        </Col>
                      </>
                    )}
                  </Row>
                  {/* Email */}
                  <Row className="py-2">
                    {users[0].email ? (
                      <>
                        <Col md={3} xs={12}>
                          <div className="p-f-inner-label">Email</div>
                        </Col>
                        <Col md={7} xs={9}>
                          <div className="p-f-inner-value">
                            {users[0].email}
                          </div>
                        </Col>
                        <Col md={2} sm={3} xs={3} className="text-end">
                          <Link to={`/email/change`} className="link-btn-act">
                            Change
                          </Link>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md={10} xs={9}>
                          <div className="p-f-inner-label">Email</div>
                        </Col>

                        <Col md={2} xs={3} className="text-end">
                          <Link to={"/email/add"} className="link-btn-act">
                            Add
                          </Link>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>

                {/* ----- recent videos start ----- */}
                {recentVideoData.length > 0 && (
                  <>
                    <hr style={{ color: "#a7a7a7" }}></hr>
                    <div className="r-p-inners-wrpr">
                      <div className="section-semi-head">Recent Videos</div>
                      <div className="-an-r-v-wrprs">
                        {recentVideoData?.map((i, index) => (
                          <RecentVideos
                            i={i}
                            index={index}
                            key={index}
                            mode="profile"
                          ></RecentVideos>
                        ))}
                      </div>
                    </div>
                  </>
                )}
                {/* ----- recent videos end ----- */}
              </div>
            </div>
          </Container>
        </>
      )}
      <Footer />
    </div>
  );
};

export default ProfileHome;
