import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

import { Footer, Navbar } from "../../Component";

import "./styles.css";

const PageNotFound = () => {
  // useNavigate
  const navigate = useNavigate();

  return (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Yuji Boku"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat"
        rel="stylesheet"
      ></link>
      <div className="privacyContainer">
        <Navbar />
        <Container>
          <div className="main-wrapper">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="main-head pt-2">oh no!</div>
              <div style={{ height: "48px" }}></div>
              <div className="sub-head">Something went wrong</div>
              <div style={{ height: "16px" }}></div>
              <div className="desc-text">
                The page you are looking for was moved or removed or might never
                existed
              </div>
              <div style={{ height: "48px" }}></div>
              {/* <div className="submit-btn">

              </div> */}
              <button
                className="submit-btn submit-btn-text"
                type="button"
                onClick={() => navigate("/")}
              >
                Back to home
              </button>
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default PageNotFound;
