import axios from "axios";
import queryString from "query-string";

import {
  signInWithPopup,
  fetchSignInMethodsForEmail,
  signInWithCredential,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

import { useForm } from "react-hook-form";

import React, { useContext, useState, useEffect } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { Footer, Navbar } from "../../Component";
import Icons from "../../Icons";
import * as apiUrls from "../../constants/ApiConstants";
import { MyContext } from "../../Store";
import { auth, provider, fbProvider } from "../../Utils/firebase";
import { GenericPostApiNoAuth } from "../../Services";

import "./Styles.css";

const Login = () => {
  //useNavigate
  const navigate = useNavigate();

  // Use Location
  const location = useLocation();

  // useContext
  const { saveToken } = useContext(MyContext);

  // useState
  const [isLoading, setIsLoading] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const { redirectTo } = queryString.parse(location.search);

  const supportedPopupSignInMethods = [
    GoogleAuthProvider.PROVIDER_ID,
    FacebookAuthProvider.PROVIDER_ID,
  ];

  // useEffect
  useEffect(() => {
    getLocation();
  }, []);

  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogin = async (data) => {
    if (data?.userName && data?.password) {
      setIsLoading(true);

      const url = apiUrls?.ClientLogin;

      const body = JSON.stringify({
        mobile: data?.userName,
        password: data?.password,
        latitude: latitude ? latitude : "",
        longitude: longitude ? longitude : "",
      });

      try {
        const response = await axios(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Accept-Language": "en",
          },
          data: body,
        });

        if (response?.data?.code === 200) {
          const data = await response.data?.data[0];
          // Store the token in local storage
          localStorage.setItem("token", `token ${data.token}`);
          // Store the token in local storage
          saveToken(`token ${data.token}`);
          if (redirectTo) {
            navigate(redirectTo);
          } else {
            navigate("/");
          }
          // console.log("successfully login");
        } else {
          console.error(response);
          // console.log("error in login , details = ", response?.data?.detail);
          alert(response?.data?.detail);
        }
      } catch (error) {
        // console.log("error in login , details = ", error);
        alert(
          "Attention! unable to complete the request, please try again later"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("Attention! please make sure all recommended fields are typed");
    }
  };

  // sign in with google
  const GoogleLogin = async () => {
    setIsLoading(true);
    try {
      signInWithPopup(auth, provider).then((result) => {
        handleSocialMediaAuthSignIn(result);
      });
    } catch (error) {
      // console.log("social login - google, error = ", error);
      setIsLoading(false);
    }
  };

  // sign in with facebook
  const FacebookLogin = async () => {
    try {
      provider.addScope("user_birthday");
      signInWithPopup(auth, fbProvider).then(function (result) {
        handleSocialMediaAuthSignIn(result);
      });
    } catch (error) {
      // console.log("social login - facebook, error = ", error);
      // console.log(error);

      if (
        error.email &&
        error.credential &&
        error.code === "auth/account-exists-with-different-credential"
      ) {
        const current_providers = await fetchSignInMethodsForEmail(error.email);
        const firstPopupProviderMethod = current_providers.find((p) =>
          supportedPopupSignInMethods.includes(p)
        );

        // Test: Could this happen with email link then trying social provider?
        if (!firstPopupProviderMethod) {
          throw new Error(
            `Your account is linked to a provider that isn't supported.`
          );
        }

        const linkedProvider = getProvider(firstPopupProviderMethod);
        linkedProvider.setCustomParameters({ login_hint: error.email });

        const new_result = await signInWithPopup(auth, linkedProvider);
        new_result.user.linkWithCredential(error.credential);

        handleSocialMediaAuthSignIn(new_result);
      } else {
        // console.log("Here reached");
      }

      // fetchSignInMethodsForEmail(error.email).then((providers) => {
      //   //providers returns this array -> ["google.com"]
      //   // You need to sign in the user to that google account
      //   // with the same email.
      //   // In a browser you can call:
      //   // var provider = new firebase.auth.GoogleAuthProvider();
      //   // provider.setCustomParameters({login_hint: error.email});
      //   // firebase.auth().signInWithPopup(provider)
      //   // If you have your own mechanism to get that token, you get it
      //   // for that Google email user and sign in
      //   signInWithCredential(auth, error.credential).then(function (data) {});

      //   // signInWithPopup(googleCred)
      //   //   .then((user) => {
      //   //     // You can now link the pending credential from the first
      //   //     // error.
      //   //     user.linkWithCredential(error.credential);
      //   //   })
      //   //   .catch((error) => log(error));
      // });
    }
  };

  const handleSocialMediaAuthSignIn = async (autData) => {
    const url = apiUrls?.ClientSocialRegister;
    const provider = autData.providerId;
    const body = {
      fullName: autData?.user?.displayName,
      email: autData?.user?.providerData[0]?.email,
      isEmailVerified: true,
      dob: "",
      profileImage: autData?.user?.providerData[0]?.photoURL
        ? autData?.user?.providerData[0]?.photoURL
        : "",
      provider: provider,
      firebaseUid: autData?.user?.uid,
      providerUid: autData?.user?.providerData[0]?.uid,
      latitude: "",
      longitude: "",
      deviceData: {
        modelName: "",
        brand: "",
        uniqueId: "",
        deviceId: "",
      },
    };
    const [res, error] = await GenericPostApiNoAuth(url, body);

    if (res?.code === 200 && res?.token) {
      localStorage.setItem("token", `token ${res.token}`);
      saveToken(`token ${res.token}`);
      if (redirectTo) {
        navigate(redirectTo);
      } else {
        navigate("/");
      }
    } else {
      // console.log(error, "....");
    }
    setIsLoading(false);
  };

  const getLocation = () => {
    if (!navigator.geolocation) {
      // console.log("Geolocation is not supported by your browser");
    } else {
      // console.log("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log("position = ", position);
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        () => {
          // console.log("Unable to retrieve your location");
        }
      );
    }
  };

  function getProvider(providerId) {
    switch (providerId) {
      case GoogleAuthProvider.PROVIDER_ID:
        return new GoogleAuthProvider();
      case FacebookAuthProvider.PROVIDER_ID:
        return new FacebookAuthProvider();
      default:
        throw new Error(`No provider implemented for ${providerId}`);
    }
  }

  return (
    <div className="loginContainer">
      <Navbar />
      <Container>
        <div className="main-logon-inner">
          <Row className="justify-content-center">
            <Col lg={6} md={7} sm={10}>
              <div className="login-card-wrpr">
                <div className="login_head">Login or sign up</div>
                <div>
                  <button onClick={GoogleLogin} className="fbbtns">
                    <Icons
                      icon={"google"}
                      color={"#ffffff"}
                      className="common-icon-size"
                    />
                    <div className="s-ss-ssep">Continue with Google</div>
                  </button>
                  <div className="h-24-s" />
                  <button onClick={FacebookLogin} className="fbbtns">
                    <Icons
                      icon={"fb"}
                      color={"#ffffff"}
                      className="common-icon-size"
                    />
                    <div className="s-ss-ssep">Continue with Facebook</div>
                  </button>
                </div>
                <div className="line-wrprs-1" />
                <Form onSubmit={handleSubmit(handleLogin)}>
                  <Form.Control
                    placeholder="Mobile with country code | Email | User name"
                    className={
                      errors.userName
                        ? "custom-pfa-class-styleq1 errorBoarder"
                        : "custom-pfa-class-styleq1"
                    }
                    {...register("userName", {
                      maxLength: {
                        value: 100,
                        message: "Text length is limited to 100 characters",
                      },
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  />
                  {errors.userName && (
                    <div className="errorText">{errors.userName.message}</div>
                  )}
                  <div style={{ height: 12 }} />

                  <Form.Control
                    placeholder="Password"
                    id="id_password"
                    type="password"
                    autoComplete="on"
                    className={
                      errors.password
                        ? "custom-pfa-class-styleq1 errorBoarder"
                        : "custom-pfa-class-styleq1"
                    }
                    {...register("password", {
                      maxLength: {
                        value: 100,
                        message: "Text length is limited to 100 characters",
                      },
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  />
                  {errors.password && (
                    <div className="errorText">{errors.password.message}</div>
                  )}

                  <button
                    disabled={isLoading}
                    className={isLoading ? "log-in-btsError" : "log-in-btsss"}
                    type="submit"
                  >
                    {isLoading ? <Spinner size="sm" /> : `Login`}
                  </button>
                </Form>
                <div className="privacyText">
                  By signing in, you agree to iShout's &nbsp;
                  <Link to={"/privacy"}>
                    <div className="privacyPlolicy">Privacy policy</div>
                  </Link>
                  <div className="andSigns">&</div>
                  <Link to={"/terms"}>
                    <div className="termsOfUse">Terms of use</div>
                  </Link>
                </div>
                <Link to={"/forgot-password"}>
                  <div className="forgotText text-decoration-underline">
                    Forgot your password?
                  </div>
                </Link>
                <div className="regBtns">
                  Don't have an account?&nbsp;
                  <Link to={"/register"}>
                    <div className="signUpTxt">Sign Up</div>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Login;
