import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Footer, Navbar } from "../../Component";

import * as Urls from "../../constants/ApiConstants";
import { GenericPostApiCall, GenericGetApis } from "../../Services";

import "./Styles.css";

const ChangePassword = () => {
  // useNavigate
  const navigate = useNavigate();

  // use State => Initialize state variables
  const [isLoading, setIsLoading] = useState(false);
  // Get data from local storage
  const [userDetail] = useState(
    localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
  );
  const [isPasswordAlreadySet, setIsPasswordAlreadySet] = useState(false);
  const passwordRegex =
    /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

  // useEffect
  useEffect(() => {
    if (userDetail[0]?.isPasswordChanged) {
      setIsPasswordAlreadySet(true);
    }
  }, []);

  // react hooks form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (data?.password) {
      setIsLoading(true);

      let url = Urls.setNewPassword;
      let body = {
        password: data?.password,
      };

      if (isPasswordAlreadySet) {
        url = Urls.SetNewPasswordForWeb;
        body = {
          password: data?.password,
          currentPassword: data?.currentPassword,
        };
      }

      const [res, error] = await GenericPostApiCall(url, body);
      // console.log(res, error);
      if (res?.code === 200) {
        let resp_flag = getUserDetails();
        if (resp_flag) {
          alert("Password successfully changed!");
          navigate("/");
        }
      } else {
        if (error?.detail) {
          alert(error?.detail);
        } else {
          alert(
            "Attention! we are unable to complete the request, please try again"
          );
        }
      }
      setIsLoading(false);
    } else {
      alert(
        "Attention! we can't process your request, please make sure you entered all recommended fields"
      );
    }
  };

  const getUserDetails = async () => {
    let response_flag = false;
    const profile_details_url = Urls.UserProfileDetails;
    const [response, error] = await GenericGetApis(profile_details_url);
    if (response?.code === 200) {
      // console.log("response = ", response);
      localStorage.setItem("userDetails", JSON.stringify(response?.data));
      response_flag = true;
    } else {
      // console.log("Error from User Details API, details = ", error);
    }
    return response_flag;
  };

  return (
    <div className="homeContainer">
      <Navbar />
      <Container>
        <div
          style={{ margin: "20px 0px 40px 0px" }}
          className="justify-content-center"
        >
          <Col lg={6} md={8} sm={10} className="mx-auto">
            <div className="ch-password-main-wrpr">
              <div className="ch-password-main-heading pb-3">
                {isPasswordAlreadySet ? `Change password` : `Set new password`}
              </div>
              <div className="pb-4 text-center ch-password-sub-head">
                Password must contain at least 1 letter, 1 number and 1 symbol.
                Password length is in between 8-16 characters
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="text-white">
                  <Row>
                    {isPasswordAlreadySet ? (
                      <>
                        <Form.Group>
                          <Form.Label className="ch-password-form-label">
                            Current Password
                          </Form.Label>

                          <Form.Control
                            placeholder="Current password"
                            type="text"
                            className={
                              errors.currentPassword
                                ? "pr-edit-form-control errorBoarder"
                                : "pr-edit-form-control "
                            }
                            {...register("currentPassword", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                              maxLength: {
                                value: 100,
                                message: "Text length is limited to 100 chars",
                              },
                            })}
                          />
                          {errors.currentPassword && (
                            <div className="errorText">
                              {errors.currentPassword.message}
                            </div>
                          )}
                        </Form.Group>
                        <div style={{ height: "16px" }}></div>
                      </>
                    ) : (
                      <div></div>
                    )}

                    <Form.Group>
                      <Form.Label className="ch-password-form-label">
                        New Password
                      </Form.Label>
                      <Form.Control
                        placeholder="New password"
                        id="id_password"
                        type="text"
                        className={
                          errors.password
                            ? "pr-edit-form-control errorBoarder w-100"
                            : "pr-edit-form-control w-100"
                        }
                        {...register("password", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                          pattern: {
                            value: passwordRegex,
                            message:
                              "Should contains a letter, digit and a special character",
                          },
                          minLength: {
                            value: 8,
                            message: "Password length is between 8 to 16 chars",
                          },
                          maxLength: {
                            value: 16,
                            message: "Password length is between 8 to 16 chars",
                          },
                        })}
                      />
                      {errors.password && (
                        <div className="errorText">
                          {errors.password.message}
                        </div>
                      )}
                    </Form.Group>

                    <div style={{ height: "16px" }}></div>
                    <Form.Group>
                      <Form.Label className="ch-password-form-label">
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        placeholder="Confirm password"
                        type="text"
                        className={
                          errors.confirmPassword
                            ? "pr-edit-form-control errorBoarder"
                            : "pr-edit-form-control "
                        }
                        {...register("confirmPassword", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                          validate: (val) => {
                            if (watch("password") !== val) {
                              return "Your passwords do no match";
                            }
                          },
                        })}
                      />
                      {errors.confirmPassword && (
                        <div className="errorText">
                          {errors.confirmPassword.message}
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                </div>

                <div style={{ height: "32px" }}></div>

                <Row>
                  <Form.Group>
                    <button className="pr-edit-submit-button w-100">
                      {isLoading ? <Spinner size="sm" /> : `Submit`}
                    </button>
                  </Form.Group>
                </Row>
              </Form>
            </div>
          </Col>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default ChangePassword;
