import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Footer, Navbar } from "../../Component";
import default_profile from "../../Assets/default-profile.png";

import * as Urls from "../../constants/ApiConstants";
import { GenericGetApis } from "../../Services";

import "./Styles.css";

const Profile = () => {
  // Get data from local storage
  const [userDetail, setUserDetails] = useState(
    localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
  );

  //useNavigate
  const navigate = useNavigate();

  // use State => Initialize state variables
  const [isLoading, setIsLoading] = useState(false);
  const [isProfilePicture, setIsProfilePicture] = useState(
    userDetail[0].profileImage ? true : false
  );
  const [isProfilePictureChanged, setIsProfilePictureChanged] = useState(false);
  const [profilePictureSource, setProfilePictureSource] = useState();
  const [newProfilePictureData, setNewProfilePictureData] = useState();

  // useEffect
  useEffect(() => {
    if (userDetail[0].profileImage) {
      setProfilePictureSource(userDetail[0].profileImage);
    }
  }, [userDetail]);

  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: userDetail[0].name ? userDetail[0].name : ``,
      userName: userDetail[0].userName ? userDetail[0].userName : ``,
      bio: userDetail[0].bio ? userDetail[0].bio : ``,
      dob: userDetail[0].dob ? userDetail[0].dob : ``,
    },
  });

  const handleProfileImage = () => {
    document.getElementById("id-hidden-image").click();
  };

  let handleChange = (e) => {
    e.preventDefault();
    // console.log(e.target.files);
    if (e.target.files && e.target.files[0]) {
      setProfilePictureSource(URL.createObjectURL(e.target.files[0]));
      setNewProfilePictureData(e.target.files[0]);
      setIsProfilePicture(true);
      setIsProfilePictureChanged(true);
    } else {
      setIsProfilePicture(false);
      setIsProfilePictureChanged(false);
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const url = Urls.ClientEditProfile;
    const token = localStorage.getItem("token");

    let form_data = new FormData();
    if (isProfilePictureChanged) {
      if (newProfilePictureData) {
        form_data.append(
          "profile",
          newProfilePictureData,
          newProfilePictureData.name
        );
      }
    }

    form_data.append("fullName", data.fullName);
    form_data.append("userName", data.userName);
    form_data.append("bio", data.bio);
    form_data.append("dob", data.dob);

    try {
      await fetch(url, {
        method: "POST",
        headers: {
          Authorization: token,
          "Accept-Language": "en",
        },
        body: form_data,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response?.code === 200) {
            const resp_fl = getUserDetails();
            if (resp_fl) {
              alert("Profile successfully updated!");
              navigate(`/profile`);
            }
          } else {
            alert(
              response?.detail ? response?.detail : "oops! something went wrong"
            );
            if (
              response?.detail ===
              "Attention! The request can't be completed. The username you typed is not available"
            ) {
              document.getElementById("id_username").focus();
            }
          }
        });
    } catch (error) {
      setIsLoading(false);
      alert(
        "Attention! unable to complete your request, please try again later"
      );
      // console.log("==> error from profile image upload", error);
    }
    setIsLoading(false);
  };

  const getUserDetails = async () => {
    let response_flag = false;
    const profile_details_url = Urls.UserProfileDetails;
    const [response, error] = await GenericGetApis(profile_details_url);
    if (response?.code === 200) {
      localStorage.setItem("userDetails", JSON.stringify(response?.data));
      setUserDetails(response?.data);
      response_flag = true;
    } else {
      // console.log("Error from User Details API, details = ", error);
    }
    return response_flag;
  };

  return (
    <div className="homeContainer">
      <Navbar />
      <Container>
        <Row>
          <Col lg={6} md={7} sm={10}>
            <div className="pr-edit-main-heading pt-0 pt-md-3 pb-2">
              Edit profile
            </div>
            <div className="pr-edit-main-wrpr mb-4">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="pr-edit-upload-div mb-4">
                  {isProfilePicture ? (
                    <div className="profileImageWrpr">
                      <img
                        src={profilePictureSource}
                        className="w-100"
                        alt="profile"
                      />
                    </div>
                  ) : (
                    <div className="defaultProfileImageWrpr">
                      <img
                        src={default_profile}
                        className="w-100"
                        alt="profile_image"
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    id="id-hidden-image"
                    accept="image/*"
                    onChange={handleChange}
                    className="d-none"
                  ></input>
                  <div className="">
                    <button
                      className="btn btn-link pr-edit-upload-link"
                      onClick={handleProfileImage}
                      type="button"
                    >
                      Upload Photo
                    </button>
                  </div>
                </div>
                <div className="text-white">
                  <Row className="mb-2">
                    <Col lg={3} sm={3}>
                      <div className="pr-edit-form-label mt-sm-2">Name</div>
                    </Col>
                    <Col lg={9} sm={9}>
                      <Form.Control
                        placeholder="John Doe"
                        className={
                          errors.fullName
                            ? "pr-edit-form-control errorBoarder"
                            : "pr-edit-form-control "
                        }
                        {...register("fullName", {
                          maxLength: {
                            value: 100,
                            message: "Text length is limited to 100 characters",
                          },
                          required: {
                            value: true,
                            message: "Required",
                          },
                        })}
                      />
                      {errors.fullName && (
                        <div className="errorText">
                          {errors.fullName.message}
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg={3} sm={3}>
                      <div className="pr-edit-form-label mt-sm-2">Username</div>
                    </Col>
                    <Col lg={9} sm={9}>
                      <Form.Control
                        placeholder="john_doe"
                        id="id_username"
                        className={
                          errors.userName
                            ? "pr-edit-form-control errorBoarder"
                            : "pr-edit-form-control"
                        }
                        {...register("userName", {
                          maxLength: {
                            value: 100,
                            message: "Text length is limited to 100 characters",
                          },
                          required: {
                            value: true,
                            message: "Required",
                          },
                        })}
                      />
                      {errors.userName && (
                        <div className="errorText">
                          {errors.userName.message}
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg={3} sm={3}>
                      <div className="pr-edit-form-label mt-sm-4">Bio</div>
                    </Col>
                    <Col lg={9} sm={9}>
                      <Form.Control
                        placeholder="A little about you"
                        as="textarea"
                        rows={3}
                        className={
                          errors.bio
                            ? "pr-edit-form-control errorBoarder"
                            : "pr-edit-form-control"
                        }
                        {...register("bio", {
                          maxLength: {
                            value: 250,
                            message: "Text length is limited to 250 characters",
                          },
                        })}
                      />
                      {errors.bio && (
                        <div className="errorText">{errors.bio.message}</div>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg={3} sm={3}>
                      <div className="pr-edit-form-label mt-sm-2">Dob</div>
                    </Col>
                    <Col lg={9} sm={9}>
                      <Form.Control
                        type="date"
                        id="id_dob"
                        defaultValue="1994-09-08"
                        className={
                          errors.dob
                            ? "pr-edit-form-control errorBoarder"
                            : "pr-edit-form-control"
                        }
                        {...register("dob")}
                      />
                    </Col>
                  </Row>
                </div>

                <div style={{ height: "40px" }}></div>

                <Row className="mb-2">
                  <Col lg={8} md={8} sm={8}></Col>
                  <Col lg={4} md={4} sm={4}>
                    <button className="pr-edit-submit-button w-100">
                      {isLoading ? <Spinner size="sm" /> : `Save`}
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Profile;
