import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";

import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

import * as apiUrls from "../../constants/ApiConstants";
import { GenericPostApiCall, GenericGetApis } from "../../Services";
import SpinnerComponent from "../../Component/Spinner";

import "./Styles.css";

const PaymentPage = () => {
  const navigate = useNavigate();

  // useLocation
  const location = useLocation();
  const { personalBookingFormData } = location.state;
  const { bookingCostFastDelivery, bookingCost, fastDelivery, celebrityName } =
    personalBookingFormData;

  // useState
  const [selectedDeliveryInfoValue, setSelectedDeliveryInfoValue] =
    useState("normal");

  const [isLoading, setIsLoading] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [PayPalFixedFee, setPayPalFixedFee] = useState(0.3);
  const [PayPalPayInPercentage, setPayPalPayInPercentage] = useState(0.049);

  const [bookingCharge, setBookingCharge] = useState(bookingCost.amount);
  const [platformFee, setPlatformFee] = useState(0);
  const [total, setTotal] = useState(0);

  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const handleRadioChange = (e) => {
    setSelectedDeliveryInfoValue(e.target.value);
    if (e.target.value === "fast") {
      const calculated = calculatePlatformFee(bookingCostFastDelivery.amount);

      setTotal(
        Math.round((bookingCostFastDelivery.amount + calculated) * 100) / 100
      );
      setPlatformFee(calculated);
      setBookingCharge(bookingCostFastDelivery.amount);
    } else {
      const calculated = calculatePlatformFee(bookingCost.amount);

      setTotal(Math.round((bookingCost.amount + calculated) * 100) / 100);
      setPlatformFee(calculated);
      setBookingCharge(bookingCost.amount);
    }
  };

  useEffect(() => {
    GetPaypalClientID();
    // setIsPaymentLoading(false);
    const calculated = calculatePlatformFee(bookingCost.amount);
    setTotal(Math.round((bookingCost.amount + calculated) * 100) / 100);
    setPlatformFee(calculated);
    setBookingCharge(bookingCost.amount);
  }, [bookingCost.amount]);

  const handleBookingOnSubmit = async (details) => {
    setIsPaymentLoading(false);
    setIsLoading(true);
    if (details?.status === "COMPLETED") {
      const {
        isPrivate,
        celebrityId,
        contentLanguage,
        friendName,
        instruction,
        introduction,
        occasion,
        pronoun,
        shoutOutFor,
        shoutOutTo,
        shoutOutPurpose,
      } = personalBookingFormData;

      const bodyNewOrder = {
        shoutOutPurpose: shoutOutPurpose,
        shoutOutFor: shoutOutFor,
        shoutOutTo: shoutOutTo,
        celebrityId: celebrityId,
        instruction: instruction,
        friendName: friendName,
        introduction: introduction ? introduction : "",
        pronoun: pronoun,
        isOrderPublic: isPrivate === false ? 1 : 0,
        occasion: occasion ? occasion : "",
        contentLanguage: Number(contentLanguage),
        isOrderFastDelivery: selectedDeliveryInfoValue === "normal" ? 0 : 1,
      };

      const [firstResp, firstError] = await GenericPostApiCall(
        apiUrls.NewOrder,
        bodyNewOrder
      );
      if (firstError) {
        // console.log("firstError = ", firstError);
      }
      if (firstResp?.code === 200) {
        const bodyOrderConfirm = {
          orderId: firstResp?.orderId,
          paymentMethod: "paypal",
          total: total,
          bookingCharge: bookingCharge,
          serviceCharge: platformFee,
          currency: "USD",
          paymentId: details?.purchase_units[0].payments?.captures[0].id,
          paypalPaymentModeWeb: true,
        };

        const [secondResp, secondError] = await GenericPostApiCall(
          apiUrls.OrderConfirm,
          bodyOrderConfirm
        );
        // console.log("secondResp = ", secondResp, "secondError = ", secondError);
        if (secondResp?.status === "SUCCESS") {
          setIsLoading(false);
          navigate(`/payment-success/${secondResp.orderId}`);
        } else {
          const bodyOrderDispute = {
            orderId: firstResp?.orderId,
            paymentMethod: "paypal",
            amount: total,
            serviceCharge: "",
            currency: "USD",
            paymentId: details?.purchase_units[0].payments?.captures[0].id,
            paypalPaymentModeWeb: true,
          };
          handleOrderDispute(bodyOrderDispute);
          setIsLoading(false);
          alert(
            "There was an error processing your payment. If money has been debited from your account, we will refund within 7 days"
          );
        }
      } else {
        const bodyOrderDispute = {
          paymentMethod: "paypal",
          amount: total,
          serviceCharge: "",
          currency: "USD",
          paymentId: details?.purchase_units[0].payments?.captures[0].id,
          paypalPaymentModeWeb: true,
        };
        handleOrderDispute(bodyOrderDispute);
        setIsLoading(false);
        alert(
          "There was an error happened in server, please try again! If money has been debited from your account, we will refund within 7 days"
        );
      }
    } else {
      alert("There was an error processing your payment, please try again!");
    }
    setIsLoading(false);
    // setIsPaymentLoading(false);
  };

  const handleOrderDispute = async (data) => {
    const [refund_res, refund_err] = await GenericPostApiCall(
      apiUrls.RequestManualRefund,
      data
    );
    // console.log("refund_res = ", refund_res, "refund_err = ", refund_err);
    if (refund_res?.code === 200) {
      // console.log("req-rf-post-api processed");
    } else {
      // console.log("error in req-rf-post-api. details = ", refund_err);
    }
  };

  let paypalBtnElem = document.getElementById("submit-button");
  if (paypalBtnElem !== null) {
    paypalBtnElem.onChange(() => {
      setIsPaymentLoading(true);
    });
  }

  const handlePaymentError = (error) => {
    setIsPaymentLoading(false);
    console.error("Payment error!, details = ", error);
    alert("There was an error processing your payment, please try again!");
  };

  // const handlePaymentBtnClick = () => {
  //   setIsPaymentLoading(true);
  // };

  const handlePaymentCancel = () => {
    setIsPaymentLoading(false);
    alert("This payment is cancelled! please try again");
  };

  // get PayPal Client ID
  const GetPaypalClientID = async () => {
    const url = apiUrls.GetPayPalClientId;
    const [response, error] = await GenericGetApis(url);
    if (error) {
      // console.log("err in pay-che-frm-get-cli");
    }
    if (response?.code === 200) {
      setClientId(response.client_id);
      setPayPalFixedFee(response.PAYPAL_FIXED_CHARGE_IN_USD);
      setPayPalPayInPercentage(response.PAYPAL_PAY_IN_PERCENTAGE);
    } else {
      // console.log(error, "coming from add setRecentVideoData  on profile");
      setClientId(null);
    }
  };

  // Custom component to wrap the PayPalButtons and show loading spinner
  const ButtonWrapper = ({ showSpinner }) => {
    const [{ isPending, isInitial }] = usePayPalScriptReducer();

    return (
      <>
        {(showSpinner || isPending || isInitial) && (
          <div className="text-center">
            <Spinner animation="grow" role="status" variant="light">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <PayPalButtons
          createOrder={(data, actions) => {
            // console.log("actions before = ", actions);
            return actions.order.create({
              intent: "CAPTURE",
              purchase_units: [
                {
                  amount: {
                    value: total,
                  },
                  description: `Payment for iShout personalized video from ${celebrityName}`,
                },
              ],
              application_context: {
                shipping_preference: "NO_SHIPPING",
                user_action: "PAY_NOW",
                locale: "en",
              },
            });
          }}
          onApprove={(data, actions) => {
            // console.log("on approve request");
            // console.log("after data = ", data);
            // console.log("after actions = ", actions);
            return actions.order.capture().then((details) => {
              handleBookingOnSubmit(details);
            });
          }}
          onError={handlePaymentError} // Callback function when an error occurs
          onCancel={handlePaymentCancel} // Callback function when an error occurs
          style={{ shape: "pill", color: "blue", label: "pay" }}
        />
      </>
    );
  };

  // Calculate platform fee
  const calculatePlatformFee = (amount) => {
    // ref: https://www.paypal.com/us/webapps/mpp/merchant-fees
    // PayPal fixed fee for USD is 0.3$
    // Percentage for both domestic and international transaction for Bahrain is 4.9%

    if (amount) {
      const calculated =
        amount * parseFloat(PayPalPayInPercentage) + parseFloat(PayPalFixedFee);
      return Math.round(calculated * 100) / 100;
    } else {
      // console.log("Unable to process the request!");
      return null;
    }
  };

  return (
    <div
      className={
        isPaymentLoading ? "paymentContainer overlayDiv" : "paymentContainer"
      }
    >
      {isLoading || !clientId ? (
        <SpinnerComponent />
      ) : (
        <>
          <Container>
            <div className="payment-main-all-wrpr">
              {/* <Row className="mb-3 justify-content-center">
                  <Col lg={7} md={8} sm={10} xs={12}>
                    <div className="d-flex">
                      <Link to={navigate("/")}>
                        <IoArrowBack style={{ color: "#FFF" }}></IoArrowBack>
                        <span
                          className="text-white"
                          style={{
                            paddingLeft: "5px",
                          }}
                        >
                          Back
                        </span>
                      </Link>
                    </div>
                  </Col>
                </Row> */}
              <Row className="justify-content-center">
                <Col lg={7} md={8} sm={10} xs={12}>
                  <div className="newCardWrpsPay px-4">
                    {/*  */}
                    <div className="pay-1-wrps-list">
                      <div className="pay-card-heading">Delivery info</div>
                      <div className="radio-btn-sections">
                        <div className="radio-btn-wrprs">
                          <Form.Check
                            type={"radio"}
                            name="radioGroup"
                            value="normal"
                            checked={selectedDeliveryInfoValue === "normal"}
                            onChange={handleRadioChange}
                          />
                        </div>
                        <div className="sec-pay-ll">
                          <div className="sec-pat-ss-ll">
                            Standard - ${bookingCost?.amount}
                          </div>
                          <div className="ss-pay-days">Up to 7 days</div>
                        </div>
                      </div>

                      {fastDelivery && (
                        <>
                          <div className="p-16s" />
                          <div className="radio-btn-sections">
                            <div className="radio-btn-wrprs">
                              <Form.Check
                                type={"radio"}
                                name="radioGroup"
                                value="fast"
                                checked={selectedDeliveryInfoValue === "fast"}
                                onChange={handleRadioChange}
                              />
                            </div>
                            <div className="sec-pay-ll">
                              <div className="sec-pat-ss-ll">
                                24hr Delivery - $
                                {bookingCostFastDelivery?.amount}
                              </div>
                              <div className="ss-pay-days">Up to 24 hours</div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <div className="pay-card-heading1">Summary</div>
                      <div className="sep-line-12"></div>
                      <div className="summary-wrprs">
                        <div className="summary-names">
                          iShout personalized video
                        </div>
                        <div className="summary-names">{`$ ${
                          selectedDeliveryInfoValue === "normal"
                            ? bookingCost.amount
                            : bookingCostFastDelivery.amount
                        }`}</div>
                      </div>
                      <div style={{ height: 15 }}></div>
                      <div className="summary-wrprs">
                        <div className="summary-names">Service charge</div>
                        <div className="summary-names">{`$ ${
                          selectedDeliveryInfoValue === "normal"
                            ? calculatePlatformFee(bookingCost.amount)
                            : calculatePlatformFee(
                                bookingCostFastDelivery.amount
                              )
                        }`}</div>
                      </div>
                      <div style={{ height: 15 }}></div>
                      <div className="summary-wrprs">
                        <div className="summary-names">Total amount to pay</div>
                        <div className="summary-names">{`$ ${total}`}</div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="h-50s" />

                    {/* <button
                      disabled={isLoading}
                      className="-btn-books-now"
                      to={"/payment-gateway"}
                      onClick={handleBookingOnSubmit}
                    >
                      {!isLoading ? (
                        `Verify & Pay`
                      ) : (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      )}
                    </button> */}
                    {/* {paypal start} */}
                    <div
                      className="justify-content-center pt-4 pb-2 px-2"
                      style={{
                        backgroundColor: "#505050",
                        borderRadius: 16,
                        boxShadow:
                          "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                      }}
                    >
                      <PayPalScriptProvider
                        options={{
                          "client-id": clientId,
                          components: "buttons",
                          intent: "capture",
                          currency: "USD",
                        }}
                      >
                        <ButtonWrapper showSpinner={false} />
                      </PayPalScriptProvider>
                    </div>
                    {/* {paypal end} */}

                    <div className="text-center mt-3">
                      <Link to={"/"} className="btn btn-link paymentCancelBtn">
                        I need to cancel and back to home
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};

export default PaymentPage;
