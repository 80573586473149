import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { MyContext } from "../Store";

const PrivateRoutes = () => {
  // useContext
  const { authToken } = useContext(MyContext);
  const [token] = authToken;
  let auth = { token: token ? true : false };

  return auth.token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
