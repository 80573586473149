import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  CelebrityCardForGrid,
  Footer,
  Navbar,
  PageHeading,
} from "../../Component";
import "./Styles.css";
import { Link, useParams } from "react-router-dom";
import { GenericGetApisExtraParams } from "../../Services";
import { useEffect } from "react";
import { useState } from "react";
import * as Urls from "../../constants/ApiConstants";
import SpinnerComponent from "../../Component/Spinner";

function makeTitle(slug) {
  var words = slug.split("-");

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ");
}

const MainCategoryData = () => {
  // params
  const { key } = useParams();

  //   // useState
  const [allCelebrityData, setAllCelebrityData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  let isViewAll = false;

  if (key === "view-all") {
    isViewAll = true;
  }

  // useEffect
  useEffect(() => {
    fetchCelebrityList();
  }, []);

  const fetchCelebrityList = async () => {
    setIsLoading(true);
    const params = { key: key };
    const url = Urls.GetAllMainCategoryDataByKey;
    const [res, error] = await GenericGetApisExtraParams(url, params);
    if (res?.code === 200) {
      setAllCelebrityData(res?.data);
    } else {
      // console.log(
      //   "error happened in get all main category api, details = ",
      //   error
      // );
    }
    setIsLoading(false);
  };

  return (
    <div className="homeContainer">
      <Navbar />
      <Container className="mt-4" style={{ minHeight: "90vh" }}>
        {isLoading ? (
          <SpinnerComponent />
        ) : (
          <>
            <PageHeading
              title={!isViewAll ? makeTitle(key) : "All Categories"}
            />
            <div className="mnCtDaLs mb-5">
              {allCelebrityData?.map((i, index) => (
                <div className="" key={index}>
                  <Link to={`/celeb/${i.username}`}>
                    <CelebrityCardForGrid data={i} />
                  </Link>
                </div>
              ))}
            </div>
          </>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default MainCategoryData;
