import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { Footer, Navbar } from "../../Component";
import "./styles.css";
import { useForm } from "react-hook-form";
import { GenericGetApisNoToken, GenericPostApiNoAuth } from "../../Services";
import * as Urls from "../../constants/ApiConstants";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import axios from "axios";

const socialMedia = [
  { label: "Instagram", value: "Instagram" },
  { label: "Facebook", value: "Facebook" },
  { label: "TikTok", value: "TikTok" },
  { label: "YouTube", value: "YouTube" },
  { label: "Twitter", value: "Twitter" },
  { label: "Snapchat", value: "Snapchat" },
  { label: "Twitch", value: "Twitch" },
  { label: "LinkedIn", value: "LinkedIn" },
];

const followers = [
  { label: "<10k", value: "0" },
  { label: "10k - 30k", value: "10000" },
  { label: "30k - 100k", value: "30000" },
  { label: "100k - 300k", value: "100000" },
  { label: "300k - 1m", value: "300000" },
  { label: "1m - 3m", value: "1000000" },
  { label: "3m - 10m", value: "3000000" },
  { label: "10m+", value: "10000000" },
];

const EnrollForm = () => {
  // const [successMesage, setSuccessMesage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [CountryData, setCountryData] = useState([]);
  // const [selectedOption, setSelectedOption] = useState(null);
  const [phoneCode, setMobileNumber] = useState("");
  const [phoneCodeCountryCode, setCountryCode] = useState("");
  const [defaultCountryCode, setDefaultCountry] = useState("");
  const [isFoundDefaultCountry, setDefaultCountryFlag] = useState(false);

  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  // useEffect
  useEffect(() => {
    getCountryList();
    getGeoInfo();
  }, []);

  const handleChange = (phoneNumber) => {
    if (phoneNumber?.length > 1) {
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
      // console.log(parsedPhoneNumber);
      if (parsedPhoneNumber) {
        setMobileNumber(parsedPhoneNumber.nationalNumber);
        setCountryCode(parsedPhoneNumber.countryCallingCode);
      }
    }
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        if (data) {
          setDefaultCountryFlag(true);
          setDefaultCountry({
            countryName: data.country_name,
            countryCallingCode: data.country_calling_code,
            countryCode: data.country_code,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const url = Urls.CelebrityEnroll;
    const body = {
      mobile: "" + phoneCode,
      firstName: data.firstName,
      lastName: data.lastName,
      country: data?.country,
      email: data.email,
      platform: data.socialMediaField,
      socialId: data.handle,
      followers: data.followers,
      about: "",
      bio: data.message,
      intro: "",
      countryCode: `+${phoneCodeCountryCode}`,
    };
    // console.log(body);

    const [res, error] = await GenericPostApiNoAuth(url, body);
    // console.log(res, error);
    if (res?.code === 200) {
      reset();
      alert(res?.detail);
    } else {
      // console.log("error coming from enroll submit", error);
      alert(error?.detail ? error?.detail : "Try again later");
    }
    setIsLoading(false);
  };

  // Email validation regex pattern
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  const getCountryList = async () => {
    const url = Urls.GetAllCountries;
    const [res, error] = await GenericGetApisNoToken(url);
    if (res) {
      setCountryData(res.data);
    } else {
      // console.log("error coming from country modal api ===>", error);
    }
  };

  return (
    <div className="contactContainer">
      <Navbar />
      <Container>
        <div className="contactUsContainerInner">
          <Row className="justify-content-center">
            <Col xl={6} lg={8} md={12}>
              <div className="formwrpr1">
                <div className="heade-cc-styles pb-1">Apply as talent</div>
                <div className="subhead-para-details">
                  If you have fan base and look to join iShout as talent, you
                  can apply here, and we'll be in touch with you soon.
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg={6} md={6} sm={6} className="mb-3">
                      <>
                        <div className="pfa-a-heading1">First name</div>
                        <Form.Control
                          placeholder="John"
                          className={
                            errors.firstName
                              ? "custom-pfa-class-style errorBoarder"
                              : "custom-pfa-class-style"
                          }
                          {...register("firstName", { maxLength: 100 })}
                        />
                        {errors.firstName && (
                          <div className="errorText">
                            Text length is limited to 100 characters
                          </div>
                        )}
                      </>
                    </Col>
                    <Col lg={6} md={6} sm={6} className="mb-3">
                      <>
                        <div className="pfa-a-heading1">Last name*</div>
                        <Form.Control
                          type="text"
                          placeholder="Doe"
                          className={
                            errors.lastName
                              ? "custom-pfa-class-style errorBoarder"
                              : "custom-pfa-class-style"
                          }
                          {...register("lastName", {
                            required: {
                              value: true,
                              message: "Required",
                            },
                            maxLength: {
                              value: 100,
                              message:
                                "Text length is limited to 100 characters",
                            },
                          })}
                        />
                        {errors.lastName && (
                          <div className="errorText">
                            {errors.lastName.message}
                          </div>
                        )}
                      </>
                    </Col>
                  </Row>
                  <>
                    <div className="pfa-a-heading1">Email*</div>
                    <Form.Control
                      placeholder="you@example.com"
                      type="email"
                      className={
                        errors.email
                          ? "custom-pfa-class-style errorBoarder"
                          : "custom-pfa-class-style"
                      }
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Required",
                        },
                        maxLength: {
                          value: 100,
                          message: "Text length is limited to 100 characters",
                        },
                        pattern: {
                          value: emailRegex,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <div className="errorText">{errors.email.message}</div>
                    )}
                  </>
                  <div style={{ height: 24 }} />
                  <>
                    <div className="pfa-a-heading1">Phone number*</div>

                    <div className="phone-number-wrpr">
                      <PhoneInputWithCountry
                        withCountryCallingCode={true}
                        name="phoneInputWithCountrySelect"
                        control={control}
                        rules={{ required: true, maxLength: 20 }}
                        value={phoneCode}
                        onChange={handleChange}
                        placeholder="Enter Your Number"
                        containerclass="my-container-class"
                        inputclass="my-input-class"
                        containerstyle={{
                          border: "10px solid black",
                        }}
                        inputstyle={{
                          background: "lightblue",
                        }}
                        defaultCountry={
                          isFoundDefaultCountry
                            ? defaultCountryCode.countryCode
                            : "AE"
                        }
                      />
                    </div>
                    {errors.phoneInputWithCountrySelect && (
                      <div className="errorText">Required</div>
                    )}
                  </>
                  <div style={{ height: 24 }} />
                  <>
                    <div className="pfa-a-heading1">Country*</div>
                    <Form.Select
                      defaultValue={""}
                      aria-label="Default select example"
                      className={
                        errors.country
                          ? "form-select-custm-class errorBoarder"
                          : "form-select-custm-class"
                      }
                      {...register("country", { required: true })}
                    >
                      <option value={""} disabled></option>
                      {CountryData?.map((i, index) => (
                        <option value={i.id} key={index}>
                          {i.name}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.country && (
                      <div className="errorText">Required</div>
                    )}
                  </>
                  <div style={{ height: 24 }} />
                  <>
                    <div className="pfa-a-heading1">
                      Where can we find you?*
                    </div>
                    <Form.Select
                      aria-label="Default select example"
                      defaultValue={""}
                      className={
                        errors.socialMediaField
                          ? "form-select-custm-class errorBoarder"
                          : "form-select-custm-class"
                      }
                      {...register("socialMediaField", { required: true })}
                    >
                      <option value={""} disabled></option>
                      {socialMedia?.map((i, index) => (
                        <option value={i.value} key={index}>
                          {i.label}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.socialMediaField && (
                      <div className="errorText">Required</div>
                    )}
                  </>
                  <div style={{ height: 24 }} />
                  <>
                    <div className="pfa-a-heading1">Your handle*</div>
                    <Form.Control
                      placeholder="@john_doe"
                      className={
                        errors.handle
                          ? "custom-pfa-class-style errorBoarder"
                          : "custom-pfa-class-style"
                      }
                      {...register("handle", {
                        required: true,
                      })}
                    />
                    {errors.handle && <div className="errorText">Required</div>}
                  </>
                  <div style={{ height: 24 }} />
                  <>
                    <div className="pfa-a-heading1">
                      How many followers do you have?*
                    </div>
                    <Form.Select
                      aria-label="Default select example"
                      defaultValue={""}
                      className={
                        errors.followers
                          ? "form-select-custm-class errorBoarder"
                          : "form-select-custm-class"
                      }
                      {...register("followers", { required: true })}
                    >
                      <option value={""} disabled></option>
                      {followers?.map((i, index) => (
                        <option value={i.value} key={index}>
                          {i.label}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.followers && (
                      <div className="errorText">Required</div>
                    )}
                  </>
                  <div style={{ height: 24 }} />
                  <>
                    <div className="pfa-a-heading1">
                      Anything else we should know about you?
                    </div>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Message"
                      className={
                        errors.message
                          ? "custom-pfa-class-style-multi errorBoarder"
                          : "custom-pfa-class-style-multi"
                      }
                      {...register("message", { required: false })}
                    />
                    {errors.message && (
                      <div className="errorText">Required</div>
                    )}
                  </>

                  <div style={{ height: 24 }} />

                  <div className="subhead-para-details">
                    By applying to enroll as talent on iShout, you agree to
                    iShout's <br />
                    <Link to={"/terms"}>Terms of Service</Link> and &nbsp;
                    <Link to={"/privacy"}>Privacy Policy</Link>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      disabled={isLoading}
                      className="c-f-s-btn"
                      type="submit"
                      style={
                        isLoading
                          ? { background: "gray" }
                          : { background: "#0d61d3" }
                      }
                    >
                      {isLoading ? <Spinner size="sm" /> : "Submit"}
                    </button>
                  </div>
                  {/* <div style={{ height: 30 }} />
                  <div className="subhead-para-details-bottom">
                    you are not automatically enrolled on iShout. If you meet
                    the eligibility requirements, a talent representative will
                    contact you within a few days to finish on-boarding.
                  </div> */}
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default EnrollForm;
