import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";

import { Footer, Navbar, CodeVerification } from "../../Component";
import * as Urls from "../../constants/ApiConstants";
import { GenericPostApiCall } from "../../Services";

import "./Styles.css";

const ChangeMobileConfirm = () => {
  // useLocation
  const location = useLocation();
  const { value, code, action } = location.state;

  const [verificationCode, setVerificationCode] = useState(code);
  const [isLoading, setIsLoading] = useState(false);

  const body = {
    mobile: value,
    purpose: action,
  };

  const resendCodeLinkComponent = async () => {
    setIsLoading(true);
    if (value) {
      const url = Urls.ClientChangeMobile;
      const body = {
        mobile: value,
      };
      const [res, error] = await GenericPostApiCall(url, body);
      // console.log(res, error);
      if (res?.code === 200) {
        setVerificationCode(res?.otp);
      } else {
        if (error?.detail) {
          alert(error?.detail);
        } else {
          alert(
            "Attention! we are unable to complete the request, please try again"
          );
        }
      }
    } else {
      alert(
        "Attention! we can't process your request, please make sure you have entered valid email id"
      );
    }
    setIsLoading(false);
  };

  return (
    <div className="homeContainer">
      <Navbar />
      <Container style={{ minHeight: "90vh" }}>
        <div className="mt-0 mt-md-5">
          <Col lg={6} md={8} sm={10}>
            <CodeVerification
              title={value}
              resendLink={resendCodeLinkComponent}
              otp={verificationCode}
              goto={Urls.clientMobileVerified}
              body={body}
              isLoadingData={isLoading}
            ></CodeVerification>
          </Col>
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default ChangeMobileConfirm;
