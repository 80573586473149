import React, { useState, useEffect } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Footer, Navbar, Spinner } from "../../Component";
import Icons from "../../Icons";
import { GenericGetApis } from "../../Services";
import * as apiUrls from "../../constants/ApiConstants";
import CelebrityDefault from "../../Assets/CelebrityDefault.jpg";

import "./Styles.css";

const Order = () => {
  // useNavigate
  const navigate = useNavigate();

  // useState
  const [orderData, setOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openOrders, setOpenOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);

  // useEffect
  useEffect(() => {
    fetchOrdersData();
  }, []);

  const fetchOrdersData = async () => {
    setIsLoading(true);
    const url = apiUrls?.getAllOrders;

    const [res, error] = await GenericGetApis(url);
    if (res?.code === 200) {
      // console.log(res);
      setOrderData(res?.order);
      setOpenOrders(res?.order.filter((order) => order.status === "Open"));
      setCompletedOrders(
        res?.order.filter((order) => order.status === "Completed")
      );
    } else {
      // console.log("fetchOrdersData", error);
    }
    setIsLoading(false);
  };

  const handleAllOrderTabNavigation = (i) => {
    // console.log(i);
    if (i.status === "Completed") {
      navigate("/order-details", {
        state: {
          orderData: i,
        },
      });
    } else if (i.status === "Open" || i.status === "Accept") {
      if (i?.shoutOutPurpose === "personal") {
        navigate("/edit-personal-order", {
          state: {
            orderData: i,
          },
        });
      } else {
        navigate("/edit-business-order", {
          state: {
            orderData: i,
          },
        });
      }
    } else {
      alert(
        "Attention! Your order has expired. You will receive refund to your original payment mode. If you haven't received the refund, kindly contact our customer support."
      );
    }
  };

  return (
    <div className="containerWrprs">
      <Navbar />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Container>
            <div className="order_main_wrpr">
              <Tabs
                defaultActiveKey="All"
                id="uncontrolled-tab-example"
                className="mb-4 my-custom-tab-styles"
              >
                <Tab eventKey="All" title="All" as={"div"}>
                  {orderData.length > 0 ? (
                    orderData.map((i, index) => (
                      <div key={index}>
                        <button
                          className="tab-all-wrpr"
                          onClick={() => handleAllOrderTabNavigation(i)}
                        >
                          <div className="first-tab-sec">
                            <div>
                              <div className="tab-avatar-img">
                                <img
                                  src={
                                    i.celebrityPhoto
                                      ? i.celebrityPhoto
                                      : CelebrityDefault
                                  }
                                  alt={`${i?.firstName} ${i?.name}`}
                                  className="w-100"
                                />
                              </div>
                            </div>
                            <div className="orderDetailsData">
                              <div className="orderDetailsName">
                                {i?.celebrityFirstName} {i.celebrity}
                              </div>
                              <div className="orderPurposeText">
                                {i?.shoutOutPurpose === "business"
                                  ? i?.occasion
                                    ? `Personalized video for business ${i?.businessName} for the ${i?.occasion.name}`
                                    : `Personalized video for business ${i?.businessName}`
                                  : i?.occasion
                                  ? `Personalized video for ${i?.shoutOutTo} for the ${i?.occasion.name}`
                                  : `Personalized video for ${i?.shoutOutTo}`}
                              </div>
                              <div className="orderDetailsSubTitle">
                                {i?.instruction}
                              </div>
                              {i.status === "Open" && (
                                <div className="daysLeft-bottom">
                                  {i.timeLeft} left
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="tab-se-sections">
                            {i.status === "Open" && (
                              <div className="daysLeft-left">
                                {i.timeLeft} left
                              </div>
                            )}
                            <div
                              className={
                                i.status === "Open" || i.status === "Accept"
                                  ? "pengingStyles"
                                  : i.status === "Completed"
                                  ? "completedStyles"
                                  : "expiresStyle"
                              }
                            >
                              {i?.status}
                            </div>
                            {i.status === "Open" && (
                              <Icons
                                icon="pencil"
                                size={20}
                                color={"#928F8F"}
                              />
                            )}
                          </div>
                        </button>
                        <div style={{ height: 16 }} />
                      </div>
                    ))
                  ) : (
                    <div className="tab-all-wrpr -an-no-o-s">No orders</div>
                  )}
                </Tab>
                <Tab eventKey="Open" title="Open">
                  {openOrders.length > 0 ? (
                    openOrders.map((i, index) => (
                      <div key={index}>
                        <button
                          className="tab-all-wrpr"
                          onClick={() => handleAllOrderTabNavigation(i)}
                        >
                          <div className="first-tab-sec">
                            <div>
                              <div className="tab-avatar-img">
                                <img
                                  src={
                                    i.celebrityPhoto
                                      ? i.celebrityPhoto
                                      : CelebrityDefault
                                  }
                                  alt={`${i?.firstName} ${i?.name}`}
                                  className="w-100"
                                />
                              </div>
                            </div>
                            <div className="orderDetailsData">
                              <div className="orderDetailsName">
                                {i?.celebrityFirstName} {i.celebrity}
                              </div>
                              <div className="orderPurposeText">
                                {i?.shoutOutPurpose === "business"
                                  ? i?.occasion
                                    ? `Personalized video for business ${i?.businessName} for the ${i?.occasion.name}`
                                    : `Personalized video for business ${i?.businessName}`
                                  : i?.occasion
                                  ? `Personalized video for ${i?.shoutOutTo} for the ${i?.occasion.name}`
                                  : `Personalized video for ${i?.shoutOutTo}`}
                              </div>
                              <div className="orderDetailsSubTitle">
                                {i?.instruction}
                              </div>
                              {i.status === "Open" && (
                                <div className="daysLeft-bottom">
                                  {i.timeLeft} left
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="tab-se-sections">
                            {i.status === "Open" && (
                              <div className="daysLeft-left">
                                {i.timeLeft} left
                              </div>
                            )}
                            <div
                              className={
                                i.status === "Open"
                                  ? "pengingStyles"
                                  : i.status === "Completed"
                                  ? "completedStyles"
                                  : "expiresStyle"
                              }
                            >
                              {i?.status}
                            </div>
                            {i.status === "Open" && (
                              <Icons
                                icon="pencil"
                                size={20}
                                color={"#928F8F"}
                              />
                            )}
                          </div>
                        </button>
                        <div style={{ height: 16 }} />
                      </div>
                    ))
                  ) : (
                    <div className="tab-all-wrpr -an-no-o-s">No orders</div>
                  )}
                </Tab>
                <Tab eventKey="Complete" title="Completed">
                  {completedOrders.length > 0 ? (
                    completedOrders.map((i, index) => (
                      <div key={index}>
                        <button
                          className="tab-all-wrpr"
                          onClick={() => handleAllOrderTabNavigation(i)}
                        >
                          <div className="first-tab-sec">
                            <div>
                              <div className="tab-avatar-img">
                                <img
                                  src={
                                    i.celebrityPhoto
                                      ? i.celebrityPhoto
                                      : CelebrityDefault
                                  }
                                  alt={`${i?.firstName} ${i?.name}`}
                                  className="w-100"
                                />
                              </div>
                            </div>
                            <div className="orderDetailsData">
                              <div className="orderDetailsName">
                                {i?.celebrityFirstName} {i.celebrity}
                              </div>
                              <div className="orderPurposeText">
                                {i?.shoutOutPurpose === "business"
                                  ? i?.occasion
                                    ? `Personalized video for business ${i?.businessName} for the ${i?.occasion.name}`
                                    : `Personalized video for business ${i?.businessName}`
                                  : i?.occasion
                                  ? `Personalized video for ${i?.shoutOutTo} for the ${i?.occasion.name}`
                                  : `Personalized video for ${i?.shoutOutTo}`}
                              </div>
                              <div className="orderDetailsSubTitle">
                                {i?.instruction}
                              </div>
                              {i.status === "Open" && (
                                <div className="daysLeft-bottom">
                                  {i.timeLeft} left
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="tab-se-sections">
                            {i.status === "Open" && (
                              <div className="daysLeft-left">
                                {i.timeLeft} left
                              </div>
                            )}
                            <div
                              className={
                                i.status === "Open"
                                  ? "pengingStyles"
                                  : i.status === "Completed"
                                  ? "completedStyles"
                                  : "expiresStyle"
                              }
                            >
                              {i?.status}
                            </div>
                            {i.status === "Open" && (
                              <Icons
                                icon="pencil"
                                size={20}
                                color={"#928F8F"}
                              />
                            )}
                          </div>
                        </button>
                        <div style={{ height: 16 }} />
                      </div>
                    ))
                  ) : (
                    <div className="tab-all-wrpr -an-no-o-s">No orders</div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </Container>
        </>
      )}
      <Footer />
    </div>
  );
};

export default Order;
