// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  FacebookAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBXUU0tFxPD_xJsSs3u4vArGyeo8vr1SHQ",
  authDomain: "ishout-dccc7.firebaseapp.com",
  projectId: "ishout-dccc7",
  storageBucket: "ishout-dccc7.appspot.com",
  messagingSenderId: "215558318685",
  appId: "1:215558318685:web:4ca46bb4f8e581ed6cbbb4",
  measurementId: "G-38NG2BPLC3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const fbProvider = new FacebookAuthProvider();
