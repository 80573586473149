import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationRouter from "./Navigation";
import Context from "./Store";

const App = () => {
  return (
    <Context>
      <NavigationRouter />
    </Context>
  );
};

export default App;
