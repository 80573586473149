import React, { useState } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { Footer, Navbar } from "../../Component";
import "./styles.css";
import { useForm } from "react-hook-form";
import { GenericPostApiNoAuth } from "../../Services";
import * as Urls from "../../constants/ApiConstants";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    const url = Urls.Contact;
    const body = {
      name: data.name,
      email: data.email,
      message: data.message,
    };
    const [res, error] = await GenericPostApiNoAuth(url, body);
    setIsLoading(false);
    if (res.code === 200) {
      alert(res.detail);
      reset();
    } else {
      alert(error.detail);
    }
  };

  // Email validation regex pattern
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  return (
    <div className="contactContainer">
      <Navbar />
      <Container>
        <div className="contactUsContainerInner">
          <Row className="justify-content-center">
            <Col lg={6} md={7} sm={10}>
              <div className="formwrpr">
                <div className="heade-cc-styles">Contact iShout</div>
                <div className="subhead-para-details">
                  Contact us for all of your enquiries
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <>
                    <div className="pfa-a-heading1">What is your name?</div>
                    <Form.Control
                      placeholder="Enter your name"
                      className={
                        errors.name
                          ? "custom-pfa-class-style errorBoarder"
                          : "custom-pfa-class-style"
                      }
                      {...register("name", { required: true })}
                    />
                    {errors.name && <div className="errorText">Required</div>}
                  </>

                  <div style={{ height: 18 }} />
                  <>
                    <div className="pfa-a-heading1">
                      What is your email address?
                    </div>
                    <Form.Control
                      placeholder="Enter your email address"
                      className={
                        errors.email
                          ? "custom-pfa-class-style errorBoarder"
                          : "custom-pfa-class-style"
                      }
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Required",
                        },
                        pattern: {
                          value: emailRegex,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <div className="errorText">{errors.email.message}</div>
                    )}
                  </>
                  <div style={{ height: 18 }} />
                  <>
                    <div className="pfa-a-heading1">Your message</div>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Message"
                      className={
                        errors.message
                          ? "custom-pfa-class-style-multi errorBoarder"
                          : "custom-pfa-class-style-multi"
                      }
                      {...register("message", { required: true })}
                    />
                    {errors.message && (
                      <div className="errorText">Required</div>
                    )}
                  </>
                  <div style={{ height: 24 }} />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      disabled={isLoading}
                      className="c-f-s-btn"
                      type="submit"
                    >
                      {isLoading ? <Spinner size="sm" /> : "Submit"}
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default ContactUs;
