import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import success from "../../Assets/success.svg";
import SpinnerComponent from "../../Component/Spinner";
import "./styles.css";

const Success = () => {
  const { Id } = useParams();

  // useState
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // useEffect
  useEffect(() => {
    setIsInitialLoading(false);
  }, []);

  return (
    <div className="mainContainer">
      {isInitialLoading ? (
        <SpinnerComponent />
      ) : (
        <Container>
          <div className="cardAllWrpr">
            <Row className="justify-content-center">
              <Col lg={7} md={8} sm={10} xs={12}>
                <div className="successCardWrpr">
                  <div className="img_s_p_wrprs">
                    <img src={success} alt="success" className="w-100" />
                  </div>
                  <div className="ps_txts">Video order successfully placed</div>
                  <div className="orderIDs">Your order ID #{Id}</div>
                  <div className="desSuccessPage">
                    Hooray! Your payment has been successfully completed. Order
                    details will be sent to Celebrity and it is expected to be
                    delivered soon. If your order is not accepted, your amount
                    will be refunded to the same payment method.
                  </div>
                  <Link to={"/"} className="linkGoBtn">
                    <div className="s_p_btn">Go Home</div>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </div>
  );
};

export default Success;
