import React from "react";
import One from "./1.png";
import Two from "./02.png";
import Three from "./03.png";
import Four from "./04.png";
import "./Styles.css";
import { Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";

const HIT_Item = [
  {
    id: 1,
    image: One,
    title: "Find a celebrity",
    subtitle: `Browse through a wide selection of celebrities offering personalized videos`,
  },
  {
    id: 2,
    image: Two,
    title: `Tell them what to say`,
    subtitle: `During checkout, you'll provide the details the celeb will need to make the perfect personalized video.`,
  },
  {
    id: 3,
    image: Three,
    title: `Get your video`,
    subtitle: `Celebs have up to 7 days to complete your request. When it's ready, we'll send it directly to you.`,
  },
  {
    id: 4,
    image: Four,
    title: `Share with loved ones`,
    subtitle: `Send the video to friends and family and don't forget to capture their priceless reactions.`,
  },
];

const HowItWorks = () => {
  return (
    <div className="hit-main-wrpr">
      <Row>
        {HIT_Item?.map((i, index) => (
          <Col
            lg={3}
            sm={6}
            xs={12}
            className="mb-md-3 mb-4 mb-lg-0"
            key={index}
          >
            <div className="hit-card-wrpr">
              <img src={i.image} alt="iShout" />
              <div style={{ height: 16 }} />
              <div className="hiw-heading">{i.title}</div>
              <div className="hiw-para">{i?.subtitle}</div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default HowItWorks;
