import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../Pages/Home";
import Profile from "../Pages/EditProfile";
import ViewAllPage from "../Pages/ViewAllPage";
import FavoriteList from "../Pages/FavouriteList";
import PersonalBookingForm from "../Pages/PersonalBookingForm";
import PaymentPage from "../Pages/PaymentPage";
import Login from "../Pages/Login";
import CelebrityDetails from "../Pages/CelebrityDetails";
import ProfileHome from "../Pages/ProfileHome";
import ChangeEmail from "../Pages/ChangeEmail";
import ChangeEmailConfirm from "../Pages/ChangeEmail/change_email_confirm";
import ChangeMobile from "../Pages/ChangeMobileNumber";
import ChangeMobileConfirm from "../Pages/ChangeMobileNumber/change_mobile_confirm";
import ChangePassword from "../Pages/ChangePassword";
import {
  BusinessBookingForm,
  Registration,
  CelebrityList,
  PaymentSuccessPage,
  OrderHome,
  OrderInnerPage,
  VideoPlayer,
  PaymentGateway,
  BusinessOrderPage,
  PersonalOrderEdit,
  BusinessOrderEdit,
  Privacy,
  Terms,
  HowItWorks,
  ContactUs,
  EnrollForm,
  Search,
  ForgotPassword,
  MainCategoryData,
  PageNotFound,
  PrivacyForMobile,
  TermsForMobile,
  MemorableDays,
} from "../Pages";
import PrivateRoutes from "./PrivateRoutes";

const AppStack = () => {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route path="/" element={<Home />} exact />
      <Route path="/view-all" element={<ViewAllPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/celeb/:username" element={<CelebrityDetails />} />
      <Route path="/booking-business" element={<BusinessBookingForm />} />
      <Route path="/register" element={<Registration />} />
      <Route path="/celebrities/:key" element={<CelebrityList />} />
      <Route path="/browse/:key" element={<MainCategoryData />} />
      <Route path="/video-player" element={<VideoPlayer />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/how-it-works" element={<HowItWorks />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/enroll" element={<EnrollForm />} />
      <Route path="/search" element={<Search />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route
        path="/privacy/f66a6629-12b0-4fef-a2e8-ef3c26a6021f"
        element={<PrivacyForMobile />}
      />
      <Route
        path="/terms/f66a6629-12b0-4fef-a2e8-ef3c26a6021f"
        element={<TermsForMobile />}
      />

      <Route element={<PrivateRoutes />}>
        <Route path="/orders" element={<OrderHome />} />
        <Route path="/edit-profile" element={<Profile />} />
        <Route path="/favorites" element={<FavoriteList />} />
        <Route path="/booking-personal" element={<PersonalBookingForm />} />
        <Route path="/make-payment" element={<PaymentPage />} />
        <Route path="/profile" element={<ProfileHome />} />
        <Route path="/payment-success/:Id" element={<PaymentSuccessPage />} />
        <Route path="/payment-gateway" element={<PaymentGateway />} />
        <Route path="/business-order" element={<BusinessOrderPage />} />
        <Route path="/edit-personal-order" element={<PersonalOrderEdit />} />
        <Route path="/edit-business-order" element={<BusinessOrderEdit />} />
        <Route path="/order-details" element={<OrderInnerPage />} />
        <Route path="/email/:action" element={<ChangeEmail />} />
        <Route path="/confirm-email" element={<ChangeEmailConfirm />} />
        <Route path="/mobile/:action" element={<ChangeMobile />} />
        <Route path="/confirm-mobile" element={<ChangeMobileConfirm />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/memorable-days" element={<MemorableDays />} />
      </Route>
    </Routes>
  );
};

export default AppStack;
