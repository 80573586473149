import React, { useContext, useEffect, useState, useTransition } from "react";
import { Dropdown, Form, Row, Container, CloseButton } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { BsXLg, BsSearch } from "react-icons/bs";

import Icons from "../../Icons";
import { MyContext } from "../../Store";
import * as apiUrls from "../../constants/ApiConstants";
import { GenericGetApisNoTokenNoParams } from "../../Services";

import "./Styles.css";
import "react-select-search/style.css";
import Logo from "../../Assets/ishout-logo-text.svg";

const Navbar = () => {
  // useContext
  const { authToken, clearToken } = useContext(MyContext);
  const [token] = authToken;
  // const { listMainCategory } = useContext(MyContext);
  // const [mainCategoryListData] = listMainCategory;

  // useState
  const [searchResultData, setSearchResultData] = useState([]);
  const [isSearchDropdownVisible, setSearchDropdownVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchCloseButtonVisible, setIsSearchCloseButtonVisible] =
    useState(false);
  const [isMiniMenuVisible, setMiniMenuVisible] = useState(false);

  // useTransition
  const [transition, startTransition] = useTransition();

  // useNavigate
  const navigate = useNavigate();

  useEffect(() => {
    handleSearchApiCall();
  }, [searchTerm]);

  const handleOrderPageNavigation = () => {
    navigate("/orders");
  };

  const handleProfilePageNavigation = () => {
    navigate("/profile");
  };

  const handlePasswordPageNavigation = () => {
    navigate("/change-password");
  };

  const handleMemorableDaysNavigation = () => {
    navigate("/memorable-days");
  };

  const handleLogout = () => {
    clearToken();
  };

  const handleSearch = async (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchApiCall = async () => {
    if (searchTerm.length === 0) {
      setSearchDropdownVisible(false);
      setIsSearchCloseButtonVisible(false);
      return;
    } else {
      setIsSearchCloseButtonVisible(true);
    }
    const url = `${apiUrls.Search}?key=${searchTerm}`;

    const [res, error] = await GenericGetApisNoTokenNoParams(url);
    // console.log("res = ", res);
    if (res?.code === 200) {
      if (res?.celebrityData) {
        startTransition(() => {
          setSearchResultData(res?.celebrityData);
        });
        setSearchDropdownVisible(true);
        setIsSearchCloseButtonVisible(true);
      } else {
        setSearchDropdownVisible(false);
      }
    } else {
      // console.log("Error in sr-st-pg-api");
      setSearchDropdownVisible(false);
    }
  };

  const handleSearchResult = (i) => {
    navigate(`/celeb/${i.username}`);
    setSearchDropdownVisible(false);
    setSearchResultData([]);
  };

  const handleClose = () => {
    setSearchTerm("");
  };

  return (
    <div>
      <Container>
        {/* add categories and business page later */}

        {/* navbar for desktop => screens from 992px => */}
        <nav className="nav-desk-menu">
          <div className="headingWrprs mt-2">
            <div className="headingFirstSection">
              <div className="w-25">
                <Link to={"/"}>
                  <div className="logoWrpr">
                    <img src={Logo} className="w-75" alt="ishout-logo" />
                  </div>
                </Link>
              </div>
              <div className="navMenuList w-75">
                <ul className="nav_ul">
                  {/* <Dropdown>
                  <Dropdown.Toggle
                    variant="dark"
                    id="id-dropdown-categories"
                    className="custm-avatar-wrpr"
                  >
                    <div className="i-avatar-header">
                      <li className="nav_ui_li">Categories</li>
                      <Icons
                    className="mr-2"
                    icon="darrow"
                    size={10}
                    color={"#ffffff"}
                  />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {mainCategoryListData &&
                      mainCategoryListData?.map((i, index) => (
                        <Dropdown.Item as="link" key={index}>{i.name}</Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown> */}

                  {/* <li className="nav_ui_li">For business</li> */}

                  <Link to={"/how-it-works"}>
                    <li className="nav_ui_li">How it works</li>
                  </Link>
                  <Link to={"/enroll"}>
                    <li className="nav_ui_li">Join as talent</li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="secondSecNav">
              <div className="search-dropdown-all-container">
                <div className="searchWrpr">
                  <Form className="d-flex">
                    <BsSearch
                      color={"rgba(167, 167, 167, 0.5)"}
                      className="common-icon-size"
                      style={{ marginTop: "10px" }}
                    />
                    {/* <Icons
                      icon={"search"}
                      color={"rgba(167, 167, 167, 0.5)"}
                      size={20}
                      style={{ verticalAlign: "center" }}
                    /> */}

                    <Form.Control
                      type="text"
                      placeholder="Search for a celebrity"
                      className="search-input-custom-class w-100"
                      onChange={handleSearch}
                      value={searchTerm}
                    />
                    {isSearchCloseButtonVisible && (
                      <CloseButton
                        onClick={handleClose}
                        variant="white"
                        className="search-btn-close"
                      ></CloseButton>
                    )}
                  </Form>
                </div>
                {isSearchDropdownVisible && (
                  <div
                    className="search-dropdown-container"
                    style={{
                      overflow: "scroll",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                  >
                    {searchResultData.map((i, index) => (
                      <button
                        className="search-dropdown-items"
                        key={index}
                        onClick={() => handleSearchResult(i)}
                      >
                        <div className="search-cel-img">
                          <img
                            src={i.profileImage}
                            className="w-100"
                            alt="celebrity-img"
                          />
                        </div>
                        <div className="search-result-names">
                          <div className="s-d-d-i-name">
                            {`${i.firstName} ${i?.name}`}
                          </div>
                          <div className="s-d-d-i-name-intro">
                            {`${i?.intro}`}
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </div>

              <div className="searchSeparator" />
              {!token ? (
                <Link to={"/login"}>
                  <div className="loginBtns">Login</div>
                </Link>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="custm-avatar-wrpr"
                  >
                    <div className="i-avatar-header">
                      <div className="user-pro-container">
                        <Icons icon="pro" size={30} color={"#ffffff"} />
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ backgroundColor: "#242424" }}>
                    <Dropdown.Item
                      as="button"
                      onClick={handleOrderPageNavigation}
                      className="dropdown-menu-elem"
                    >
                      Orders
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={handleProfilePageNavigation}
                      className="dropdown-menu-elem"
                    >
                      Profile
                    </Dropdown.Item>

                    <Dropdown.Item
                      as="button"
                      onClick={handleMemorableDaysNavigation}
                      className="dropdown-menu-elem"
                    >
                      Memorable Days
                    </Dropdown.Item>

                    <Dropdown.Item
                      as="button"
                      onClick={handlePasswordPageNavigation}
                      className="dropdown-menu-elem"
                    >
                      Change password
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={"button"}
                      onClick={handleLogout}
                      className="dropdown-menu-elem"
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        </nav>

        {/* navbar for screen below 992 px */}
        <nav className="nav-mob-menu mb-4">
          <Row>
            <div className="headingWrprs m-2" style={{ alignItems: "center" }}>
              <div>
                <FaBars
                  size={25}
                  className="barsStyles"
                  onClick={() => setMiniMenuVisible(!isMiniMenuVisible)}
                ></FaBars>
                {isMiniMenuVisible && (
                  <div id="id_mini_menus" className="mn-mu-dv-vi">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="text-left">
                        <img
                          src={Logo}
                          className="nv-logo-style"
                          alt="ishout-logo"
                        />
                      </div>
                      <div
                        className="text-right"
                        style={{ textAlign: "right" }}
                      >
                        <BsXLg
                          size={22}
                          className="barsStyles"
                          onClick={() => setMiniMenuVisible(!isMiniMenuVisible)}
                        ></BsXLg>
                      </div>
                    </div>
                    <hr style={{ color: "#565656" }}></hr>
                    {/* <li className="nav_ui_li" style={{ listStyle: "none;" }}>For business</li> */}
                    <Link to={"/how-it-works"}>
                      <li className="nav_ui_li" style={{ listStyle: "none" }}>
                        How it works
                      </li>
                    </Link>
                    <Link to={"/enroll"}>
                      <li className="nav_ui_li" style={{ listStyle: "none" }}>
                        Join as talent
                      </li>
                    </Link>
                  </div>
                )}
              </div>
              <div className="">
                <Link to={"/"}>
                  <div className="logoWrpr text-center">
                    <img
                      src={Logo}
                      className="nv-logo-style"
                      alt="ishout-logo"
                    />
                  </div>
                </Link>
              </div>
              <div className="">
                <div className="searchSeparator" />
                {!token ? (
                  <Link to={"/login"}>
                    <div className="loginTextMobileScreen">Login</div>
                  </Link>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      id="dropdown-basic"
                      className="custm-avatar-wrpr"
                    >
                      <div className="i-avatar-header">
                        <div className="user-pro-container">
                          <Icons icon="pro" size={25} color={"#ffffff"} />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ backgroundColor: "#242424" }}>
                      <Dropdown.Item
                        as="button"
                        onClick={handleOrderPageNavigation}
                        className="dropdown-menu-elem"
                      >
                        Orders
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={handleProfilePageNavigation}
                        className="dropdown-menu-elem"
                      >
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={handleMemorableDaysNavigation}
                        className="dropdown-menu-elem"
                      >
                        Memorable Days
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={handlePasswordPageNavigation}
                        className="dropdown-menu-elem"
                      >
                        Change password
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={"button"}
                        onClick={handleLogout}
                        className="dropdown-menu-elem"
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </Row>
          <Row>
            <div className="search-dropdown-all-container">
              <div className="searchWrpr">
                <Form className="d-flex">
                  <BsSearch
                    size={20}
                    className="common-icon-size"
                    color={"rgba(167, 167, 167, 0.5)"}
                    style={{ marginTop: 10 }}
                  />
                  <Form.Control
                    type="text"
                    placeholder="Search for a celebrity"
                    className="search-input-custom-class w-100"
                    onChange={handleSearch}
                    value={searchTerm}
                  />
                  {isSearchCloseButtonVisible && (
                    <CloseButton
                      onClick={handleClose}
                      variant="white"
                      className="search-btn-close"
                    ></CloseButton>
                  )}
                </Form>
              </div>
              {isSearchDropdownVisible && (
                <div
                  className="search-dropdown-container"
                  style={{
                    overflow: "scroll",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  {searchResultData.map((i, index) => (
                    <button
                      className="search-dropdown-items"
                      key={index}
                      onClick={() => handleSearchResult(i)}
                    >
                      <div className="search-cel-img">
                        <img
                          src={i.profileImage}
                          className="w-100"
                          alt="celebrity-img"
                        />
                      </div>
                      <div className="search-result-names">
                        <div className="s-d-d-i-name">
                          {`${i.firstName} ${i?.name}`}
                        </div>
                        <div className="s-d-d-i-name-intro">
                          {`${i?.intro}`}
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </Row>
        </nav>
      </Container>
    </div>
  );
};

export default Navbar;
