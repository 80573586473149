import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppStack from "./AppStack";

const NavigationRouter = () => {
  return (
    <BrowserRouter>
      <AppStack />
    </BrowserRouter>
  );
};

export default NavigationRouter;
