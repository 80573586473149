import React, { useEffect, useState } from "react";
// import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import * as apiUrls from "../../constants/ApiConstants";
import { GenericPostApiCall } from "../../Services";
import SpinnerComponent from "../../Component/Spinner";

import "./Styles.css";

const stripePromise = loadStripe(
  "pk_test_51JpZyYGONhZGm4PSTZRBGwzli1gpqXxmrNlKEeOYjbSGrEBrkg0rAFv0qIjWF3Pz50MA4aqk4g6ztWo8gPawmaK500IE3CrT27"
);

const PaymentPage = () => {
  // useLocation
  const location = useLocation();
  const { orderId, total } = location.state;

  // useState
  const [clientSecret, setClientSecret] = useState("");
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    createPaymentIndent();
  }, []);

  const options = {
    clientSecret: clientSecret,
  };

  const createPaymentIndent = async (orderId) => {
    const url = `${apiUrls.adminBaseUrl}stripe/payment/indent/`;
    const body = {
      amount: total,
      currency: "usd",
    };
    const [res, err] = await GenericPostApiCall(url, body);
    if (res?.clientSecret) {
      setClientSecret(res?.clientSecret);
    } else {
      // console.log(err, ".........stripe intent");
    }
    setIsInitialLoading(false);
  };

  return (
    <div className="paymentContainer">
      {isInitialLoading ? (
        <SpinnerComponent />
      ) : (
        <>
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm orderId={orderId} total={total} />
            </Elements>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentPage;
