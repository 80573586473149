import React from "react";
import "./Styles.css";

const Head = ({ title, subtitle, onClick, isViewAllVisible }) => {
  return (
    <div className="-an-main-header-wrpr">
      <div className="viewAllbtsWrpr">
        <div className="home-page-heads">{title}</div>
        <div className="home-page-subheads-i">{subtitle}</div>
      </div>
      {isViewAllVisible && (
        <button className="viewAllButton" onClick={onClick}>
          View all
        </button>
      )}
    </div>
  );
};

export default Head;
