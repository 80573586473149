import React, { useRef, useState, useEffect } from "react";

import "./Styles.css";
import Icons from "../../Icons";

const RecentVideos = ({ i, index, mode }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSoundMute, setIsSoundMute] = useState(true);

  // useRef
  const videoRef = useRef(null);

  useEffect(() => {
    if (index === 0) {
      setIsPlaying(true);
      videoRef.current.play();
      setIsSoundMute(false);
    }
  }, []);

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleMouseOver = (event) => {
    event.target.play();
    videoRef.current.play();
    setIsPlaying((current) => !current);
    setIsPlaying(true);
  };

  const handleMouseOut = (event) => {
    event.target.pause();
    videoRef.current.pause();
    setIsPlaying(false);
  };

  const handleMuteUnmute = () => {
    if (isSoundMute) {
      setIsSoundMute(false);
    } else {
      setIsSoundMute(true);
    }
  };

  const onEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div className="r-p-imgs">
      <video
        width="200"
        height="360"
        ref={videoRef}
        muted={isSoundMute}
        onEnded={onEnded}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        style={{ borderRadius: 15, background: "#000000" }}
      >
        {mode === "profile" ? (
          <source src={`${i.videoProcessed}#t=0.001`} type="video/mp4" />
        ) : (
          <source src={`${i.video}#t=0.001`} type="video/mp4" />
        )}
      </video>
      {/* <img src={i?.thumbnail} className="w-100" alt="cel" /> */}
      <div className="mute-wrprs-c-d">
        <button className="-an-c-d-muts-bts-1" onClick={handleMuteUnmute}>
          {isSoundMute ? (
            <Icons icon="mutes" size={32} color="#ffffff" />
          ) : (
            <Icons icon="soundOn" size={32} color="#ffffff" />
          )}
        </button>
      </div>
      <div className="mute-wrprs-c-d-play">
        {isPlaying ? (
          <button onClick={handlePlayPause} className="-an-r-v-play-btn">
            <Icons icon="pauses" size={32} color="#ffffff" />
          </button>
        ) : (
          <button onClick={handlePlayPause} className="-an-r-v-play-btn">
            <Icons icon="plays" size={32} color="#ffffff" />
          </button>
        )}
      </div>
    </div>
  );
};

export default RecentVideos;
