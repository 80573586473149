import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import slugify from "react-slugify";
import moment from "moment";

import {
  CelebrityCard,
  Footer,
  HowItWorks,
  Navbar,
  RecentVideos,
} from "../../Component";
import { MyContext } from "../../Store";
import {
  GenericGetApisExtraParams,
  GenericGetApisNoToken,
  GenericPostApiCall,
} from "../../Services";
import * as apiUrls from "../../constants/ApiConstants";
import SpinnerComponent from "../../Component/Spinner";

import Icons from "../../Icons";
import CelebrityDefault from "../../Assets/CelebrityDefault.jpg";
import "./Styles.css";

const Details = () => {
  // params
  const { username } = useParams();

  // useNavigate
  const navigate = useNavigate();

  const location = useLocation();

  // useContext
  const { authToken } = useContext(MyContext);
  const [token] = authToken;

  // useState
  const [isLoading, setLoading] = useState(true);
  const [isNotifyButtonLoading, setIsNotifyButtonLoading] = useState(false);
  const [notifyResponse, setNotifyResponse] = useState("");
  // const [rating, setRating] = useState(0);
  const [celebrityData, setCelebrityData] = useState([]);

  const [show, setShow] = useState(false);
  const [reviewsData, setReviewsData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // useEffect
  useEffect(() => {
    getCelebrityData();
    // localStorage.setItem("newPersonalOrderData", JSON.stringify({}));
    // localStorage.setItem("newBusinessOrderData", JSON.stringify({}));
  }, [location.key]);

  useEffect(() => {
    if (notifyResponse) {
      const timeout = setTimeout(() => notifyResponseClose(), 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [notifyResponse]);

  const getCelebrityData = async () => {
    setLoading(true);
    const sessionId = sessionStorage.getItem("sessionId");
    const params = { key: username, session_key: sessionId };
    const url = `${apiUrls.CelebrityDetails}/?key=${username}&session_key=${sessionId}`;
    const [response, error] = await GenericGetApisExtraParams(url, params);
    if (error) {
      // console.log("error in c-d-p-api");
    }
    // console.log(response);
    if (response) {
      setCelebrityData(response);
      getReviewData(response);
    } else {
      setCelebrityData([]);
    }
    setLoading(false);
  };

  const handleUserIsLogIn = () => {
    if (token) {
      navigate("/booking-personal", {
        state: {
          celebrityData: celebrityData,
          selectedType: "personal",
        },
      });
    } else {
      navigate(`/login?redirectTo=${window.location.pathname}`);
    }
  };

  const handleBusinessUserIsLogged = () => {
    // BusinessBookingForm
    if (token) {
      navigate("/booking-business", {
        state: {
          celebrityData: celebrityData,
          selectedType: "business",
        },
      });
    } else {
      navigate(`/login?redirectTo=${window.location.pathname}`);
    }
  };

  const handleNotifyCelebrity = async () => {
    setIsNotifyButtonLoading(true);
    const data = { username: username };
    const [response, error] = await GenericPostApiCall(
      apiUrls.NotifyClients,
      data
    );
    if (error) {
      // console.log("something happened not right in cd-handle-not-if-a-i");
    }
    // console.log(response);
    if (response) {
      setNotifyResponse(response.detail);
    }
    setIsNotifyButtonLoading(false);
  };

  function Review({ createdTimeStamp }) {
    const timestamp = moment.unix(createdTimeStamp);
    const timeAgo = timestamp.fromNow();
    return <div>{timeAgo}</div>;
  }

  const getReviewData = async (data) => {
    const url = `${apiUrls.GetReviews}?key=${data?.celebrity?.celebrityId}`;
    // console.log(url);
    const [response, error] = await GenericGetApisNoToken(url);
    // console.log(response);
    if (error) {
      // console.log("error in c-d-p-rev-api", error);
    }

    if (response?.reviews) {
      setReviewsData(response?.reviews);
    } else {
      setReviewsData([]);
    }
  };

  const notifyResponseClose = () => {
    setNotifyResponse("");
  };

  return (
    <div className="celebrityContainer">
      <Navbar />
      <Container>
        {isLoading ? (
          <SpinnerComponent />
        ) : (
          <div className="celebrityInnerContainer">
            <div className="section-one-c-d-p">
              <div className="sec-onw-s-c-d">
                <img
                  src={
                    celebrityData?.celebrity?.profileImage
                      ? celebrityData?.celebrity?.profileImage
                      : CelebrityDefault
                  }
                  className="w-100"
                  alt={`${celebrityData?.celebrity?.firstName} ${celebrityData?.celebrity?.name}`}
                />
              </div>

              <div className="ssec-c-d-page">
                <Row>
                  <Col lg={9} md={12}>
                    <div className="cele-name-s">
                      {celebrityData?.celebrity?.firstName}{" "}
                      {celebrityData?.celebrity?.name}
                    </div>
                    <div className="blog-c-d">
                      {celebrityData?.celebrity?.intro}
                    </div>
                    <div className="c-d-expla">
                      {celebrityData?.celebrity?.bio}
                    </div>
                    <div className="ratingReview">
                      {celebrityData?.averageReview?.rating ? (
                        <>
                          <div className="d-flex flex-row me-2 align-items-center">
                            <div className="mb-1">
                              <Icons
                                icon="star"
                                size={12}
                                color={"yellow"}
                              ></Icons>
                            </div>
                            &nbsp;
                            <div className="cl-det-av-rating-label">
                              {celebrityData?.averageReview?.rating.toFixed(2)}
                            </div>
                            {celebrityData?.totalReview > 0 && (
                              <div className="reviewCounts">
                                ({celebrityData?.totalReview})
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        ``
                      )}

                      {celebrityData?.celebrity?.fastDelivery && (
                        <div className="availabilityTiming">
                          <Icons icon={"time"} size={14} color={"#9CD0FA"} />
                          <div className="attext">24hr Response</div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                {!celebrityData?.celebrity.vacationMode ? (
                  <>
                    <div className="bb-b-f-d-f">
                      <button
                        onClick={handleUserIsLogIn}
                        className="b-n-bts-c-d"
                      >
                        Book now - $
                        {celebrityData?.celebrity?.bookingCost?.amount}
                      </button>
                      {celebrityData?.celebrity?.supportBusiness && (
                        <button
                          onClick={handleBusinessUserIsLogged}
                          className="b-n-bts-c-d-b-b"
                        >
                          Book for business - $
                          {
                            celebrityData?.celebrity?.businessBookingCost
                              ?.amount
                          }
                        </button>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="d-flex flex-column">
                    <div className="c-d-expla" style={{ marginBottom: 12 }}>
                      {celebrityData?.celebrity?.firstName}{" "}
                      {celebrityData?.celebrity?.name} is currently unavailable
                      for booking!
                    </div>
                    <button
                      onClick={handleNotifyCelebrity}
                      className="b-n-bts-c-d-b-b"
                      style={{ width: "max-content", marginLeft: 0 }}
                    >
                      Notify me when {celebrityData?.celebrity?.firstName}{" "}
                      {celebrityData?.celebrity?.name} is back
                      {isNotifyButtonLoading && <Spinner size="sm"></Spinner>}
                    </button>
                    <div className="c-d-not-resp-txt mt-2">
                      {notifyResponse}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* ---- */}
            {celebrityData?.celebrity?.recentVideos && (
              <div className="r-p-inners-wrpr">
                <div className="how-i-w-headings">Recently posted</div>
                <div className="-an-r-v-wrprs">
                  {celebrityData?.celebrity?.recentVideos?.map((i, index) => (
                    <RecentVideos
                      i={i}
                      index={index}
                      key={index}
                      mode="celebrity"
                    ></RecentVideos>
                  ))}
                </div>
              </div>
            )}
            {celebrityData?.categories && (
              <>
                <div className="d-flex flex-row mb-3 justify-content-start cel-cat-sup-div">
                  {celebrityData?.categories?.map((i, index) => (
                    <Link to={`/celebrities/${slugify(i)}`} key={index}>
                      <div className="cel-cat-main-div">
                        <div className="cel-cat-text">{i}</div>
                      </div>
                    </Link>
                  ))}
                </div>
              </>
            )}

            {/* ---- */}
            {/* -----reviews start----- */}
            <Row>
              <Col lg={8}>
                <div className="reviews-wrprs-i">
                  <div className="how-i-w-headings">Reviews</div>
                  <Row>
                    {celebrityData?.reviews.length > 0 ? (
                      celebrityData?.reviews.map((i, index) => (
                        <Col lg={6} className="mb-1" key={index}>
                          <div className="wrpr-all-i-c-s-main">
                            <div className="wrpr-all-i-c-s">
                              <div className="r-img-wrpr-ii-c">
                                {i?.user?.charAt(0).toUpperCase()}
                              </div>
                              <div className="review-ss-wrpr-ii">
                                <div className="re-u-name">{i.user}</div>
                                <div className="re-u-name-time">
                                  <Review
                                    createdTimeStamp={i?.createdTimeStamp}
                                  />
                                </div>
                                <Rating
                                  size={12}
                                  fillColor={"rgba(255, 206, 49, 1)"}
                                  readonly={true}
                                  initialValue={i.rating}
                                  iconsCount={5}
                                />
                              </div>
                            </div>
                            <div className="review-para-cc">{i.comment}</div>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <div className="no-review-txt">
                        This talent has no reviews. Book them today so you can
                        be their first review.
                      </div>
                    )}
                  </Row>
                  {celebrityData?.reviews.length > 2 && (
                    <button className="show-all-wrpsss" onClick={handleShow}>
                      <div className="show-all-reviews">Show all</div>
                    </button>
                  )}
                </div>
              </Col>
            </Row>
            {/* -----reviews end----- */}
            {celebrityData && celebrityData?.similarCelebrities.length > 0 && (
              <>
                <div>
                  <div className="how-i-w-headings">Similar celebrities</div>
                  <div className="main-wrpr-h-p-scroll">
                    {celebrityData?.similarCelebrities?.map((i, index) => (
                      <Link to={`/celeb/${i.username}`} key={index}>
                        <div className="h-c-l-scroll-wrps">
                          <CelebrityCard data={i} key={index} />
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="sep40" />
              </>
            )}

            <div className="how-it-works-main-wrpr">
              <div className="how-i-w-headings">How it works</div>
              <div className="how-i-subtitle">
                Get your personalized video by following these simple steps
              </div>
              <HowItWorks />
            </div>
          </div>
        )}
      </Container>
      <Footer />

      <Modal show={show} onHide={handleClose} scrollable={true}>
        <Modal.Body className="modal-wrpr-body">
          <button className="close-btn-wrpr-modal" onClick={handleClose}>
            <span className="m-cls-btn-style" aria-hidden="true">
              &times;
            </span>
          </button>
          <div className="total-review-modals-count">
            {celebrityData?.totalReview} reviews
          </div>
          {reviewsData?.map((i, index) => (
            <Col lg={12} className="mb-4" key={index}>
              <div className="wrpr-all-i-c-s-main">
                <div className="wrpr-all-i-c-s">
                  <div className="r-img-wrpr-ii-c">
                    {i?.user?.charAt(0).toUpperCase()}
                  </div>
                  <div className="review-ss-wrpr-ii">
                    <div className="re-u-name">{i.user}</div>
                    <div className="re-u-name-time">
                      <Review createdTimeStamp={i?.createdTimeStamp} />
                    </div>
                    <Rating
                      size={12}
                      fillColor={"rgba(255, 206, 49, 1)"}
                      readonly={true}
                      initialValue={i.rating}
                      iconsCount={5}
                    />
                  </div>
                </div>
                <div className="review-para-cc1">{i.comment}</div>
              </div>
            </Col>
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Details;
